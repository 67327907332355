import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "dsmds-option-popup",
  templateUrl: "./option-popup.component.html",
  styleUrls: ["./option-popup.component.css"],
})
export class OptionPopupComponent implements OnInit {
  option_data: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.option_data = data;
  }

  formatPrice(price) {
    return parseInt(price)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  formatText(text) {
    if (text) {
      text = text.replace("<ul>", "");
      text = text.replace("</ul>", "");
      text = text.replace("<ol>", "");
      text = text.replace("</ol>", "");
      text = text.replace("<em>", "");
      text = text.replace("</em>", "");
      text = text.replaceAll("li", "div");
      return text.replace(/ *\[[^\]]*]/g, "");
    }
  }

  ngOnInit(): void {}
}
