import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() { }

  setItem(key, data){
    localStorage.setItem(key, JSON.stringify(data));
  }
  getItem(key){
    const data = JSON.parse(localStorage.getItem(key));
    return data;
  }
  removeItem(key){
    localStorage.removeItem(key);
  }

  checkItem(key){
    return localStorage.getItem(key) !== null
  }
  
}
