import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { DisclaimerPopupComponent } from "../../shared/disclaimer-popup/disclaimer-popup.component";
import { Subject, take, takeUntil } from "rxjs";
import { UiStateService } from "../../../service/ui-state.service";
import { FindMyCarService } from "../../../service/find-my-car.service";
import { TOYOTA_BASE_URL } from "src/app/constants/imagebase.constants";
import { Router } from "@angular/router";
import { ROUTES_TOYOTA } from "src/app/constants";
import { getToyotaDisplayHeader } from "src/app/utils";
import { errorStatusCode } from "src/app/utils/custom.function";
@Component({
  selector: "app-model-color",
  templateUrl: "./model-color.component.html",
  styleUrls: ["./model-color.component.css"],
})
export class ModelColorComponent implements OnInit, OnDestroy {
  exteriorColor: any = [];
  selected_exterior: any = [];
  displayText: any;
  errMessage: string;
  isLoading: boolean = true;
  unsubscribe$ = new Subject();
  constructor(
    public dialog: MatDialog,
    private appState: UiStateService,
    private findMyCarService: FindMyCarService,
    private router: Router
  ) {
    try {
      this.appState
        .getState()
        .pipe(take(1))
        .subscribe((state) => {
          let stateModelNo = null;
          if (state.hasOwnProperty("modelNo") && state?.modelNo !== null) {
            stateModelNo = state?.modelNo;
          }

          if (history.state?.row !== undefined) {
            if (history.state?.row?.code === stateModelNo) {
              this.appState?.setState({
                modelNo: history.state?.row?.code,
                grade_attributes: history.state?.row,
                option_data: null,
              });
            } else {
              this.appState?.setState({
                modelNo: history.state?.row?.code,
                grade_attributes: history.state?.row,
                option_data: null,
                exterior_color: null,
                interior_color: null,
                option_list: null,
                option_msrp: null,
                optionData: null,
              });
              stateModelNo = history.state?.row?.code;
            }
            this.displayText =
              history.state?.displayText +
              " " +
              history.state?.row?.engine_title;
          } else {
            this.displayText = getToyotaDisplayHeader(state);
          }
          if (stateModelNo) {
            this.getStateData();
          } else {
            this.router?.navigate(ROUTES_TOYOTA.slice(0, 3));
          }
        });
    } catch (error) {
      this.router?.navigate(["/"]);
      console.log(error);
    }
  }

  openDialog(type) {
    this.dialog.open(DisclaimerPopupComponent, {
      data: type,
    });
  }

  ngOnInit(): void {}
  getStateData() {
    try {
      /* Extract exterior color information based on previously
       stored series data */
      this.appState
        ?.getState()
        .pipe(take(1))
        .subscribe((state) => {
          const exterior_color = state.toyotaseriesdata?.colors?.exterior;
          this.getExteriorColor(state.modelNo, exterior_color);
          this.exteriorColor = this.exteriorColor.filter(
            (x) => x.status == "LIVE" && x.type == "exteriorcolor"
          );
          this.selected_exterior = this.exteriorColor[0];
          if (state?.exterior_color) {
            this.selected_exterior = state?.exterior_color;
          }
          /* Extract option packages and store in cache to imrove performance */
          this.findMyCarService
            .getOptionPackage(state.modelNo, state.year)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              error: (err) => {
                this.isLoading = false;
                this.errMessage = errorStatusCode(err);
              },
              next: (data) => {
                this.appState.setState({
                  option_data: data,
                });
              },
            });
        });
    } catch (error) {
      console.log(error);
    }
  }

  /* Extract exterior color information */
  getExteriorColor(modelNo, exterior_color) {
    for (let i = 0; i < exterior_color?.length; i++) {
      let applicable = exterior_color[i].applicable;
      for (let j = 0; j < applicable.length; j++) {
        if (applicable[j].trim == modelNo) {
          this.exteriorColor.push({
            color_code: exterior_color[i]?.code,
            color_name: exterior_color[i]?.title,
            color_msrp: exterior_color[i]?.price
              ? parseInt(exterior_color[i]?.price?.msrp)
              : null,
            image:
              TOYOTA_BASE_URL +
              applicable[j]?.override?.images?.car_jelly_image,
            jellyImage: applicable[j]?.override?.images?.car_jelly_image,
            hex_value_1: "#" + exterior_color[i].hex_value[0],
            hex_value_2:
              exterior_color[i]?.hex_value[1] != null
                ? "#" + exterior_color[i]?.hex_value[1]
                : null,
            status: exterior_color[i]?.visibility?.status?.status_code,
            type: exterior_color[i]?.type,
          });
        }
      }
    }
  }

  /* Populate image based on color selection */
  getImage(data) {
    this.selected_exterior = data;
  }

  formatExtColor(data) {
    let colorName = data.color_name;
    if (data.color_msrp != null) {
      colorName = colorName.replace("[extra_cost_color]", "");
      colorName = colorName.replace(/ *\[[^\]]*]/g, "");
      colorName = colorName + " " + "$" + data.color_msrp;
    }
    return colorName;
  }

  ngOnDestroy() {
    this.unsubscribe$.complete();
  }
}
