<div class="nav-bar-lexus">
  <div class="main-nav-bar-lexus container h-100">
    <div class="t-container h-100">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-6 col-sm-6 col-md-3">
          <img
            (click)="navigateToLesus()"
            style="width: 150px; height: auto; cursor: pointer"
            alt="logo"
            [src]="lexusLogoUrl"
          />
        </div>
        <div class="col-6 col-sm-6 col-md-5 d-none d-md-block">
          <div class="toyota-logo">
            <h4 *ngIf="latest_date" class="display-4" style="margin-top: 1%">
              RESULTS ACCURATE AS OF<br class="d-lg-none" />
              {{ latest_date }}
            </h4>
          </div>
        </div>
        <div class="col-6 col-sm-6 col-md-4 g-0 justify-content-right">
          <div class="d-flex justify-content-end">
            <div class="pe-2">
              <a [routerLink]="['/lexus']">
                <dsmds-button-lexus
                  (onClick)="onClickfunc($event)"
                  [buttonConfig]="{
                    text: 'New Search',
                    type: 'primary',
                    size: 'sm'
                  }"
                ></dsmds-button-lexus>
              </a>
            </div>
            <div class="colss">
              <a [href]="feedbackUrl" target="_blank" rel="noopener noreferrer">
                <dsmds-button-lexus
                  (onClick)="onClickfunc($event)"
                  [buttonConfig]="{
                    text: 'Feedback',
                    type: 'primary',
                    size: 'sm'
                  }"
                ></dsmds-button-lexus>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="justify-content-center" [hidden]="loading">
  <div class="spinner-border" role="status">
    <span class="sr-only" id="loading"></span>
  </div>
</div>
<div class="dsmds-steps-navigations-lexus px-4 mt-3">
  <dsmds-steps-navigations-lexus
    [tabnumber]="5"
  ></dsmds-steps-navigations-lexus>
</div>
<div class="mt-3 px-4" [ngClass]="{ 'd-flex d-sm': !exact_matches }">
  <div
    class="table-responsive-lg"
    [hidden]="!loading"
    [class.w-100]="exact_matches"
    style="overflow-x: auto; position: relative"
  >
    <table id="users" aria-describedby="findcar-table">
      <tbody>
        <tr>
          <th scope="row"></th>
          <td>
            <div class="your-choice-header">YOUR CHOICE</div>
            <img
              alt="Car Image"
              width="250px"
              src="{{ your_choice?.img || your_choice?.image }}"
            />
          </td>
          <td [hidden]="!exact_matches">
            <div *ngIf="exact_matches" class="exact-match-header">
              CLOSEST MATCH
            </div>
            <img
              *ngIf="exact_matches"
              width="250px"
              alt="Car Image"
              src="{{ getImage(exact_matches?.exterior_image_path_compare) }}"
            />
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            <div class="exact-match-header">RECOMMENDATIONS</div>
            <img
              alt="Car Image"
              src="{{ getImage(data?.exterior_image_path_compare) }}"
              class="ml-lg-5 order-1 order-lg-2"
              width="250px"
            />
          </td>
        </tr>
        <!-- <tr>
          <th scope="row">
            Earliest available<sup
              style="cursor: pointer"
              (click)="openDialogDisclaimer('eta')"
              >*</sup
            >
          </th>
          <td style="padding: 30px">
            <mat-select
              (selectionChange)="onSelection($event)"
              [(value)]="modeselect"
            >
              <mat-option *ngFor="let eta of eta_options" [value]="eta.value">
                {{ eta.displayValue }}
              </mat-option>
            </mat-select>
          </td>
          <td [hidden]="!exact_matches">
            {{ exact_matches?.eta }}
          </td>
          <td [hidden]="!best_match" *ngFor="let data of best_match">
            {{ data?.eta }}
          </td>
        </tr> -->
        <tr>
          <th scope="row">Estimated Dealer Arrival</th>
          <td style="padding: 30px">
            <mat-select
              (selectionChange)="onSelectionEL($event)"
              [(value)]="modeselectel"
            >
              <mat-option *ngFor="let eta of eta_options" [value]="eta.value">
                {{ eta.displayValue }}
              </mat-option>
            </mat-select>
          </td>
          <td [hidden]="!exact_matches">
            {{ exact_matches?.edaText }}
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ data?.edaText }}
          </td>
        </tr>

        <tr>
          <th scope="row">
            Similarity
            <sup
              style="cursor: pointer"
              (click)="openDialogDisclaimer('similarity')"
              >*</sup
            >
          </th>
          <td></td>
          <td [hidden]="!exact_matches">
            {{ exact_matches?.Similarity_score * 100 | number: "1.0-0" }} %
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ data?.Similarity_score * 100 | number: "1.0-0" }} %
          </td>
        </tr>
        <tr>
          <th scope="row">Model Year</th>
          <td style="padding: 30px">
            <mat-select
              (selectionChange)="onSelectionYears($event)"
              [(value)]="modeyears"
            >
              <mat-option
                *ngFor="let year of years_options"
                [value]="year.value"
              >
                {{ year.displayValue }}
              </mat-option>
            </mat-select>
          </td>
          <td [hidden]="!exact_matches">
            {{ exact_matches?.model_year_compare }}
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ data?.model_year_compare }}
          </td>
        </tr>
        <tr>
          <th scope="row">Model #</th>
          <td>
            {{ your_choice?.model_number }}
            <mat-checkbox
              class="example-margin"
              (change)="
                onCheck(
                  $event,
                  'model_number_compare',
                  your_choice.model_number
                )
              "
            ></mat-checkbox>
          </td>
          <td [hidden]="!exact_matches">
            {{ exact_matches?.model_number_compare }}
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ data?.model_number_compare }}
          </td>
        </tr>
        <tr>
          <th scope="row">Series</th>
          <td>
            {{ getSeriesName(your_choice) }}
            <mat-checkbox
              class="example-margin"
              (change)="
                onCheck(
                  $event,
                  'toms_series_name_compare',
                  getSeriesName(your_choice)
                )
              "
            ></mat-checkbox>
          </td>
          <td [hidden]="!exact_matches">
            {{ exact_matches?.toms_series_name_compare }}
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ data?.toms_series_name_compare }}
          </td>
        </tr>
        <tr>
          <th scope="row">Grade</th>
          <td>
            {{ your_choice.grade
            }}<mat-checkbox
              class="example-margin"
              (change)="
                onCheck($event, 'grade_spec_name_compare', your_choice.grade)
              "
            ></mat-checkbox>
          </td>
          <td [hidden]="!exact_matches">
            {{ exact_matches?.grade_spec_name_compare }}
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ data?.grade_spec_name_compare }}
          </td>
        </tr>
        <tr>
          <th scope="row">Drive</th>
          <td>
            {{ your_choice.drive }}
            <mat-checkbox
              class="example-margin"
              (change)="
                onCheck($event, 'drivetrain_name_compare', your_choice.drive)
              "
            ></mat-checkbox>
          </td>
          <td [hidden]="!exact_matches">
            {{ exact_matches?.drivetrain_name_compare }}
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ data?.drivetrain_name_compare }}
          </td>
        </tr>
        <tr>
          <th scope="row">Interior</th>
          <td style="max-width: 270px">
            {{ your_choice.interior_color }}
            <mat-checkbox
              class="example-margin"
              (change)="
                onCheck(
                  $event,
                  'interior_color_name_compare',
                  your_choice.interior_color
                )
              "
            ></mat-checkbox>
          </td>
          <td [hidden]="!exact_matches">
            {{ exact_matches?.interior_color_name_compare }}
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ data.interior_color_name_compare }}
          </td>
        </tr>
        <tr>
          <th scope="row">Exterior</th>
          <td>
            {{ formatExtColor(your_choice?.exterior_color) }}
            <mat-checkbox
              class="example-margin"
              (change)="
                onCheck(
                  $event,
                  'exterior_color_name_compare',
                  your_choice.exterior_color
                )
              "
            ></mat-checkbox>
          </td>
          <td [hidden]="!exact_matches">
            {{ formatExtColor(exact_matches?.exterior_color_name_compare) }}
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ formatExtColor(data?.exterior_color_name_compare) }}
          </td>
        </tr>
        <tr>
          <th scope="row">Packages</th>
          <td
            matTooltip="{{ your_choice?.option_package_details?.join('\r\n') }}"
            matTooltipClass="tooltip-package-details"
            [matTooltipDisabled]="!your_choice?.option_packages"
            matTooltipPosition="below"
          >
            {{ your_choice?.option_packages }}
            <button
              (click)="openOptionDialog(your_choice.option_packages)"
              style="
                background-color: black;
                color: var(--bs-white);
                float: right;
              "
            >
              +
            </button>
          </td>
          <td
            [hidden]="!exact_matches"
            matTooltip="{{
              exact_matches?.option_package_details?.join('\r\n')
            }}"
            matTooltipClass="tooltip-package-details"
            [matTooltipDisabled]="!exact_matches?.fio_list_compare"
            matTooltipPosition="below"
          >
            {{ exact_matches?.fio_list_compare.split(",").join(", ") }}
          </td>
          <td
            [hidden]="!exact_matches"
            *ngFor="let data of best_match"
            matTooltip="{{ data?.option_package_details?.join('\r\n') }}"
            matTooltipClass="tooltip-package-details"
            [matTooltipDisabled]="!data?.fio_list_compare"
            matTooltipPosition="below"
          >
            {{ data?.fio_list_compare.split(",").join(", ") }}
          </td>
        </tr>
        <tr>
          <th scope="row">
            Factory MSRP
            <sup
              style="cursor: pointer"
              (click)="openDialogDisclaimer('findcar-msrp')"
              >*</sup
            >
          </th>
          <td>{{ formatPrice(your_choice?.msrp) }}</td>
          <td [hidden]="!exact_matches">
            {{ formatPrice(exact_matches?.msrp_amount_compare) }}
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ formatPrice(data?.msrp_amount_compare) }}
          </td>
        </tr>
        <tr>
          <th scope="row">Dealer #</th>
          <td>{{ your_choice?.dealercode }}</td>
          <td [hidden]="!exact_matches">
            {{ exact_matches?.dealer_number_compare[0] }}
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ data?.dealer_number_compare[0] }}
          </td>
        </tr>
        <tr>
          <th scope="row">VIN</th>
          <td></td>
          <td [hidden]="!exact_matches">
            {{ exact_matches?.vin_compare[0] }}
            <button
              (click)="openDialog(exact_matches)"
              [hidden]="!exact_matches"
              style="background-color: black; color: white"
            >
              +
            </button>
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ data?.vin_compare[0] }}
            <button
              (click)="openDialog(data)"
              title="{{ best_match?.vin_compare }}"
              mat-button
              [hidden]="!exact_matches"
              style="background-color: black; color: white"
            >
              +
            </button>
          </td>
        </tr>
        <tr>
          <th scope="row">V-Spec</th>
          <td></td>
          <td [hidden]="!exact_matches">
            V-Spec
            <button
              (click)="openDialog(exact_matches, 'vspec')"
              [hidden]="!exact_matches"
              style="background-color: black; color: white; padding: 0 3px"
            >
              <mat-icon class="t-icon">cloud_upload</mat-icon>
            </button>
          </td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            V-Spec
            <button
              (click)="openDialog(data, 'vspec')"
              title="{{ best_match?.vin_compare }}"
              mat-button
              [hidden]="!exact_matches"
              style="background-color: black; color: white; padding: 0 3px"
            >
              <mat-icon class="t-icon">cloud_upload</mat-icon>
            </button>
          </td>
        </tr>
        <tr>
          <th scope="row">Number available</th>
          <td></td>
          <td [hidden]="!exact_matches">{{ exact_matches?.vin_count }}</td>
          <td [hidden]="!exact_matches" *ngFor="let data of best_match">
            {{ data?.vin_count }}
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <div style="text-align: center">
      <button
        [hidden]="!this.next"
        class="lexus-btn-primary me-2 lexus-btn-p"
        (click)="getBestUpsellDataPrev()"
      >
        Previous
      </button>
      <button
        [hidden]="prev >= cardatalength || cardatalength <= 4"
        class="lexus-btn-primary lexus-btn-p"
        (click)="getBestUpsellData(true)"
      >
        More results
      </button>
    </div>
  </div>
  <div [hidden]="!loading" class="no-result">
    <div [hidden]="exact_matches">
      No available vehicles match the search criteria.
    </div>
    <div [hidden]="exact_matches" style="padding-top: 10px">
      Please remove filters or try a
      <a [routerLink]="['/lexus']">new search</a>.
    </div>
  </div>
</div>
