import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { take, first } from "rxjs";
import { DEALER_USER, ROUTES_TOYOTA } from "src/app/constants"
import { ObservableDataShareService } from 'src/app/service/data-share/observable-data-share.service';
import { UiStateService } from 'src/app/service/ui-state.service';

@Component({
  selector: 'dsmds-steps-navigations',
  templateUrl: './steps-navigations.component.html',
  styleUrls: ['./steps-navigations.component.css']
})
export class StepsNavigationsComponent implements OnInit, OnDestroy {
  @Input() tabnumber:number = 1;
  @ViewChild('tabGroup')
  tabGroup;

  links = ['Model', 'Grade', "Powertrain", 'Exterior Color' , 'Interior Color', "Packages"];
  routes = ROUTES_TOYOTA
  isAllSelected:boolean = false
  allStates:any; 
  activeLink = 1;
  background: ThemePalette = undefined;

  constructor(
    public router: Router,
    private appstate: UiStateService,
    private obDataShareService: ObservableDataShareService
    ) { 
      this.obDataShareService?.pkgData.subscribe(data => {
        // console.log('obDataShareService', data)
          
        if(data && data == 'Yes'){
          this.obDataShareService.updatePkgSelection(null)
          this.activeLink = 7
          this.checkAllSelected()
        }
      })
    }

  ngOnInit(): void {
    this.activeLink = this.tabnumber
    this.checkAllSelected()
  }
  ngOnDestroy() {
    this.obDataShareService.pkgData.subscribe();
  }
  
  checkAllSelected(){
    try {
      this.appstate
        .getState()
        .pipe(take(1))
        .subscribe((state) => {
          this.allStates = state;
          if(state?.seriescode && state?.seriescode !==null
            && state?.grade && state?.grade !==null
            && state?.modelNo && state?.modelNo !==null
            && state?.exterior_color && state?.exterior_color !==null
            && state?.interior_color && state?.interior_color !==null
            && state?.option_data && state?.option_data !==null
            && state?.option_list && state?.option_list !==null
            ){
            this.isAllSelected = true;
          }
          
        });
    } catch (error) {
      console.log(error);
    }
  }

  
  scrollTabs(event) {
    console.log('scrollTabs', event)
    // const children = this.tabGroup._tabHeader._elementRef.nativeElement.children;
    // const back = children[0];
    // const forward = children[3];
    // if (event.deltaY > 0) {
    //   forward.click();
    // } else {
    //   back.click();
    // }
  }
  redirectToSteps(e){
    if(parseInt(e?.index)<6){
      const len = parseInt(e?.index) + 1
      const newRoute = this.routes.slice(0, len)
      this.router.navigate(newRoute);
    }
  }
  redirectToFindCar(){
    if( this.isAllSelected){
      if(this.allStates.claims?.user_type == DEALER_USER){
        this.router.navigate(this.routes);
      }
      else{
        this.router.navigate(this.routes.slice(0, 7));
      }
      
    }
  }

}
