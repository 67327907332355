import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "dsmds-disclaimer-popup",
  templateUrl: "./disclaimer-popup.component.html",
  styleUrls: ["./disclaimer-popup.component.css"],
})
export class DisclaimerPopupComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {}
}
