<div class="container">
  <div class="row text-center text-white mb-5">
    <div class="vehicle-container">
      <span style="margin-top: 5%; margin-left: 20%; text-align: center">
        <h1 class="container-heading">
          ALL LEXUS VEHICLES
        </h1>
      </span>
      <span style="margin-top: 12%; margin-left: 20%">
        <h1 class="display-4">SELECT A SERIES</h1>
      </span>
    </div>
  </div>

  <div class="tc-dropdown">
    <button class="tc-dropbtn">{{this.category.indexOf("_") > -1 ? this.category.toUpperCase().split("_").join(" & "):this.category.toUpperCase()}}</button>
    <div class="tc-dropdown-content">
      <button
              [ngClass]="{ active: this.category === 'sedan' }"
              (click)="category='sedan';selectedGroup=sedan;">SEDAN</button>
      <button
              [ngClass]="{ active: this.category === 'suv' }"
              (click)="category='suv';selectedGroup=suv;">SUV</button>
      <button
              [ngClass]="{ active: this.category === 'coupe' }"
              (click)="category='coupe';selectedGroup=coupe">COUPE</button>

      <button
              [ngClass]="{ active: this.category === 'hybrid_electric' }"
              (click)="category='hybrid_electric';selectedGroup=hybrid_electric;">HYBRID & ELECTRIC</button>

      <button
              [ngClass]="{ active: this.category === 'performance' }"
              (click)="category='performance';selectedGroup=performance;">PERFORMANCE</button>
    </div>
  </div>

  <div class="tab tc-tab">
    <button
            [ngClass]="{ active: this.category === 'sedan' }"
            class="tablinks"
            (click)="category='sedan';selectedGroup=sedan;"
    >
      SEDAN
    </button>
    <button
            [ngClass]="{ active: this.category === 'suv' }"
            class="tablinks"
            (click)="category='suv';selectedGroup=suv;"
    >
      SUV
    </button>
    <button
            [ngClass]="{ active: this.category === 'coupe' }"
            class="tablinks"
            (click)="category='coupe';selectedGroup=coupe;"
    >
      COUPE
    </button>
    <button
            [ngClass]="{ active: this.category === 'hybrid_electric' }"
            class="tablinks"
            (click)="category='hybrid_electric';selectedGroup=hybrid_electric;"
    >
      HYBRID & ELECTRIC
    </button>
    <button
            [ngClass]="{ active: this.category === 'performance' }"
            class="tablinks"
            (click)="category='performance';selectedGroup=performance;"
    >
      PERFORMANCE
    </button>
  </div>
  <div style="padding-top: 5px; ">
    Vehicles on this page are for illustrative purposes only.
  </div>
  <div>
    <div class="model-info-p">
      <div class="row">
        <div class="col-lg-4 col-xl-3 row-cols-xxxl-5 col-md-6 col-sm-12 mb-4 border-bottom1" *ngFor="let data of selectedGroup">
          <a (click)="navigateToGrade($event, data)" class="general-router-lx">
            <div class="p-3 lxcard"  [class.active-model]="lexmodelCodeSelected==data.code"  >
              <div class="col-mdd">
                <div class="mb-lg-0">

                  <div
                          class="bg-image hover-overlay ripple shadow-1-strong rounded"
                          data-ripple-color="light"
                  >
                    <img
                            alt="Car Image"
                            src="{{ data.image? data.image: 'assets/images/default-car-lexus.png'}}"
                            class="ml-lg-5 order-1 order-lg-2"
                            class="img-fluid"
                            [class.no-image] = "!data.image"
                    />
                  </div>
                </div>
                <div class="d-fflex align-items-center justify-content-between mt-1">
                  <div
                          class="card-car-tittle"
                          style="font-weight: 800; text-transform: uppercase"
                  >
                    {{ data.name }}
                  </div>
                  <div class="card-price-0 mt-2">
                    <span class="card-subheader">STARTING AT {{ "$" + formatPrice(data.price) }}</span>
                    <span style="cursor: pointer" class="sup-star" (click)="openDialog('lexus-msrp')"
                    >*</span><br />
                  </div>
                </div>
              </div>

            </div>
          </a>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="container">

<!--  <div class="model-info-p">-->

<!--    <div class="row">-->
<!--      <div class="col-lg-4 col-xl-3 row-cols-xxxl-5 col-md-6 col-sm-12 mb-4 border-bottom1" *ngFor="let data of finalResult">-->
<!--        <a (click)="navigateToGrade($event, data)" class="general-router-lx">-->
<!--          <div class="p-3 lxcard"  [class.active-model]="lexmodelCodeSelected==data.code"  >-->
<!--            <div class="col-mdd">-->
<!--              <div class="mb-lg-0">-->
<!--                -->
<!--                  <div-->
<!--                    class="bg-image hover-overlay ripple shadow-1-strong rounded"-->
<!--                    data-ripple-color="light"-->
<!--                  >-->
<!--                    <img-->
<!--                      alt="Car Image"-->
<!--                      src="{{ data.image? data.image: 'assets/images/default-car-lexus.png'}}"-->
<!--                      class="ml-lg-5 order-1 order-lg-2"-->
<!--                      class="img-fluid"-->
<!--                      [class.no-image] = "!data.image"-->
<!--                    />-->
<!--                  </div>-->
<!--              </div>-->
<!--              <div class="d-fflex align-items-center justify-content-between mt-1">-->
<!--                <div-->
<!--                  class="card-car-tittle"-->
<!--                  style="font-weight: 800; text-transform: uppercase"-->
<!--                >-->
<!--                  {{ data.name }}-->
<!--                </div>-->
<!--                <div class="card-price-0 mt-2">-->
<!--                  <span class="card-subheader">STARTING AT {{ "$" + formatPrice(data.price) }}</span> -->
<!--                  <span style="cursor: pointer" class="sup-star" (click)="openDialog('lexus-msrp')"-->
<!--                    >*</span><br />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--        </a>  -->
<!--      </div>-->
<!--    </div>-->
<!--    -->
<!--  </div>-->
</div>
