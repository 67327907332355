<!-- <div class="steps-navs mb-1">
    <div class="container-na ">
        <div class="mat-tab-group">
            <div class="mat-tab" [class.active]="i===activeLink" *ngFor="let link of links; let i = index" (click)="redirectToSteps(i)">
                <span class="nav-title">{{link}} </span>
            </div>
            <div class="mat-tab" (click)="redirectToFindCar()">
                <div class="find-car-btn">Find Car</div>
            </div>
        </div>
    </div>
</div>-->
<div class="steps-navs-toyota">
    <mat-tab-group class="steps-navs" mat-stretch-tabs (selectedTabChange)="redirectToSteps($event)" (wheel)="scrollTabs($event)" #tabGroup [selectedIndex]="activeLink">
        <mat-tab *ngFor="let link of links; let i = index" 
            class="mat-tab"
            [class.active]="i===activeLink"
        >
            <ng-template mat-tab-label>
            {{link}}
            </ng-template>
        </mat-tab>
        <mat-tab class="mat-tab" [disabled]="!isAllSelected">
            <ng-template mat-tab-label>
                <div class="find-car-btn" [class.car-btn-disabled]="!isAllSelected" (click)="redirectToFindCar()">Find Car</div>
            </ng-template>
        </mat-tab>
    </mat-tab-group> 
</div>
<!-- <nav mat-tab-nav-bar class="steps-navs" [tabPanel]="tabPanel" >
    <a mat-tab-link class="mat-tab " *ngFor="let link of links; let i = index"
    [class.active]="i===activeLink"
    (click)="redirectToSteps(i)"
      > {{link}} </a>
    <a mat-tab-link disabled><div class="find-car-btn">Find Car</div></a>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel> -->