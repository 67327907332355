<a
  [routerLink]="['exteriorcolor']"
  [state]="{ row: rowData, displayText: this.displayText }"
  class="general-router"
>
  <!-- the vehicle is NOT a truck (i.e. does not have a cab) -->

  <div *ngIf="!rowData.cab_title" class="mb-3 bg-white">
    <div
      class="interior-color"
      id="{{ rowData.code }}"
      [class.pwr-active]="selectedPwrtrain == rowData.code"
    >
      <div class="tcom-card-index">
        <span class="tcom-card-index-layer"></span>
      </div>
      <div class="tcom-card-background"></div>
      <div class="tcom-card-content">
        <div class="tcom-card-title">
          <div>
            {{ rowData?.engine_title + " with " + rowData?.drive_code }}
          </div>
        </div>

        <div class="tcom-card-sub-title mt-2">
          {{ rowData?.transmission_title }}
        </div>

        <div>
          <div class="card-price mt-3">
            {{ "MSRP " + "$" + formatPrice(rowData?.price) }}
          </div>
        </div>

        <div>
          <ul class="tcom-card-bullets">
            <li
              *ngIf="rowData.engine_description"
              [innerHtml]="formatText(rowData.engine_description)"
            ></li>
            <!-- <li
              *ngIf="rowData.drive_description"
              [innerHtml]="formatText(rowData.drive_description)"
            ></li> -->
            <br />
            <li
              *ngIf="rowData.transmission_description"
              [innerHtml]="formatText(rowData.transmission_description)"
            ></li>
            <br *ngIf="rowData.transmission_description" />
            <li *ngIf="rowData.seating_description">
              <h4 class="font-weight-bold my-1">
                {{ "Seating: " + rowData?.seating_description }}
              </h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- the vehicle IS a truck (i.e. does have a cab) -->

  <div *ngIf="rowData.cab_title" class="mb-3 bg-white">
    <div
      class="interior-color"
      id="{{ rowData.code }}"
      [class.pwr-active]="selectedPwrtrain == rowData.code"
    >
      <div class="tcom-card-index">
        <span class="tcom-card-index-layer"></span>
      </div>
      <div class="tcom-card-background"></div>
      <div class="tcom-card-content">
        <div class="tcom-card-title">
          <div>
            {{ rowData?.cab_title + " with " + rowData?.bed_title }}
          </div>
        </div>

        <div>
          <ul class="tcom-card-bullets">
            <li [innerHtml]="formatText(rowData?.cab_description)"></li>
            <br />
            <li [innerHtml]="formatText(rowData?.bed_description)"></li>
            <br />
          </ul>
        </div>

        <div>
          <div class="card-price mt-3">
            {{ "MSRP " + "$" + formatPrice(rowData?.price) }}
          </div>
        </div>

        <hr class="solid mt-4" />

        <div class="tcom-card-title">
          <div>
            {{ rowData?.engine_title + " with " + rowData?.drive_title }}
          </div>
        </div>

        <div class="tcom-card-sub-title">
          {{ rowData?.transmission_title }}
        </div>

        <div>
          <div class="tcom-card-bullets">
            {{ formatText(rowData?.drive_description) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
