import { Component, OnInit } from "@angular/core";
import { elementAt, forkJoin, map, Subject, take, takeUntil } from "rxjs";
import { UiStateService } from "src/app/service/ui-state.service";
import * as _ from "lodash";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { OptionPopupComponent } from "../../shared/option-popup/option-popup.component";
import { DisclaimerPopupComponent } from "../../shared/disclaimer-popup/disclaimer-popup.component";
import { FindMyCarService } from "src/app/service/find-my-car.service";
import { OptionRequirementComponent } from "../../shared/option-requirement/option-requirement.component";
import { LexusDisclaimerPopupComponent } from "../../shared/lexus-disclaimer-popup/lexus-disclaimer-popup.component";
import { TOYOTA_BASE_URL } from "src/app/constants/imagebase.constants";
import { DEALER_USER } from "src/app/constants";

@Component({
  selector: "dsmds-lexus-option",
  templateUrl: "./lexus-option.component.html",
  styleUrls: ["./lexus-option.component.css"],
})
export class LexusOptionComponent implements OnInit {
  code: any;
  lexDisplayText: any;
  selected_option: any;
  options: any = [];
  package: any = [];
  option_list: any = [];
  required_option_code: any = [];
  removeOption: any = [];
  constrain = [];
  requirementinfo = [];
  requirement = [];
  loading = false;
  unsubscribe$ = new Subject();
  packagedata: any = {};
  selectedstate: any;
  constructor(
    private appState: UiStateService,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public findCarService: FindMyCarService
  ) {
    this.code = this.activeRoute.snapshot.params["gradeCode"];
    this.packagedata["gradeCode"] = this.code;
  }

  ngOnInit(): void {
    /* Extract information from uisate */
    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        // console.log('state',state)
        this.selectedstate = state;

        if (state.lexSelectedExterior === undefined) {
          this.router.navigate(["/"]);
        }
        this.lexDisplayText = this.lexDisplayText =
          state.lexGradeTitle +
          " " +
          state.lexDriveTitle +
          " IN " +
          state.lexDisplayExterior +
          " WITH " +
          state.lexSelectedInterior?.title.toUpperCase();
        /* Initialize data from ui state */
        const extCode = state?.lexSelectedExterior?.code;
        const intCode = state?.lexSelectedInterior?.code;
        const conflictinfo = state?.lexConflictInfo;
        const requirementinfo = state?.lexRequirementInfo;
        this.requirementinfo = state.lexRequirementInfo;
        this.required_option_code = state?.lexRequiredOptions;
        /* If conflict and requirement data is not null get list of options and packages */
        if (conflictinfo != null && requirementinfo != null) {
          /* Get list of codes that are in conflict with exterior and interior code */
          this.getConflictOptions(conflictinfo, extCode, intCode);
          const options = state.lexSeriesData.options;
          this.getOptionData(options);
          /* filter list of options and packages that have status live and 
          that are in conflict with selected interior and exterior code */
          this.options = this.options.filter(
            (x) => x.status == "LIVE" && !this.removeOption.includes(x.code)
          );
          this.package = this.package.filter(
            (x) => x.status == "LIVE" && !this.removeOption.includes(x.code)
          );
          /* Select first package to display image */
          this.selected_option =
            this.package.length > 0 ? this.package[0] : this.options[0];
          this.loading = true;
        } else {
          /* If conflict and requirement data is null send a request to api */
          forkJoin({
            conflictinfo: this.findCarService.getOptionConstrain(
              this.code,
              state.lexYear
            ),
            requirementinfo: this.findCarService.getOptionRequirement(
              this.code,
              state.lexYear
            ),
          })
            .pipe(
              takeUntil(this.unsubscribe$),
              map((response) => {
                const conflictinfo = <Array<any>>response.conflictinfo;
                const requirementinfo = <Array<any>>response.requirementinfo;
                this.getConflictOptions(conflictinfo, extCode, intCode);
              })
            )
            .subscribe(() => {
              this.loading = true;
              const options = state.lexSeriesData.options;
              this.getOptionData(options);
              this.options = this.options.filter(
                (x) => x.status == "LIVE" && !this.removeOption.includes(x.code)
              );
              this.package = this.package.filter(
                (x) => x.status == "LIVE" && !this.removeOption.includes(x.code)
              );

              this.selected_option =
                this.package.length > 0 ? this.package[0] : this.options[0];
            });
        }
      });
  }

  getRequiredOptions(requirementinfo, extcode, intcode) {
    /* Filter all packages that have E and I as user seleected E and I 
    and get requirement id */
    const opt_code = [];
    const opt_req = requirementinfo
      .filter(
        (x) =>
          x.item_type_indicator == "I" &&
          x.lead_item_type_indicator == "E" &&
          x.item_code == intcode.slice(-2) &&
          x.lead_item_code == extcode.slice(-3)
      )
      .map(function (obj) {
        return obj.requirement_id;
      });

    /* Get all options that are present in above requirement id */
    opt_req.forEach((element) => {
      opt_code.push(
        requirementinfo
          .filter(
            (x) => x.requirement_id == element && x.item_type_indicator == "O"
          )
          .map(function (obj) {
            return obj.item_code;
          })
      );
    });
  }

  getConflictOptions(conflict, extcode, intcode) {
    /* Group by conflict id and filter conflict id containing exterior
     and interior code */

    const conflictData = _.chain(conflict)
      .groupBy("conflict_id")
      .map((value, key) => ({ title: key, conflict: value }))
      .value();
    /* Get all options that have user selected exterior and interior color code */
    conflictData.forEach((element) => {
      const optInt = element.conflict
        .filter((x) => x.item_type_indicator == "I")
        .map(function (obj) {
          return obj.item_code;
        });
      const optExt = element.conflict
        .filter((x) => x.item_type_indicator == "E")
        .map(function (obj) {
          return obj.item_code;
        });
      const optCode = element.conflict
        .filter((x) => x.item_type_indicator == "O")
        .map(function (obj) {
          return obj.item_code;
        });

      if (
        /* If interior code length and exterior is greter then 0 then
       add option package in remove option List */
        optInt.length > 0 &&
        optExt.length > 0 &&
        optCode.length > 0 &&
        optExt[0] == extcode &&
        optInt[0] == intcode.slice(-2)
      ) {
        this.removeOption.push(...optCode);
      } else if (
        /* If interior code length greater then 0 then
       add option package in remove option List */
        optInt.length > 0 &&
        optInt[0] == intcode.slice(-2) &&
        optCode.length > 0
      ) {
        this.removeOption.push(...optCode);
      } else if (
        /* If exterior code length greater then 0 then
       add option package in remove option List */
        optExt.length > 0 &&
        optExt[0] == extcode &&
        optCode.length > 0
      ) {
        this.removeOption.push(...optCode);
      } else if (
        /* Add remaining options in constrain list. This list essentially
         determines which package is in conflict with other package */
        optInt.length == 0 &&
        optExt.length == 0 &&
        optCode.length > 1
      ) {
        this.constrain.push(optCode);
      }
    });
  }
  /* Extract option information based on previously
    stored series data */
  getOptionData(options) {
    console.log("....................INT........................", options);
    for (let i = 0; i < options.length; i++) {
      let applicable = options[i].applicable;
      for (let j = 0; j < applicable.length; j++) {
        /* Filter packages that have type FIO */
        if (
          applicable[j].trim == this.code &&
          options[i].type == "FIO" &&
          options[i]?.price?.msrp
        ) {
          this.option_list.push({
            code: options[i]?.code,
            title: options[i]?.title,
            description: options[i]?.description,
            price: applicable[j]?.override?.price?.msrp
              ? applicable[j]?.override?.price?.msrp
              : options[i]?.price?.msrp
              ? options[i]?.price?.msrp
              : 0,
            status: options[i]?.visibility?.status?.status_code,
            images: TOYOTA_BASE_URL + options[i]?.images?.full_image,
            add: this.required_option_code.includes(options[i]?.code)
              ? false
              : this.selectedstate?.lexSelectedoptionCode?.indexOf(
                  options[i].code
                ) > -1
              ? false
              : true,
            remove: this.required_option_code.includes(options[i]?.code)
              ? true
              : this.selectedstate?.lexSelectedoptionCode?.indexOf(
                  options[i].code
                ) > -1
              ? true
              : false,
            required_option: this.required_option_code.includes(
              options[i]?.code
            )
              ? true
              : false,
            ispackage: options[i]?.indicators?.ispackage,
          });
        }
      }
    }
    /* Filter into packages/options based on ispackage flag*/
    this.package = this.option_list.filter((x) => x.ispackage);
    this.options = this.option_list.filter((x) => !x.ispackage);
  }

  formatPrice(price) {
    return parseInt(price)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  formatText(text) {
    if (text) {
      text = text.replace("<ul>", "");
      text = text.replace("</ul>", "");
      text = text.replaceAll("li", "div");
      return text.replace(/ *\[[^\]]*]/g, "");
    }
  }

  /* When user adds package check if package exist on option conflict data 
        and also find out dependent packages for data */
  addPackage(data) {
    let isConflict = false;
    let isPackage = false;
    const code = [];
    const conflict_code = [];
    /* Extract all existing code that have been selected by user */
    code.push(
      ...this.options
        .filter((x) => x.remove == true)
        .map(function (obj) {
          return obj.code;
        })
    );
    code.push(
      ...this.package
        .filter((x) => x.remove == true)
        .map(function (obj) {
          return obj.code;
        })
    );
    /* Check if packages are in conflict with existing user selection */
    this.constrain.forEach((element) => {
      if (
        element.includes(data.code) &&
        code.some((r) => element.includes(r))
      ) {
        isConflict = true;
        /* show popup incase of conflict*/
        this.openLexusDialog(
          "The option code " + element + " are conflict with each other"
        );
      }
    });
    /* Incase package is not in conflict get list packages that are 
       required with selected package*/
    if (!isConflict) {
      let opt_data = [];
      let i = 0;
      /* Get required option code*/
      const opt_code = this.getRecursiveOption(data.code);
      /* Exclude all option codes that are in conflict with existing code*/
      opt_code.forEach((x) => {
        this.constrain.forEach((element) => {
          if (
            element.some((r) => x.includes(r)) &&
            code.some((r) => element.includes(r))
          ) {
            opt_code.splice(i, 1);
          }
        });
        i = i + 1;
      });
      if (opt_code.length > 0) {
        let i = 0;
        opt_code.forEach((x) => {
          this.removeOption.forEach((element) => {
            if (x.includes(element)) {
              opt_code.splice(i, 1);
            }
          });
          i = i + 1;
        });
        /* If option package is null show popup that package is not selectable*/
        if (opt_code.length == 0) {
          this.openLexusDialog(
            "This package cannot be added. Select a different exterior/interior color"
          );
        } else {
          /* show popup with list of option code*/
          opt_code.forEach((element) => {
            opt_data.push(
              this.option_list.filter((x) => element.includes(x.code))
            );
          });
          opt_data = _.uniqWith(opt_data, _.isEqual);
          opt_data = opt_data.filter((x) => x.length > 0);
          /* 
            Remove conflict selection [drop_opt_data] from opt_data 
            code:  current in bag package
            opt_data: related packages with selected package
          */
          let drop_opt_data = [];
          code.forEach((c) => {
            this.constrain.forEach((element) => {
              if (element.includes(c)) {
                opt_data[0].forEach((x) => {
                  if (element.includes(x.code)) {
                    drop_opt_data.push(x);
                  }
                });
              }
            });
          });
          drop_opt_data = [...new Set(drop_opt_data)];
          opt_data[0] = opt_data[0].filter(
            (item) => drop_opt_data.indexOf(item) < 0
          );
          /* show popup with list of option code*/
          this.dialog
            .open(OptionRequirementComponent, {
              data: {
                option_data: opt_data,
                selected_interior: [],
                code: this.code,
                type: "package",
              },
            })
            .afterClosed()
            .subscribe((response) => {
              if (response?.data) {
                /* Select option code returned after user confirmation*/
                this.options
                  .filter((x) => response.data.includes(x.code))
                  .map(function (obj) {
                    obj.add = false;
                    obj.remove = true;
                  });
                this.package
                  .filter((x) => response.data.includes(x.code))
                  .map(function (obj) {
                    obj.add = false;
                    obj.remove = true;
                  });
              }
              this.navigateToZipcode({}, "apppackage");
            });
        }
      } else {
        /* Add selected package if not in conflict*/
        data.add = false;
        data.remove = true;
      }
    }
    this.navigateToZipcode({}, "apppackage");
  }

  removePackage(data) {
    /* If package is selected from interior page it cannot be removed*/
    if (data.required_option) {
      this.openDialog("option-required");
    } else {
      data.add = true;
      data.remove = false;
    }
    this.navigateToZipcode({}, "apppackage");
  }

  getImage(data) {
    this.selected_option = data;
  }

  getOption(data) {
    this.dialog.open(OptionPopupComponent, {
      width: "40%",
      data: data,
    });
  }

  /* Navigate to zipcode page */
  navigateToZipcode(event, nav) {
    const code = [];
    /* Extract user selected option code*/
    code.push(
      ...this.options
        .filter((x) => x.remove == true)
        .map(function (obj) {
          return obj.code;
        })
    );
    code.push(
      ...this.package
        .filter((x) => x.remove == true)
        .map(function (obj) {
          return obj.code;
        })
    );
    /* Store selected option code and navigate to zipcode*/

    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        if (state.claims?.user_type == DEALER_USER) {
          const dealer_number = state.claims?.dealer_number;
          this.appState.setState({
            lexSelectedoptionCode: code.sort(),
            lexSelectedDealerCode: dealer_number,
            lexSelectedDealerRadii: 0,
          });
          //event.stopPropagation();
          if (nav === "nextstep") {
            this.router.navigate(["lexus", "findmycar"]);
          }
        }
        else{
          this.appState.setState({
            lexSelectedoptionCode: code.sort(),
          });
          //event.stopPropagation();

          if (nav === "nextstep") {
            this.router.navigate(["lexus", "zipcode"]);
          }
        }
      });
  }

  openDialog(type) {
    this.dialog.open(DisclaimerPopupComponent, {
      data: type,
    });
  }
  openLexusDialog(text) {
    this.dialog.open(LexusDisclaimerPopupComponent, {
      data: text,
    });
  }
  getRecursiveOption(opt_code) {
    /* Filter option code based on selected option code*/
    let opt_code_final = [];
    const opt_req = this.requirementinfo
      .filter(
        (x) => x.lead_item_type_indicator == "O" && x.lead_item_code == opt_code
      )
      .map(function (obj) {
        return obj;
      });

    if (opt_req.length > 0) {
      /*Group based on requirement id and add the option code to list */
      const code_comb = _.chain(opt_req)
        .groupBy("requirement_id")
        .map((value, key) => ({ title: key, item: value }))
        .value();
      code_comb.forEach((x) => {
        const option_ei = x.item
          .filter(
            (x) => x.item_type_indicator == "E" || x.item_type_indicator == "I"
          )
          .map(function (obj) {
            return obj.item_code;
          });
        if (option_ei.length == 0) {
          let op = [];
          x.item.forEach((y) => {
            op.push(y.item_code);
          });
          op.push(x.item[0].lead_item_code);
          opt_code_final.push(op.sort());
        }
      });
    }
    /*Return array of option code list */
    return opt_code_final;
  }

  showOptionOrPackage(code) {
    if (this.selectedstate?.lexSelectedoptionCode?.indexOf(code) > -1) {
      return false;
    } else {
      return true;
    }
  }
}
