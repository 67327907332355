import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES_TOYOTA } from 'src/app/constants';
import { UiStateService } from 'src/app/service/ui-state.service';

@Component({
  selector: 'dsmds-vehicle-card',
  templateUrl: './vehicle-card.component.html',
  styleUrls: ['./vehicle-card.component.css']
})
export class VehicleCardComponent implements OnInit {
  @Input() data:any ={}
  @Input() category:string = ''
  @Input() selectedModel:string = null
  @Output() openDialogFromChild = new EventEmitter<string>();  

  constructor(
    public router: Router,
    private appstate: UiStateService
    ) {
    
   }

  ngOnInit(): void {
  }

  saveModelAndRedirect(){
    if(this.selectedModel && this.selectedModel !== this.data.code){
      //Remove all selected features
      this.appstate?.setState({
        seriescode: this.data.code,
        seriesCategory: this.category,
        year: this.data?.year,
        series: this.data?.name,
        grade: null,
        gradeData:null,
        modelNo: null,
        grade_attributes:null,
        option_data: null,
        exterior_color:null,
        interior_color:null,
        option_list: null,
        option_msrp:null,
        optionData:null
      });
    }
    else{
      this.appstate?.setState({
        seriescode: this.data.code,
        seriesCategory: this.category,
        year: this.data?.year,
        series: this.data?.name,
      });
    }
    
    this.router.navigate(ROUTES_TOYOTA.slice(0, 2));
  }
  callParentPrice() {
    this.openDialogFromChild.emit('msrp');
  }
  callParentMpg() {
    this.openDialogFromChild.emit('mpg');
  }

}
