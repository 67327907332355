import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "dsmds-lexus-disclaimer-popup",
  templateUrl: "./lexus-disclaimer-popup.component.html",
  styleUrls: ["./lexus-disclaimer-popup.component.css"],
})
export class LexusDisclaimerPopupComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {}
}
