import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { map, Observable, startWith, take ,Subject} from 'rxjs';
import { ObservableDataShareService } from 'src/app/service/data-share/observable-data-share.service';
import { LocalStorageService } from 'src/app/service/storage/local-storage.service';
import { UiStateService } from 'src/app/service/ui-state.service';

@Component({
  selector: 'dsmds-dealer-selection-modal',
  templateUrl: './dealer-selection-modal.component.html',
  styleUrls: ['./dealer-selection-modal.component.css']
})
export class DealerSelectionModalComponent implements OnInit {
  dealer_list:any = []
  selectedDealer:any;
  currentDealer:any;
  filteredOptions: Observable<any[]>;
  myControl = new FormControl();
  constructor(
    private appState: UiStateService,
    private lStorage: LocalStorageService,
    private obDataShareService: ObservableDataShareService,
    public dialogRef: MatDialogRef<DealerSelectionModalComponent>
    ) { }

  ngOnInit(): void {
    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        this.dealer_list = state.dealer_list;

        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value.dealer_number || value.name)),
          map(name => (name ? this._filter(name) : this.dealer_list.slice())),
        );
        
        if(state.current_dealer){
          this.currentDealer = state.current_dealer
        }
        else if(this.lStorage.checkItem('current_dealer')){
          this.currentDealer = this.lStorage.getItem('current_dealer')
        }
        this.selectedDealer = this.currentDealer
      });
  }

  onSelectDealer(dealer){
    this.selectedDealer = dealer
  }

  cancel() {
    if(this.selectedDealer ){
      this.dialogRef.close({ data: null })
    }
  }

  confirm() {
      
    if(this.selectedDealer ){
      this.appState.setState({
        current_dealer: this.selectedDealer,
      });
      //this.lStorage.setItem('current_dealer', this.selectedDealer)
      this.obDataShareService.updateCurrentDealer(this.selectedDealer)
      this.dialogRef.close({ data: this.selectedDealer });
      this.removePastState();
      
    }
  }
  displayFn(rowInfo: any) {
    if (rowInfo && rowInfo?.dealer_number )
      return rowInfo?.dealer_number;
    else
      return null
  }
  private _filter(name) {
    const filterValue = name.toLowerCase();
    return this.dealer_list.filter(option => option.dealer_number.includes(filterValue) || option.name.toLowerCase().includes(filterValue));
  }

  removePastState() {
    this.appState?.setState({
      seriescode: null,
      seriesCategory: null,
      year: null,
      series: null,
      grade: null,
      gradeData:null,
      modelNo: null,
      grade_attributes:null,
      option_data: null,
      exterior_color:null,
      interior_color:null,
      option_list: null,
      option_msrp:null,
      optionData:null
    });
    this.appState?.setState({
      lexSeriesCode: null,
      lexSeriesTitle: null,
      lexYear: null,
      lexModelCode:null,
      lexConflictInfo:null,
      lexDisplayExterior:null,
      lexDisplayText: null,
      lexDriveTitle: null,
      lexExtImageData: null,
      lexGradeCode: null,
      lexGradeTitle: null,
      lexIntImageData: null, 
      lexRequiredOptions:null, 
      lexRequirementInfo:null,
      lexSelectedExterior: null,
      lexSelectedGradePrice:null,
      lexSelectedInterior:null,
      lexSeriesData: null,
      lexextcode: null,
      lexintcode:null
      
    });
  }
  
  
}
