import { Component, OnDestroy, OnInit } from "@angular/core";
import { FindMyCarService } from "../../../service/find-my-car.service";
import * as _ from "lodash";
import { DisclaimerPopupComponent } from "../../shared/disclaimer-popup/disclaimer-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { forkJoin, map, Subject, takeUntil, take } from "rxjs";
import { TOYOTA_BASE_URL } from "src/app/constants/imagebase.constants";
import { UiStateService } from "src/app/service/ui-state.service";
import { Router } from "@angular/router";

import { errorStatusCode } from "src/app/utils/custom.function";
@Component({
  selector: "app-model-info",
  templateUrl: "./model-info.component.html",
  styleUrls: ["./model-info.component.css"],
})
export class ModelInfoComponent implements OnInit, OnDestroy {
  gridClass =
    "col-lg-4 col-xl-3 row-cols-xxxl-5 col-md-6 col-sm-12 mb-4 mb-lg-0";
  category = "Cars & Minivan";
  categoryList = [
    "Cars & Minivan",
    "Trucks",
    "Crossovers & SUVs",
    "Electrified",
  ];
  modelData: any = [];
  imageData: any = [];
  finalResult: any = [];
  crossover: any = [];
  minivan: any = [];
  electrified: any = [];
  truck: any = [];
  unsubscribe$ = new Subject();
  selectedModel: string;

  constructor(
    private findCarService: FindMyCarService,
    public dialog: MatDialog,
    private appstate: UiStateService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    try {
      this.appstate
        .getState()
        .pipe(take(1))
        .subscribe((state) => {
          if (!state.hasOwnProperty("otd_api_key") || !state?.otd_api_key) {
            this.router.navigate(["/"]);
          }
          if (state?.seriesCategory) {
            this.category = state?.seriesCategory;
            this.selectedModel = state?.seriescode;
          }
        });
    } catch (error) {
      console.log(error);
    }

    try {
      /* Parse data to get series info and different category 
       associated with series information */
      forkJoin({
        modelinfo: this.findCarService.getToyotaApi("toyota"),
        imageinfo: this.findCarService.getModelImage("TOY"),
      })
        .pipe(
          takeUntil(this.unsubscribe$),
          map((response) => {
            const modelinfo = <Array<any>>response.modelinfo;
            const imageinfo = <Array<any>>response.imageinfo;

            const modelinfo_detail = this.getModelInfo(modelinfo);
            const imageinfo_detail = this.getImage(imageinfo);

            const finalResultMerged = _.merge(
              {},
              _.keyBy(modelinfo_detail, "code"),
              _.keyBy(imageinfo_detail, "code")
            );
            this.finalResult = _.toArray(finalResultMerged);
            return this.finalResult;
          })
        )
        .subscribe(
          () => {
            this.getCategoryResult();
          },
          (error) => {
            errorStatusCode(error);
            console.log("infopage1", error);
          }
        );
    } catch (error) {
      console.log("infopage", error);
    }
  }

  openDialog(type) {
    this.dialog.open(DisclaimerPopupComponent, {
      data: type,
    });
  }

  openSeriesCategory(type) {
    this.category = type;
  }

  getModelInfo(modelinfo) {
    const seriesList = _.values(modelinfo["seriesList"]).flat();
    for (let i = 0; i < seriesList.length; i++) {
      const series = seriesList[i].years;
      for (let j = 0; j < series.length; j++) {
        this.modelData.push({
          year: parseInt(series[j]?.year),
          code: seriesList[i]?.seriesId,
          name: seriesList[i]?.seriesName,
          city_mpg: series[j]?.mileage.city ? series[j]?.mileage.city : 0,
          combined_mpg: series[j]?.mileage?.combined
            ? series[j]?.mileage?.combined
            : 0,
          highway_mpg: series[j]?.mileage?.highway
            ? series[j]?.mileage?.highway
            : 0,
          price: parseInt(series[j]?.startingMsrp)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          category: series[j]?.seriesCategory,
        });
      }
    }
    // Get Latest year veichle
    this.modelData = _(this.modelData)
      .groupBy("code")
      .map((group) => _.maxBy(group, "year"))
      .value();

    this.modelData.forEach((element) => {
      if (element.code.includes("gr")) {
        //CHECK IF A NON-GR VERSION EXISTS
        let strippedcode = element.code.replace("gr", "");

        this.modelData.forEach((innerel) => {
          if (innerel.code == strippedcode) {
            //there is a non-gr version: do NOT replace!
            console.log(
              "NOT stripping gr from",
              element.code,
              "because",
              innerel.code,
              "exists"
            );
            strippedcode = -1;
          }
        });

        //end of for loop: if no non-gr versions found, then replace
        if (strippedcode != -1) {
          console.log("stripping gr from", element.code);
          element.code = strippedcode;
        }
      }
    });
    return this.modelData;
  }

  /* Parse  series info to get exterior image
   for each series */
  getImage(imageinfo): any {
    const image_detail = imageinfo["years_list"];
    image_detail.forEach((element) => {
      element.series_list.forEach((image) => {
        this.imageData.push({
          year: parseInt(element?.year),
          code: image?.series,
          image:
            TOYOTA_BASE_URL + this.getExteriorImage(image?.colors?.exterior),
        });
      });
    });
    // Get Latest year veichle
    this.imageData = _(this.imageData)
      .groupBy("code")
      .map((group) => _.maxBy(group, "year"))
      .value();

    return this.imageData;
  }

  getExteriorImage(exterior) {
    if (exterior) {
      for (let i = 0; i < exterior.length; i++) {
        if (exterior[i]?.applicable[0]?.override?.images?.car_jelly_image) {
          return exterior[i].applicable[0].override.images.car_jelly_image;
        }
      }
    }
    return null;
  }

  /* Based on category store result in 4 buckets */
  getCategoryResult() {
    console.log("this.finalResult", this.finalResult);
    for (let i = 0; i < this.finalResult.length; i++) {
      if (this.finalResult[i]?.category.indexOf("Crossovers & SUVs") !== -1) {
        this.crossover.push(this.finalResult[i]);
      }
      if (this.finalResult[i]?.category.indexOf("Cars & Minivan") !== -1) {
        this.minivan.push(this.finalResult[i]);
      }
      if (this.finalResult[i]?.category.indexOf("Electrified") !== -1) {
        this.electrified.push(this.finalResult[i]);
      }
      if (this.finalResult[i]?.category.indexOf("Trucks") !== -1) {
        this.truck.push(this.finalResult[i]);
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.complete();
  }
}
