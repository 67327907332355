import { Component, ComponentFactoryResolver, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { forkJoin, map, Subject, take, takeUntil } from "rxjs";
import { LEXUS_BASE_URL } from "src/app/constants/imagebase.constants";
import { FindMyCarService } from "src/app/service/find-my-car.service";
import { UiStateService } from "src/app/service/ui-state.service";
import { DisclaimerPopupComponent } from "../../shared/disclaimer-popup/disclaimer-popup.component";

@Component({
  selector: "dsmds-lexus-exterior",
  templateUrl: "./lexus-exterior.component.html",
  styleUrls: ["./lexus-exterior.component.css"],
})
export class LexusExteriorComponent implements OnInit {
  exterior_color: any = [];
  extImageResult: any = [];
  selected_exterior: any = null;
  lexDisplayText: any;
  code: any;
  unsubscribe$ = new Subject();
  loading = false;
  exteriordata: any = {};
  lexextcolorSelected: any = null;
  constructor(
    private appState: UiStateService,
    public dialog: MatDialog,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public findCarService: FindMyCarService
  ) {
    /* Extract model no from route */
    this.code = this.activeRoute?.snapshot.params["gradeCode"];
    this.exteriordata["gradeCode"] = this.code;

    this.appState?.setState({
      lexConflictInfo: null,
      lexRequirementInfo: null,
    });
  }

  ngOnInit(): void {
    this.getOptionData();
  }

  getExtImg(ext_image) {
    //console.log('EXTIMG..................',ext_image.code);
    this.selected_exterior = ext_image;
  }

  getImage(ext_img) {
    let model = _.values(ext_img).flat();
    model.forEach((element) => {
      //console.log('...........last2',element["3dimage"]["700_645_PNG"]["1"][0]);
      let url = "assets/images/default-car-lexus.png";
      if (
        element["3dimage"] &&
        element["3dimage"]["700_645_PNG"] &&
        element["3dimage"]["700_645_PNG"]["1"] &&
        element["3dimage"]["700_645_PNG"]["1"][0]
      ) {
        url = LEXUS_BASE_URL + element["3dimage"]["700_645_PNG"]["1"][0];
      }
      this.extImageResult.push({
        code: element?.code,
        ext_image: url,
      });
    });
  }

  getExteriorColor(exterior_color) {
    if (exterior_color) {
      for (let i = 0; i < exterior_color.length; i++) {
        if (exterior_color[i].hasOwnProperty("applicable")) {
          let applicable = exterior_color[i]?.applicable;
          for (let j = 0; j < applicable?.length; j++) {
            if (applicable[j].trim == this.code) {
              this.exterior_color.push({
                price: exterior_color[i]?.price
                  ? parseInt(exterior_color[i]?.price?.msrp)
                  : 0,
                code: exterior_color[i].code,
                codeExt: exterior_color[i]?.code
                  ? exterior_color[i]?.code.slice(-3)
                  : exterior_color[i]?.code,
                title: exterior_color[i].title,
                image:
                  LEXUS_BASE_URL +
                  applicable[j]?.override?.images?.on_swatch_image,
                status: exterior_color[i]?.visibility?.status?.status_code,
              });
              //console.log('9400000000000000',exterior_color);
            }
          }
        }
      }
    }
  }

  formatText(data) {
    let colorName = data.title;
    colorName = colorName?.replace(/ *\[[^\]]*]/g, "");
    if (data.price != 0) {
      colorName = colorName?.replace("[extra_cost_color]", "");
      colorName = colorName?.replace(/ *\[[^\]]*]/g, "");
      colorName = colorName + " " + "$" + data.price;
    }
    return colorName;
  }

  formatDisplayText(text) {
    if (text) {
      return text.replace(/ *\[[^\]]*]/g, "").toUpperCase();
    }
  }
  openDialog(type) {
    this.dialog.open(DisclaimerPopupComponent, {
      data: type,
    });
  }

  navigateToInteriorColor(event, selected_exterior) {
    // console.log(selected_exterior, 'sssssss')
    this.appState.setState({
      lexDisplayExterior: this.formatDisplayText(selected_exterior?.title),
      lexSelectedExterior: selected_exterior,
      lexextcode: selected_exterior.code,
      lexDisplayText:
        this.lexDisplayText +
        " IN " +
        this.formatDisplayText(selected_exterior.title),
      lexSelectedInterior: null,
      lexintcode: null,
    });
    event.stopPropagation();
    this.router.navigate(["lexus", "interior", this.code]);
  }

  formatPrice(price) {
    return parseInt(price)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  /* Extract exterior color information and option constrain 
     and option requirement based on model no */
  getOptionData() {
    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        if (state.lexYear === undefined) {
          this.router.navigate(["/"]);
        }
        forkJoin({
          conflictinfo: this.findCarService.getOptionConstrain(
            this.code,
            state.lexYear
          ),
          requirementinfo: this.findCarService.getOptionRequirement(
            this.code,
            state.lexYear
          ),
        })
          .pipe(
            takeUntil(this.unsubscribe$),
            map((response) => {
              const conflictinfo = <Array<any>>response.conflictinfo;
              const requirementinfo = <Array<any>>response.requirementinfo;
              this.appState.setState({
                lexConflictInfo: conflictinfo,
                lexRequirementInfo: requirementinfo,
              });
              this.getExteriorInfo(requirementinfo);
            })
          )
          .subscribe(() => {});
      });
  }

  getExteriorInfo(requirementinfo) {
    let extCode = [];
    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        this.lexDisplayText = state.lexGradeTitle + " " + state.lexDriveTitle;
        const exterior_color = state.lexSeriesData?.colors?.exterior;
        this.getExteriorColor(exterior_color);
        this.getImage(state.lexExtImageData);
        const finalResultMerged = _.merge(
          {},
          _.keyBy(this.exterior_color, "code"),
          _.keyBy(this.extImageResult, "code")
        );
        requirementinfo
          .filter(
            (x) =>
              x.item_type_indicator == "I" && x.lead_item_type_indicator == "E"
          )
          .map(function (obj) {
            extCode.push(obj.lead_item_code);
          });
        extCode = extCode.filter(function (item, index, inputArray) {
          return inputArray.indexOf(item) == index;
        });
        this.exterior_color = _.toArray(finalResultMerged);
        if (extCode.length > 0) {
          this.exterior_color = this.exterior_color.filter(
            (x) => x.status == "LIVE" && extCode.includes(x.codeExt)
          );
        } else {
          this.exterior_color = this.exterior_color.filter(
            (x) => x.status == "LIVE"
          );
        }
        this.selected_exterior = this.exterior_color[0];
        this.appState
          ?.getState()
          .pipe(take(1))
          .subscribe((state) => {
            if (
              state &&
              state.hasOwnProperty("lexextcode") &&
              state.lexextcode
            ) {
              this.lexextcolorSelected = state.lexextcode;
              let temp = this.exterior_color.filter(
                (x) => x.code == this.lexextcolorSelected
              );
              this.selected_exterior = temp[0];
            }
          });
        this.loading = true;
      });
    //console.log('............................exterior.',this.exterior_color);
  }
}
