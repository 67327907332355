<mat-icon class="btn-close" mat-dialog-close>close</mat-icon>
<br />
<br />
<p *ngIf="data == 'msrp'">
  <sup>*</sup>MSRP excludes the Delivery, Processing, and Handling Fee of $1,025
  for Cars (86, Avalon, Avalon HV, Camry, Camry HV, Corolla, Corolla HV, Corolla
  HB, Mirai, Prius, Prius Prime, Supra), $1,215 for SUV/Van/Small Trucks
  (4Runner, bZ4X, C-HR, Corolla Cross, Highlander, Highlander HV, RAV4, RAV4 HV,
  RAV4 Prime, Sienna, Tacoma, Venza), $1,495 for Large SUVs (Land Cruiser,
  Sequoia), and $1,695 for Large Truck (Tundra). (Historically, vehicle
  manufacturers and distributors have charged a separate fee for processing,
  handling and delivering vehicles to dealerships. Toyota's charge for these
  services is called the "Delivery, Processing and Handling Fee" and is based on
  the value of the processing, handling and delivery services Toyota provides as
  well as Toyota's overall pricing structure and may be subject to change at any
  time. Toyota may make a profit on the Delivery, Processing and Handling Fee.)
  Excludes taxes, license, title and available or regionally required equipment.
  The Delivery, Processing and Handling Fee in AL, AR, FL, GA, LA, MS, NC, OK,
  SC and TX will be higher. The published prices do not apply to Puerto Rico and
  the U.S. Virgin Islands. Actual dealer price will vary.
</p>
<p *ngIf="data == 'mpg'">
  <sup>*</sup>EPA-estimated city, hwy, and combined mpg rating for vehicle. Use
  for comparison purposes only. Your mileage will vary for many reasons,
  including your vehicle’s condition and how/where you drive. See
  www.fueleconomy.gov.
</p>
<p *ngIf="data == 'similarity'">
  Each vehicle option presented by Smart Fulfillment Engine “SFE” is assigned an
  estimated overall “similarity/matching score” <br />
  <br />
  The aim of this matching score is to assist dealers help customers in their
  purchasing journey, by providing a single and easy-to-understand metric that
  summarizes the approximate similarity between 2 vehicle configurations <br />
  <br />
  The matching score presented is always relative to customer’s desired vehicle
  configuration that was inputted (ie, the seed vehicle) <br />
  The matching score is normalized to be between 0% and 100%: 2 exact same
  configurations will result in a 100% matching score, and for increasingly
  different configurations, the matching score will decrease toward 0% <br />
  <br />
  In this context, matching score aims to approximate similarity as perceived
  from the eyes of an average dealer/buyer. It is important to note that, there
  isn’t a single and accurate matching score as: <br />
  <br />
  (1) similarity can be measured differently for different personas (eg,
  engineering vs. dealers) <br />
  <br />
  (2) by definition, similarity depends on each individual’s perception (eg, one
  person may treat 2 different exterior colors more/less similar than another
  person) <br />
  <br />
  For the purposes of SFE MVP, the matching score is calculated by comparing the
  list of main features of 2 cars including attributes such as grade, drive
  type, packages (e.g., audio, weather, convenience, technology), interior
  color, interior material, and exterior color. <br />
  <br />
  Similarity importance of each attribute is estimated by assigning a percent
  weight (e.g., interior color will have a higher %-weight than exterior color,
  if it’s estimated to be more important for similarity purposes) <br />
  <br />
  Attributes are further broken down into levels, in other words specific
  choices within the attribute (eg., LE, XLE, XLE Premium within grade; Lunar
  Rock, Cavalry Blue, Magnetic Gray within exterior color, etc.). Similarity
  between levels is estimated on a scale of 0 to 1. <br />
  <br />
  The above-described methodology is codified analytically into “the matching
  engine” component of Smart Fulfillment Engine. Features for comparison,
  weights and similarity scores are selected and/or calibrated by DSM & TMNA
  based on their knowledge of Toyota vehicles.
</p>
<p *ngIf="data == 'eta'">Earliest available times are estimated</p>
<p *ngIf="data == 'swStart'">Estimated Dealer Arrival</p>
<p *ngIf="data == 'swEnd'">Smart window end date</p>
<p *ngIf="data == 'lexus-msrp'">
  <sup>*</sup>MSRP includes delivery, processing and handling fee and may be
  subject to change at any time. Excludes taxes, title, license and optional
  equipment. Dealer price will vary.
</p>

<p *ngIf="data == 'findcar-msrp'">
  <sup>*</sup>MSRP may vary across vehicles depending on features, production
  timing and other factors.
</p>
<p *ngIf="data == 'option-required'">
  <sup>*</sup>This a required option Package.Inorder to remove you will need to
  change exterior and interior color.
</p>
