import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { NullValidationHandler, OAuthService } from "angular-oauth2-oidc";
import { filter, take } from "rxjs";
import { AnalyticsService } from "./service/analytics/analytics.service";
import {
  ANALYTICS_TEMPLATE_USER_LOGIN,
  ANALYTICS_TEMPLATE_USER_LOGOUT,
  ANALYTICS_CAPTURE_USER_LOGIN_LOCALLY,
} from "src/app/constants/analytics.constants";
import { UiStateService } from "./service/ui-state.service";
import { isLocal, isLocalOrDev } from "./utils";
import { DealerSelectionModalComponent } from "./modules/shared/dealer-selection-modal/dealer-selection-modal.component";
import { AnalyticsModalComponent } from "./modules/shared/analytics-modal/analytics-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiRouteService } from "./config/api-routes";
import {
  authCodeFlowConfigDev,
  authCodeFlowConfigQa,
  authCodeFlowConfigUat,
  authCodeFlowConfigProd,
  authCodeFlowConfiglocal,
} from "./sso.config";
import { primaryRoleValidation, secondaryRoleValidation } from "./utils";
import { LocalStorageService } from "./service/storage/local-storage.service";
import { FeatureFlagService } from "./service/feature-flag/feature-flag.service";
import { ObservableDataShareService } from "./service/data-share/observable-data-share.service";
import {
  PRIMARY_USER_ROLES,
  SECONDARY_USER_ROLES,
  PROVIDE_FEEDBACK_URL,
  DEV_BASE_URL,
  QA_BASE_URL,
  UAT_BASE_URL,
  PROD_BASE_URL,
  LEXUS_LOGO_URL,
} from "./constants";
import { FEATURE_FLAG_ANALYTICS } from "./constants/flags.constants";
import { v4 as uuidv4 } from "uuid";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  host: { "(document:click)": "handleClick($event)" },
})
export class AppComponent implements OnInit {
  title = "SFE";
  loading = false;
  showLogo: boolean = false;
  imgProfile = "/assets/images/profile_icon.svg";
  isLexus: string = "";
  isLandingPage: boolean = true;
  claims: any;
  toyota_dealer = [0, 1, 2, 3, 4, 5];
  profileOwner = "";
  profileDealer: any;
  profileType = "";
  feedbackUrl: String = "";
  lexusLogoUrl: String = "";
  userActivity: any;
  lastCheckIn: any = new Date();
  TIMEOUT_SECS: number = 60 * 2 * 1;
  showAnalytics = false;
  sessionId = "";

  //NOTE: By default, don't capture analytics for user login on local environment
  // to prevent flood of data for every recompile.
  // Change in 'analytics.contants.ts' to change behavior.
  captureUserLogin = !isLocal() || ANALYTICS_CAPTURE_USER_LOGIN_LOCALLY;

  @HostListener("window:beforeunload", ["$event"])
  async beforeUnloadHandler(event: any) {
    await this.captureLogout();
    //this.debugTime();
    //this.debugWait(30000);
  }

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private appState: UiStateService,
    private lStorage: LocalStorageService,
    private obDataShareService: ObservableDataShareService,
    private http: HttpClient,
    private apiRouteService: ApiRouteService,
    private analyticsService: AnalyticsService,
    private featureFlagService: FeatureFlagService,
    public dialog: MatDialog
  ) {
    this.configureSingleSignOn();

    router?.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes("lexus")) {
          this.isLexus = "L";
          this.imgProfile = "/assets/images/profile_icon_lexus.svg";
          if (event.url !== "/lexus/findmycar") {
            this.showLogo = true;
          } else {
            this.showLogo = false;
          }
        } else if (event.url.includes("/toyota")) {
          this.isLexus = "T";
          if (
            event.url !==
            "/toyota/trim/powertrain/exteriorcolor/interiorColor/packages/zipcode/findMyCar"
          ) {
            this.showLogo = true;
          } else {
            this.showLogo = false;
          }
        }
        if (
          event.url === "/" ||
          event.url === "/toyota" ||
          event.url === "/lexus"
        ) {
          this.isLandingPage = true;
        } else {
          this.isLandingPage = false;
        }
      }
    });
    this.obDataShareService?.currentDealerData.subscribe((data) => {
      if (data && data !== "") {
        this.profileDealer = data;
        if (this.profileDealer?.brand === "LEXUS") {
          this.isLexus = "L";
        } else {
          this.isLexus = "T";
        }
      }
    });
    this.setTimeout();
    this.obDataShareService.userLastTime.subscribe((dateValue) => {
      if (dateValue && dateValue?.last) {
        const timeDiff = dateValue?.new.getTime() - dateValue?.last.getTime();
        if (Math.floor(timeDiff / 1000) < 120) {
          this.lastCheckIn = new Date();
        }
      }
    });
  }

  async configureSingleSignOn(): Promise<any> {
    this.loading = false;
    return new Promise<void>((resolveFn, rejectFn) => {
      if (window.location.hostname == DEV_BASE_URL) {
        this.oauthService.configure(authCodeFlowConfigDev);
      } else if (window.location.hostname == QA_BASE_URL) {
        this.oauthService.configure(authCodeFlowConfigQa);
      } else if (window.location.hostname == UAT_BASE_URL) {
        this.oauthService.configure(authCodeFlowConfigUat);
      } else if (window.location.hostname == PROD_BASE_URL) {
        this.oauthService.configure(authCodeFlowConfigProd);
      } else {
        this.oauthService.configure(authCodeFlowConfiglocal);
      }
      // setup oauthService
      this.oauthService.setStorage(localStorage);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();
      this.oauthService.timeoutFactor = 0.7;
      this.oauthService.setupAutomaticSilentRefresh();

      // subscribe to token events
      this.oauthService.events
        .pipe(
          filter((e: any) => {
            return e.type === "token_received";
          })
        )
        .subscribe(() => {});

      this.oauthService.loadDiscoveryDocumentAndLogin().then((isLoggedIn) => {
        const hasIdToken = this.oauthService.hasValidIdToken();
        const hasAccessToken = this.oauthService.hasValidAccessToken();
        if (isLoggedIn) {
          if (!hasIdToken || !hasAccessToken) {
            this.oauthService.initImplicitFlow();
          }
          this.oauthService.loadUserProfile().then(
            async (data) => {
              // Generate a unique "session" identifier.
              this.sessionId = uuidv4();
              data["info"].session_id = this.sessionId;
              this.obDataShareService.updateSessionId(this.sessionId);

              if (this.captureUserLogin) {
                // Returns promise, but don't need to wait.
                this.analyticsService.capture(
                  this.apiRouteService.getRoutes().ANALYTICS.USER_LOGIN(),
                  ANALYTICS_TEMPLATE_USER_LOGIN,
                  data["info"]
                );
              }

              this.obDataShareService.updateUserId(
                data["info"].UserId ? data["info"].UserId : ""
              );

              this.profileType = data["info"].user_type;
              const roles = data["info"].roles;
              const rolesArr = roles.split(",");
              if (
                primaryRoleValidation(PRIMARY_USER_ROLES, rolesArr) &&
                (secondaryRoleValidation(SECONDARY_USER_ROLES, rolesArr) ||
                  isLocalOrDev())
              ) {
                this.loading = true;
                this.profileOwner =
                  data["info"].first_name + " " + data["info"].last_name;
                console.log(
                  "User successfully retrieved and continuing with rest of the process!"
                );
                //TODO: Add roles based analytics access (only in local or dev for now)
                this.showAnalytics = this.featureFlagService.isFlag(
                  FEATURE_FLAG_ANALYTICS
                );

                this.obDataShareService.updateSSOInitialization(data);
                resolveFn();
              } else {
                await this.doLogout();
              }
            },
            async (err) => {
              await this.doLogout();
            }
          );
          // this.oauthService.setupAutomaticSilentRefresh();
          // this.loading = true;
          // resolveFn();
        } else {
          this.oauthService.initImplicitFlow();
          rejectFn();
        }
      });
    });
  }

  login() {
    // code flow + PKCE
    this.oauthService.initLoginFlow();
  }

  async doLogout() {
    await this.logout();
  }

  async logout() {
    // Returns a promise and need to wait in the case of browser shutdown.
    await this.captureLogout();

    this.lStorage.removeItem("current_dealer");
    // If you want to revoke the existing access token and the existing refresh token before logging out, use the following method:
    this.oauthService.revokeTokenAndLogout();
    this.oauthService.logOut();
  }

  setTimeout() {
    const timeDiff = new Date().getTime() - this.lastCheckIn.getTime();
    if (Math.floor(timeDiff / 1000) > 10000) {
      let ss = {
        new: new Date(),
        last: this.lastCheckIn,
      };
      this.obDataShareService.updateuserLastTime(ss);
    }
  }
  openDialog() {
    const dialogRef = this.dialog.open(DealerSelectionModalComponent, {
      width: "768px",
      height: "70vh",
    });

    dialogRef.beforeClosed().subscribe((dealerObj) => {
      if (dealerObj && dealerObj?.data) {
        this.lStorage.setItem("current_dealer", dealerObj.data);
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {
      if (resp && resp?.data) {
        this.navigateToHome("");
      }
    });
  }
  openAnalytics() {
    const dealer = this.lStorage.getItem("current_dealer");

    const dialogRef = this.dialog.open(AnalyticsModalComponent, {
      width: "768px",
      height: "70vh",
      data: { brand: dealer?.brand?.toLowerCase() },
    });

    dialogRef.beforeClosed().subscribe((dealerObj) => {});

    dialogRef.afterClosed().subscribe((resp) => {});
  }

  get token() {
    let claims: any = this.oauthService.getIdentityClaims();
    this.appState.setState({
      claims: claims ? claims : null,
    });
    this.obDataShareService.updateToken(this.oauthService.getAccessToken());

    return claims ? claims : null;
  }

  ngOnInit(): void {
    this.feedbackUrl = PROVIDE_FEEDBACK_URL;
    this.lexusLogoUrl = LEXUS_LOGO_URL;
    if (this.lStorage.checkItem("current_dealer")) {
      const oldD = this.lStorage.getItem("current_dealer");
      if (oldD?.brand === "LEXUS") {
        this.isLexus = "L";
      }
    }
    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        let current_dealer = state?.current_dealer || null;
        if (!current_dealer && this.lStorage.checkItem("current_dealer")) {
          current_dealer = this.lStorage.getItem("current_dealer");
        }
        this.profileDealer = current_dealer;
      });
  }

  @ViewChild("loginpopup") private loginpopup: ElementRef;
  showOption: boolean = false;
  private handleClick(event) {
    if (this.showOption) {
      let clickedComponent = event.target;
      if (clickedComponent !== this.loginpopup.nativeElement) {
        this.showOption = false;
      }
    }
  }

  toggleOption() {
    this.showOption = this.showOption === true ? false : true;
  }
  navigateToHome(endPt) {
    this.router.navigate(["/" + endPt]);
  }
  onClickfunc(e) {
    alert("k");
  }
  onClickRedirect() {
    this.appState?.setState({
      seriescode: null,
      seriesCategory: null,
      year: null,
      series: null,
      grade: null,
      gradeData: null,
      modelNo: null,
      grade_attributes: null,
      option_data: null,
      exterior_color: null,
      interior_color: null,
      option_list: null,
      option_msrp: null,
      optionData: null,
    });
    this.navigateToHome("toyota");
  }
  onClickRedirectlexus() {
    this.appState?.setState({
      lexSeriesCode: null,
      lexSeriesTitle: null,
      lexYear: null,
      lexModelCode: null,
      lexConflictInfo: null,
      lexDisplayExterior: null,
      lexDisplayText: null,
      lexDriveTitle: null,
      lexExtImageData: null,
      lexGradeCode: null,
      lexGradeTitle: null,
      lexIntImageData: null,
      lexRequiredOptions: null,
      lexRequirementInfo: null,
      lexSelectedExterior: null,
      lexSelectedGradePrice: null,
      lexSelectedInterior: null,
      lexSeriesData: null,
      lexextcode: null,
      lexintcode: null,
    });
    this.navigateToHome("lexus");
  }

  async captureLogout() {
    if (this.captureUserLogin && this.sessionId) {
      try {
        await this.analyticsService.capture(
          this.apiRouteService.getRoutes().ANALYTICS.USER_LOGOUT(),
          ANALYTICS_TEMPLATE_USER_LOGOUT,
          { session_id: this.sessionId }
        );
      } catch (error) {
        // Can ignore, not enforcing the capture if service not available.
      }
      this.sessionId = "";
    }
  }

  // Debug: Delay the closing of the window so can look at variables, etc.
  debugWait(ms: number) {
    var start = Date.now(),
      now = start;
    while (now - start < ms) {
      now = Date.now();
    }
  }

  // Debug: Add a timestamp to local storage to know whether event was called.
  debugTime() {
    this.lStorage.setItem("time", Date.now());
  }
}
