import { Injectable } from "@angular/core";
import { BehaviorSubject, take } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class UiStateService {
  private state = {};
  private state$ = new BehaviorSubject<any>(this.state);
  constructor() {}

  getState() {
    return this.state$;
  }

  setState(state) {
    this.state = { ...this.state, ...state };
    this.state$.next(this.state);
  }
  clearState() {
    this.state = {};
    this.state$.next({});
  }

  async getStateData(){
    return new Promise((resolve, reject) => {
      this.state$
        .pipe(take(1))
        .subscribe((state) => {
            resolve(state);
        });
    });
  }
}
