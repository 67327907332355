import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dsmds-button-round',
  templateUrl: './button-round.component.html',
  styleUrls: ['./button-round.component.css']
})
export class ButtonRoundComponent implements OnInit {
  @Input() buttonConfig: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
