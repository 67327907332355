import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { UiStateService } from 'src/app/service/ui-state.service';
import { DEALER_USER } from "src/app/constants";

@Component({
  selector: 'dsmds-steps-navigations-lexus',
  templateUrl: './steps-navigations-lexus.component.html',
  styleUrls: ['./steps-navigations-lexus.component.css']
})
export class StepsNavigationsLexusComponent implements OnInit {
  @Input() tabnumber: number = 1;
  @Input() paramsdata: any = {};
  @Input() exteriordata: any = {};
  @Input() interiordata: any = {};
  @Input() packagedata: any = {};
  @ViewChild('tabGroup')
  tabGroup;
  links = ['MODEL', 'GRADE', 'EXTERIOR', 'INTERIOR', 'PACKAGES'];
  //routes = ["lexus", "trim", "exteriorcolor", "interiorColor", "packages", "zipcode", "findMyCar"];

  activeLink = 1;
  background: ThemePalette = undefined;
  lexstate: any;
  //prevInd=0;
  isAllSelected: boolean = false

  constructor(public router: Router,
    public appstate: UiStateService
  ) { }

  ngOnInit(): void {

    this.activeLink = this.tabnumber
    // this.prevInd=this.tabnumber-1

    this.appstate
      ?.getState()
      .pipe(take(1))
      .subscribe((state) => {
        // if(state && state.hasOwnProperty('lexSeriesTitle')){
        //console.log('..............34',state);
        // this.modelNumber = state.lexSeriesTitle;
        // this.year = state.lexYear;
        this.lexstate = state;
        if (this.tabnumber == 4) {
          this.isAllSelected = true;
        }

        //}
        // else{
        //   this.router.navigate(["/"]);
        // }    
      });
  }
  //public seriesCode='';
  //public prevInd=0;
  redirectToSteps(e) {
    // console.log("Ind- ",index,"PrevInd- ",this.prevInd)
    // if(index !=0 && this.prevInd!=(index-1) && this.prevInd!=(index+1) ){
    //   console.log("Bad Navigation")
    //   const routes = ["lexus"];
    //   //const newRoute = routes;
    //    this.router.navigate(routes);
    // }
    const index = parseInt(e?.index);
    console.log("index",index);
    this.activeLink = this.tabnumber
    console.log('63...........tab', this.tabnumber)
    if (index == 0) {
      //     if(this.paramsdata.hasOwnProperty('seriesCode'))
      // {
      const routes = ["lexus"];
      //const newRoute = routes;
      this.router.navigate(routes);
      //this.lexstate.reset();
    }
    //} 
    if (index == 1) {
      if (this.lexstate.hasOwnProperty('lexSeriesCode') && this.lexstate.lexSeriesCode !== null) {
        const routes = ["lexus", "trim", this.lexstate.lexSeriesCode];
        //const newRoute = routes;
        this.router.navigate(routes);
      }
    }
    if (index == 2) {
      if (this.lexstate.hasOwnProperty('lexGradeCode') && this.lexstate.lexGradeCode !== null) {
        const routes = ["lexus", "exterior", this.lexstate.lexGradeCode];
        //const newRoute = routes;
        this.router.navigate(routes);
      }
      else if (this.lexstate.hasOwnProperty('lexSeriesCode') && this.lexstate.lexSeriesCode !== null) {
        const routes = ["lexus", "trim", this.lexstate.lexSeriesCode];
        this.router.navigate(routes);
      }

    }
    if (index == 3) {
      if (this.lexstate.hasOwnProperty('lexSelectedExterior') && this.lexstate.lexSelectedExterior !== null) {
        const routes = ["lexus", "interior", this.lexstate.lexGradeCode];
        this.router.navigate(routes);
      }
      else if (this.lexstate.hasOwnProperty('lexGradeCode') && this.lexstate.lexGradeCode !== null) {
        const routes = ["lexus", "exterior", this.lexstate.lexGradeCode];
        //const newRoute = routes;
        this.router.navigate(routes);
      }

    }
    if (index == 4) {
      if (this.lexstate.hasOwnProperty('lexSelectedInterior') && this.lexstate.lexSelectedInterior !== null) {
        const routes = ["lexus", "packages", this.lexstate.lexGradeCode];
        this.router.navigate(routes);
      }
      else if (this.lexstate.hasOwnProperty('lexSelectedExterior') && this.lexstate.lexSelectedExterior !== null) {
        const routes = ["lexus", "interior", this.lexstate.lexGradeCode];
        this.router.navigate(routes);
      }
    }

  }


  // console.log('................Ind.',index);
  //console.log("Series",paramsdata.seriesCode);
  //const len = index + 1
  // const newRoute = this.routes.slice(0, len)
  // this.router.navigate(newRoute);
  //this.prevInd=index;
  // console.log("PrevInd",this.prevInd)

  redirectToFindCar() {
    
    if (this.lexstate.hasOwnProperty('lexRequiredOptions') && this.lexstate.lexRequiredOptions !== null) {
      if(this.lexstate.claims?.user_type == DEALER_USER){
        const dealer_number = this.lexstate.claims?.dealer_number
        //  this.lexstate.setState({
        //   lexSelectedDealerCode: dealer_number,
        //   lexSelectedDealerRadii: 0,
        // });
        //event.stopPropagation();
        this.router.navigate(["lexus", "findmycar"]);
      }else{
        const routes = ["lexus", "zipcode"];
        this.router.navigate(routes);
      }
    }
    console.log("lexRequiredOptions",this.lexstate);

  }


}













