<mat-icon class="btn-close" mat-dialog-close>close</mat-icon>

<!-- title. only display if data exists, or else test cases fail trying to access 0 -->
<h2
  *ngIf="data?.sortedOpt"
  style="font-weight: 700"
  class="font-italic text-muted mb-0"
>
  {{
    data?.options_titles?.length <= 2
      ? data?.sortedOpt[0]?.title
      : data?.sortedOpt[0]?.title + " with options"
  }}
</h2>

<!-- MSRP -->

<div class="tcom-package-card-msrp">
  <div class="card-price">
    {{ "$" + data?.price }}
  </div>
  <div class="tcom-package-msrp-title">
    MSRP
    <sup style="cursor: pointer" (click)="openDialog('msrp')">*</sup>
  </div>
</div>

<!-- bullet list of full descriptions -->

<ul
  style="padding-left: 25px"
  *ngFor="let detail of data?.options_long_descriptions"
>
  <li [innerHTML]="formatText(detail)"></li>
</ul>
