import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FindMyCarService } from "../../../service/find-my-car.service";
import * as _ from "lodash";
import { MatDialog } from "@angular/material/dialog";
import { filter, forkJoin, map, Subject, take, takeUntil } from "rxjs";
import { DisclaimerPopupComponent } from "../../shared/disclaimer-popup/disclaimer-popup.component";
import { UiStateService } from "src/app/service/ui-state.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { LEXUS_BASE_URL } from "src/app/constants/imagebase.constants";
//import { StepsNavigationsLexusComponent } from "src/app/shared/components/steps-navigations-lexus/steps-navigations-lexus.component";
import { errorStatusCode } from "src/app/utils/custom.function";
import { HostListener } from "@angular/core";

@Component({
  selector: "dsmds-lexus-grade",
  templateUrl: "./lexus-grade.component.html",
  styleUrls: ["./lexus-grade.component.css"],
})
export class LexusGradeComponent implements OnInit, OnDestroy {
  @ViewChildren("handleDragW") handleDragW: QueryList<ElementRef>;
  scrHeight: any;
  scrWidth: any;

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  code: string;
  year: string = "";
  modelNumber: string = "";
  gradeResult: any = [];
  gradeImageResult: any = [];
  unsubscribe$ = new Subject();
  paramsdata: any = {};
  lexGradeCodeSelected: any = null;
  errMessage: string;
  isLoading: boolean = true;
  constructor(
    private findCarService: FindMyCarService,
    public dialog: MatDialog,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public appstate: UiStateService
  ) //private lexusGrade:StepsNavigationsLexusComponent
  {
    /* Extract series code from route */
    this.code = this.activeRoute?.snapshot?.params["seriesCode"];
    this.paramsdata["seriesCode"] = this.code;
    //console.log('................seriescode',this.code);
    //this.lexusGrade.seriesCode=this.code;
    this.appstate
      ?.getState()
      .pipe(take(1))
      .subscribe((state) => {
        if (state && state.hasOwnProperty("lexSeriesTitle")) {
          this.modelNumber = state.lexSeriesTitle;
          this.year = state.lexYear;
        } else {
          this.router.navigate(["/"]);
        }
        if (state && state.hasOwnProperty("lexGradeCode")) {
          this.lexGradeCodeSelected = state.lexGradeCode;
        }
      });
  }

  openDialog(type) {
    this.dialog.open(DisclaimerPopupComponent, {
      data: type,
    });
  }
  ngOnInit(): void {
    forkJoin({
      modelinfo: this.findCarService.getSeriesDetials(
        "LEX",
        this.code,
        this.year
      ),
      imageinfo: this.findCarService.getLexusImage(this.code, this.year),
    })
      .pipe(
        takeUntil(this.unsubscribe$),
        map((response) => {
          const modelinfo = <Array<any>>response.modelinfo;
          const imageinfo = <Array<any>>response.imageinfo;
          this.getModelInfo(modelinfo);
          this.getImage(imageinfo);
          const finalResultMerged = _.merge(
            {},
            _.keyBy(this.gradeResult, "code"),
            _.keyBy(this.gradeImageResult, "code")
          );
          this.gradeResult = _.toArray(finalResultMerged);
          this.gradeResult = this.gradeResult.filter((x) => x.status == "LIVE");
          this.gradeResult = _.sortBy(this.gradeResult, ["title"]);
          return this.gradeResult;
        })
      )
      .subscribe({
        error: (err) => {
          this.isLoading = false;
          this.errMessage = errorStatusCode(err);
        },
        next: (data) => {},
      });
  }

  heightChecked: boolean = false;
  ngAfterViewChecked() {
    let maxH = 520;
    this.getScreenSize();
    if (!this.heightChecked) {
      if (this.scrWidth > 2299) maxH = 720;
      else if (this.scrWidth > 1899) maxH = 620;
      else if (this.scrWidth > 1599) maxH = 560;
      else maxH = 530;
      // this.handleDragW.forEach((div: ElementRef) => {
      //   //this.heightChecked = true
      //   const eleH = div.nativeElement.offsetHeight;
      //   console.log(eleH, maxH)
      //   if(eleH > maxH){
      //     maxH = eleH
      //   }

      // });

      this.handleDragW.forEach((div: ElementRef) => {
        div.nativeElement.style.height = maxH + "px";
      });
    }
  }

  formatText(text) {
    if (text) {
      text = text.replace("<ul>", "");
      text = text.replace("</ul>", "");
      text = text.replaceAll("li", "div");
      return text.replace(/ *\[[^\]]*]/g, "");
    }
  }

  formatPrice(price) {
    return parseInt(price)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getModelInfo(modelinfo) {
    this.appstate.setState({
      lexSeriesData: modelinfo,
    });
    const trim = modelinfo["trims"];
    for (let i = 0; i < trim?.length; i++) {
      this.gradeResult.push({
        year: modelinfo["year"],
        code: trim[i]["code"],
        title: trim[i]["title"],
        attributes: trim[i].attributes,
        drive: "",
        description: trim[i]["description"],
        status: trim[i]?.visibility?.status?.status_code,
        price: trim[i]?.price?.msrp
          ? parseInt(trim[i]?.price?.msrp) +
            parseInt(trim[i]?.price?.destination_fee)
          : parseInt(trim[i]?.price?.base_msrp) +
            parseInt(trim[i]?.price?.destination_fee),
      });
    }

    //console.log('130.....................',this.gradeResult);
    this.gradeResult.forEach((element) => {
      element.attributes.forEach((ele) => {
        if (ele.type == "drive") {
          element.drive = ele.title;
        }
      });
    });
  }

  getImage(imageinfo) {
    let model = _.values(imageinfo.DataArea.model).flat();

    model.forEach((element) => {
      this.gradeImageResult.push({
        code: element?.code,
        image:
          LEXUS_BASE_URL +
          this.getTrimImage(_.values(element?.exteriorcolor).flat()),
        exterior: element?.exteriorcolor,
        interior: element?.interiorcolor,
      });
    });
  }
  getTrimImage(ext_image) {
    let img = _?.values(ext_image[0]).flat();

    let x = _.values(img[1]).flat();
    if (x.length > 0) {
      return x[0][1][0];
    }
    return null;
  }

  navigateToExteriorColor(event, data) {
    this.appstate.setState({
      lexExtImageData: data.exterior,
      lexIntImageData: data.interior,
      lexGradeTitle: data.title,
      lexSelectedGradePrice: data.price,
      lexGradeCode: data.code,
      lexDriveTitle: data.drive.toUpperCase(),
      lexDisplayText: data.title + " " + data.drive.toUpperCase(),
      lexConflictInfo: null,
      lexRequiredOptions: null,
      lexRequirementInfo: null,
      lexSelectedExterior: null,
      lexSelectedInterior: null,
      lexextcode: null,
      lexintcode: null,
    });
    event.stopPropagation();
    this.router.navigate(["lexus", "exterior", data.code]);
  }

  ngOnDestroy() {
    this.unsubscribe$.complete();
  }
}
