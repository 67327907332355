import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogModule } from "@angular/material/dialog";
import * as _ from "lodash";
import { DisclaimerPopupComponent } from "../../shared/disclaimer-popup/disclaimer-popup.component";
import { PackageDetailsPopupComponent } from "../../shared/package-details-popup/package-details-popup.component";
import { FindMyCarService } from "../../../service/find-my-car.service";
import { Subject, take, takeUntil } from "rxjs";
import { UiStateService } from "../../../service/ui-state.service";
import { Router } from "@angular/router";
import { DEALER_USER, ROUTES_TOYOTA } from "src/app/constants";
import { ObservableDataShareService } from "src/app/service/data-share/observable-data-share.service";
import { getToyotaDisplayHeader } from "src/app/utils";
import { errorStatusCode } from "src/app/utils/custom.function";
import { state } from "@angular/animations";
@Component({
  selector: "app-packages",
  templateUrl: "./packages.component.html",
  styleUrls: ["./packages.component.css"],
})
export class PackagesComponent implements OnInit, OnDestroy {
  option_list: any[];
  displayText: any;
  loading = false;
  unsubscribe$ = new Subject();
  selectedPackage: any;
  errMessage: string;
  isLoading: boolean = true;
  packageDetailsInfo: any[];
  constructor(
    private findCarService: FindMyCarService,
    private appState: UiStateService,
    public dialog: MatDialog,
    public router: Router,
    private obDataShareService: ObservableDataShareService
  ) {
    if (history.state?.idata === undefined) {
      try {
        this.appState
          .getState()
          .pipe(take(1))
          .subscribe((state) => {
            console.log("STATE", state);
            this.packageDetailsInfo = state?.toyotaseriesdata?.options;
            if (
              !state.hasOwnProperty("exterior_color") ||
              state?.exterior_color == null
            ) {
              this.router?.navigate(ROUTES_TOYOTA.slice(0, 4));
            } else if (
              !state.hasOwnProperty("interior_color") ||
              state?.interior_color == null
            ) {
              this.router?.navigate(ROUTES_TOYOTA.slice(0, 5));
            }
          });
      } catch (error) {
        console.log(error);
        this.router?.navigate(["/"]);
      }
    } else {
      /* Populate text displayed on top to show selection at each stage */
      this.appState.setState({
        interior_color: history.state?.idata,
      });
      this.appState
        .getState()
        .pipe(take(1))
        .subscribe((state) => {
          //fetching detailed package data for the popups
          this.packageDetailsInfo = state?.toyotaseriesdata?.options;
        });
    }
  }

  formatColorName(colorName) {
    return colorName
      ?.replace(/SofTex®|Softex®|SofTex®&nbsp;/g, "SofTex")
      .split("[")[0];
  }

  sortOptions(item) {
    const optionArr = [];
    let index = 0;
    item?.options_titles?.forEach((element) => {
      let optionData = {
        title: item?.options_titles[index],
        price: item?.options_price[index],
        optionList: item?.options_list[index],
      };
      optionArr.push(optionData);
      index++;
    });

    return _.orderBy(optionArr, ["price"], ["desc"]);
  }

  ngOnInit(): void {
    this.getInit();
  }
  getInit() {
    try {
      this.appState
        .getState()
        .pipe(take(1))
        .subscribe((state) => {
          if (state?.optionData) {
            this.selectedPackage = state?.optionData;
          }
        });
    } catch (error) {
      console.log(error);
    }

    try {
      this.appState
        .getState()
        .pipe(take(1))
        .subscribe((state) => {
          this.displayText = getToyotaDisplayHeader(state);

          if (state?.option_data) {
            this.loading = true;
            let data = state.option_data;
            data.options_titles = data?.options_titles?.filter(
              (x) => x != null
            );
            this.option_list = _.flatMap(data, (item) => [
              this.createOptionList(item),
            ]);
            this.option_list.forEach((element) => {
              element["sortedOpt"] = this.sortOptions(element);
            });
          } else {
            this.findCarService
              .getOptionPackage(state.modelNo, state.year)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe({
                error: (err) => {
                  this.isLoading = false;
                  this.errMessage = errorStatusCode(err);
                },
                next: (data) => {
                  this.loading = true;
                  data.options_titles = data?.options_titles?.filter(
                    (x) => x != null
                  );
                  this.option_list = _.flatMap(data, (item) => [
                    this.createOptionList(item),
                  ]);
                  this.option_list.forEach((element) => {
                    element["sortedOpt"] = this.sortOptions(element);
                  });
                },
              });
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  createOptionList = (item) => ({
    model_year: item.model_year,
    model_number: item.model_number,
    options_list: item.options_list,
    options_descriptions: item.options_descriptions,
    options_long_descriptions: this.getLongOptions(item.options_list),
    options_titles: item.options_titles,
    options_price: item.options_msrps,
    sortedOpt: [],
    price: parseInt(item.options_msrps_total),
  });

  getLongOptions(options_codes_list) {
    var unique_list = [];
    //for each code, find the detailed info
    options_codes_list?.forEach((element) => {
      //compare against detailed info database and push to unique_list if it matches
      this.packageDetailsInfo?.forEach((detail) => {
        if (detail.code == element) {
          unique_list.push(detail.description);
        }
      });
    }),
      //final list compared to input:
      console.log("options_codes_list", options_codes_list);
    console.log("unique_list", unique_list);
    return unique_list;
  }

  formatText(text) {
    if (text) {
      return text.replace(/ *\[[^\]]*]/g, "");
    }
  }
  /* Navigate to zipcode page */
  navigateToZipcode(event, optionData) {
    this.selectedPackage = optionData;
    /* Store selected option code and navigate to zipcode*/
    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        if (state.claims?.user_type == DEALER_USER) {
          const dealer_number = state.claims?.dealer_number;
          this.appState.setState({
            option_list: optionData.options_list,
            option_list_details: optionData.options_titles,
            option_msrp: optionData.price,
            optionData: optionData,
            tySelectedDealerCode: dealer_number,
            tySelectedDealerRadii: 0,
          });
          this.obDataShareService.updatePkgSelection("Yes");
          event.stopPropagation();
          //this.router.navigate(["toyota", "trim", "powertrain", "exteriorcolor", "interiorColor", "packages", "zipcode", "findMyCar"]);
        } else {
          this.appState.setState({
            option_list: optionData.options_list,
            option_list_details: optionData.options_titles,
            option_msrp: optionData.price,
            optionData: optionData,
            toyotaOptionData: optionData,
            displayText: this.displayText,
          });
          this.obDataShareService.updatePkgSelection("Yes");
          event.stopPropagation();
          //this.router.navigate(["toyota", "trim", "powertrain", "exteriorcolor", "interiorColor", "packages", "zipcode"]);
        }
      });
  }

  openDialog(type) {
    this.dialog.open(DisclaimerPopupComponent, {
      data: type,
    });
  }

  openPackagesDialog(type) {
    this.dialog.open(PackageDetailsPopupComponent, {
      data: type,
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.complete();
  }
}
