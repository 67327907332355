// import { Directive } from '@angular/core';

// @Directive({
//   selector: 'img[dsmdsImgFallback]'
// })
// export class ImgFallbackDirective {

//   constructor() { }

// }

import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[dsmdsImgFallback]'
})
export class ImgFallbackDirective {

  @Input() dsmdsImgFallback: string;

  constructor(private eRef: ElementRef) { }

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    element.src = this.dsmdsImgFallback;
  }

}
