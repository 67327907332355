import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "../environments/environment";
//check sample integration with implicit authentication
export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: "https://steyer-identity-server.azurewebsites.net/identity",

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + "/index.html",

  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: "spa-demo",

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: " [profile, openid]",
};

//check sample integration with code + PKCE
// export const authCodeFlowConfig: AuthConfig = {
//   // Url of the Identity Provider
//   issuer: 'https://idsvr4.azurewebsites.net',

//   // URL of the SPA to redirect the user to after login
//   redirectUri: window.location.origin + '/index.html',

//   // The SPA's id. The SPA is registerd with this id at the auth-server
//   // clientId: 'server.code',
//   clientId: 'spa',

//   // Just needed if your auth server demands a secret. In general, this
//   // is a sign that the auth server is not configured with SPAs in mind
//   // and it might not enforce further best practices vital for security
//   // such applications.
//   // dummyClientSecret: 'secret',

//   responseType: 'code',

//   // set the scope for the permissions the client should request
//   // The first four are defined by OIDC.
//   // Important: Request offline_access to get a refresh token
//   // The api scope is a usecase specific one
//   scope: 'openid profile email offline_access api',

//   showDebugInformation: true,
// };

// for forge rock
// export const authConfig: AuthConfig = {
//   // Url of the Identity Provider
//   issuer: 'https://ep.fram.qa.idm.toyota.com/oauth2/realms/root/realms/dealerdaily',

//   // URL of the SPA to redirect the user to after login
//   redirectUri: window.location.origin + '/index.html',

//   // The SPA's id. The SPA is registered with this id at the auth-server
//   clientId: 'DSMOTD',

//   // set the scope for the permissions the client should request
//   // The first three are defined by OIDC. The 4th is a usecase-specific one
//   scope: 'openid profile email voucher',
// }

// Forge Rock check sample integration with code + PKCE
export const authCodeFlowConfigDev: AuthConfig = {
  // Url of the Identity Provider
  issuer:
    "https://ep.fram.qa.idm.toyota.com/oauth2/realms/root/realms/dealerdaily",

  // URL of the SPA to redirect the user to after login
  // redirectUri: window.location.origin + '',
  redirectUri: "https://one.dsmotddev.toyota.com/",
  postLogoutRedirectUri: "https://one.dsmotddev.toyota.com/",

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: "DSMOTD",

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  responseType: "code",

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: "openid profile",

  showDebugInformation: true,
};
export const authCodeFlowConfigProd: AuthConfig = {
  // Url of the Identity Provider
  issuer:
    "https://ep.fram.idm.toyota.com/oauth2/realms/root/realms/dealerdaily",
    //https://ep.fram.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily

  // URL of the SPA to redirect the user to after login
  // redirectUri: window.location.origin + '',
  redirectUri: "https://one.dsmotd.toyota.com/",
  postLogoutRedirectUri: "https://one.dsmotd.toyota.com/",

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: "DSMOTD",

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  responseType: "code",

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: "openid profile",

  showDebugInformation: true,
};
export const authCodeFlowConfigUat: AuthConfig = {
  // Url of the Identity Provider
  issuer:
    "https://ep.fram.qa.idm.toyota.com/oauth2/realms/root/realms/dealerdaily",

  // URL of the SPA to redirect the user to after login
  // redirectUri: window.location.origin + '',
  redirectUri: "https://one.dsmotduat.toyota.com/",
  postLogoutRedirectUri: "https://one.dsmotduat.toyota.com/",

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: "DSMOTD",

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  responseType: "code",

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: "openid profile",

  showDebugInformation: true,
};
export const authCodeFlowConfigQa: AuthConfig = {
  // Url of the Identity Provider
  issuer:
    "https://ep.fram.qa.idm.toyota.com/oauth2/realms/root/realms/dealerdaily",

  // URL of the SPA to redirect the user to after login
  // redirectUri: window.location.origin + '',
  redirectUri: "https://one.dsmotdqa.toyota.com/",
  postLogoutRedirectUri: "https://one.dsmotdqa.toyota.com/",

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: "DSMOTD",

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  responseType: "code",

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: "openid profile",

  showDebugInformation: true,
};
export const authCodeFlowConfiglocal: AuthConfig = {
  // Url of the Identity Provider
  issuer:
    "https://ep.fram.qa.idm.toyota.com/oauth2/realms/root/realms/dealerdaily",

  // URL of the SPA to redirect the user to after login
  // redirectUri: window.location.origin + '',
  redirectUri: "http://localhost:4200/",
  postLogoutRedirectUri: "http://localhost:4200/",

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: "DSMOTD",

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  responseType: "code",

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: "openid profile",

  showDebugInformation: true,
};
