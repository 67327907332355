import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DisclaimerPopupComponent } from "../disclaimer-popup/disclaimer-popup.component";
@Component({
  selector: "dsmds-package-details-popup",
  templateUrl: "./package-details-popup.component.html",
  styleUrls: [
    "./package-details-popup.component.css",
    "../../toyota/packages/packages.component.css",
  ],
})
export class PackageDetailsPopupComponent implements OnInit {
  package_data: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public innerdialog: MatDialog
  ) {
    this.package_data = data;
  }

  ngOnInit(): void {
    console.log("PACKAGE DATA", this.package_data);
  }
  formatText(text) {
    if (text) {
      text = text.replace("<ul>", "");
      text = text.replace("</ul>", "");
      text = text.replace("<ol>", "");
      text = text.replace("</ol>", "");
      text = text.replace("<em>", "");
      text = text.replace("</em>", "");
      text = text.replaceAll("<li>", "<div>");
      text = text.replaceAll("</li>", "</div>");
      return text.replace(/ *\[[^\]]*]/g, "");
    }
  }
  openDialog(type) {
    this.innerdialog.open(DisclaimerPopupComponent, {
      data: type,
    });
  }
}
