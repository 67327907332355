import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { take } from "rxjs";
import { UiStateService } from "src/app/service/ui-state.service";
import { HostListener } from "@angular/core";

@Component({
  selector: "dsmds-option-requirement",
  templateUrl: "./option-requirement.component.html",
  styleUrls: ["./option-requirement.component.css"],
})
export class OptionRequirementComponent implements OnInit {
  option_data: any;
  selected_interior: any = null;
  code: any;
  type: any = "";
  options: any = [];
  disclaimers: any = [];
  lexintSelected: any = null;
  selectedIdx: number = null;
  selectedTooltip: number = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<OptionRequirementComponent>,
    public appState: UiStateService,
    public router: Router
  ) {
    this.option_data = data.option_data;
    console.log(this.option_data);
    this.selected_interior = data.selected_interior;
    this.code = data.code;
    this.type = data.type;

    //retrieve disclaimer information
    this.appState
      ?.getState()
      .pipe(take(1))
      .subscribe((state) => {
        if (state && state?.lexSeriesData?.disclaimers) {
          console.log("disclaimers found");
          this.disclaimers = state.lexSeriesData.disclaimers;
        } else {
          console.log("no disclaimers");
        }
      });
  }

  formatPrice(price) {
    return parseInt(price)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  findDisclaimer(code) {
    for (var entry of this.disclaimers) {
      if (entry.code == code) {
        //console.log("code found,", code, entry.description);
        return entry.description;
      }
    }
    console.log("no code found for ", code);
    return null;
  }

  formatText(text) {
    if (text) {
      text = text.replace("<ol>", "<ul>");
      text = text.replace("</ol>", "</ul>");

      let disclaimcode = "";
      let findAndReplace = [];

      //parse disclaimers:
      for (let h = 0; h < text.length; h++) {
        //for each open bracket, find the text inside
        if (text[h] == "[") {
          for (let j = h + 1; j < text.length; j++) {
            if (text[j] != "]") {
              disclaimcode = disclaimcode + text[j];
            } else {
              //end of disclaim code:
              //find relevant disclaimer
              let disclaimer = this.findDisclaimer(disclaimcode);
              disclaimcode = "[" + disclaimcode + "]";
              //make a tooltip out of the disclaimertext;
              disclaimer =
                '<a class=tooltip-disclaimer matTooltip="' +
                disclaimer +
                '">*</a>';
              //put data into findAndReplace as a pair
              findAndReplace.push([disclaimcode, disclaimer]);
              //reset variables and go back to outer loop to look for new code
              h = j;
              disclaimcode = "";
              break;
            }
          }
        }
      }

      console.log("findandreplace", findAndReplace);

      //for each disclaimer code/tooltip pair, find and replace
      for (let pair in findAndReplace) {
        //console.log("pair", findAndReplace[pair]);
        text = text.replace(findAndReplace[pair][0], findAndReplace[pair][1]);
      }

      text = text.split("<li>");

      //remove capitalization
      for (let i = 0; i < text.length; i++) {
        text[i] =
          text[i].charAt(0).toUpperCase() + text[i].slice(1).toLowerCase();
      }

      //add line breaks
      text = text.join("<li>");
      //console.log(text);
      return text;
    }
  }

  ngOnInit(): void {}

  getOption(data, idx) {
    this.options = data;
    this.selectedIdx = idx;
    console.log("......data", data);
    console.log("........idx", idx);
  }
  navigateToPackage(event, next) {
    const code = [];
    if (this.options) {
      code.push(
        ...this.options.map(function (obj) {
          return obj.code;
        })
      );
    }
    this.appState.setState({
      lexSelectedInterior: this.selected_interior,
      lexintcode: this.selected_interior.code,
      lexRequiredOptions: code,
    });
    // event.stopPropagation();
    //this.dialogRef.close(0);
    this.dialogRef.close({ data: this.code, next: next });
    //this.router.navigate(["lexus", "packages", this.code]);
  }

  //wait for element: this is used to help position the tooltip popup.
  waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  @HostListener("window:resize", ["-3", "$event"])
  async updateTooltip(num, event: any) {
    //update selected tooltip index
    this.selectedTooltip = num;

    // set tooltip position based on click
    this.waitForElm(".package-tooltip").then((tooltip: HTMLElement) => {
      //add a little buffer to the top edge
      let topEdge = event.clientY + 5;
      //center the tooltip horizontally...
      let leftEdge = event.clientX - tooltip.offsetWidth / 2;
      //but don't let it go offscreen!
      if (leftEdge < 5) {
        leftEdge = 5;
      }

      tooltip.style.top = topEdge + "px";
      tooltip.style.left = leftEdge + "px";
    });
  }

  selectPackage(event) {
    const code = [];
    if (this.options) {
      code.push(
        ...this.options.map(function (obj) {
          return obj.code;
        })
      );
    }
    event.stopPropagation();
    this.dialogRef.close({
      data: code,
      next: "No",
    });
  }
  getTotal(data) {
    let sum = 0;
    data.forEach((element) => {
      sum = sum + parseInt(element.price);
    });
    return "$" + this.formatPrice(sum);
  }
}
