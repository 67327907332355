import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { take } from "rxjs";
import { ROUTES_TOYOTA } from "src/app/constants";
import { getToyotaDisplayHeader } from "src/app/utils";
import { UiStateService } from "../../../service/ui-state.service";
import { DisclaimerPopupComponent } from "../../shared/disclaimer-popup/disclaimer-popup.component";

@Component({
  selector: "app-pwrtrain",
  templateUrl: "./pwrtrain.component.html",
  styleUrls: ["./pwrtrain.component.css"],
})
export class PwrtrainComponent implements OnInit {
  pwrTrainData: any = [];
  displayText: any;
  grade: any;
  selectedPwrtrain: string = null;

  constructor(
    public dialog: MatDialog,
    private appstate: UiStateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    try {
      this.appstate
        .getState()
        .pipe(take(1))
        .subscribe((state) => {
          if (state?.modelNo) {
            this.selectedPwrtrain = state?.modelNo;
          }
          if (state?.gradeData && state?.gradeData !== null) {
            this.grade = state?.gradeData;
          }

          if (history.state?.data !== undefined) {
            const historyGrade = history.state?.data?.grade;
            if (this.grade && this.grade[0].code === historyGrade[0].code) {
              this.appstate?.setState({
                grade: history.state?.data?.title,
                gradeData: history.state?.data?.grade,
              });
            } else {
              this.grade = historyGrade;
              this.appstate?.setState({
                grade: history.state?.data?.title,
                gradeData: history.state?.data?.grade,
                modelNo: null,
                grade_attributes: null,
                option_data: null,
                exterior_color: null,
                interior_color: null,
                option_list: null,
                option_msrp: null,
                optionData: null,
              });
              this.selectedPwrtrain = null;
            }
            this.displayText =
              history.state?.displayText + " " + history.state?.data?.title;
          } else {
            this.displayText = getToyotaDisplayHeader(state);
          }

          if (this.grade) {
            this.getPwrTrainData();
          } else {
            this.router?.navigate(ROUTES_TOYOTA.slice(0, 2));
          }

          //only sort the powertrains IF they are trucks
          if (this.pwrTrainData[0]?.bed_title) {
            console.log("it's trucks!");
            this.pwrTrainData.sort((a, b) => {
              const bedlengthA = a?.bed_title;
              const bedlengthB = b?.bed_title;
              const driveA = a?.drive_code;
              const driveB = b?.drive_code;
              if (bedlengthA < bedlengthB) {
                return -1;
              } else if (bedlengthA > bedlengthB) {
                return 1;
              } else {
                // bed lengths are be equal: compare AWD vs FWD!
                if (driveA < driveB) {
                  return -1;
                } else if (driveA > driveB) {
                  return 1;
                }

                //they're identical!
                return 0;
              }
            });
          }

          console.log("pwrtrainData", this.pwrTrainData);
        });
    } catch (error) {
      this.router?.navigate(ROUTES_TOYOTA.slice(0, 2));
      console.log(error);
    }
  }
  getPwrTrainData() {
    for (let i = 0; i < this.grade?.length; i++) {
      this.pwrTrainData.push({
        price: this.grade[i]?.price,
        title: this.grade[i]?.title,
        attributes: this.grade[i]?.attributes,
        code: this.grade[i]?.code,
      });
    }

    this.getAttributes();
  }

  /* Based on attributes get engine,drive and transmission info */
  getAttributes() {
    this.pwrTrainData?.forEach((element) => {
      element.attributes.forEach((att) => {
        if (att.type === "engine") {
          element["engine_title"] = att?.title;
          element["engine_code"] = att?.code;
          element["engine_description"] = att?.description;
        } else if (att.type === "drive") {
          element["drive_title"] = att?.title;
          element["drive_code"] = att?.code;
          element["drive_description"] = att?.description;
        } else if (att.type === "transmission") {
          element["transmission_title"] = att?.title;
          element["transmission_code"] = att?.code;
          element["transmission_description"] = att?.description;
        } else if (att.type === "cab") {
          element["cab_title"] = att?.title;
          element["cab_code"] = att?.code;
          element["cab_description"] = att?.description;
        } else if (att.type === "bed") {
          element["bed_title"] = att?.title;
          element["bed_code"] = att?.code;
          element["bed_description"] = att?.description;
        } else if (att.type === "seating") {
          element["seating_title"] = att?.title;
          element["seating_code"] = att?.code;
          element["seating_description"] = att?.description;
        }
      });
    });
  }

  /* Disclaimer Popup */
  openDialog(type) {
    this.dialog.open(DisclaimerPopupComponent, {
      data: type,
    });
  }

  formatText(text) {
    if (text) {
      text = text.replace(/ *\[[^\]]*]/g, "");
    }
    return text;
  }

  formatPrice(price) {
    if (price) {
      price = parseInt(price)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return price;
  }

  formatDescriptionText(text) {
    if (text) {
      text = text.replaceAll("• ", "");
      text = text.replace(/ *\[[^\]]*]/g, "");
    }
    return text;
  }
}
