import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ObservableDataShareService {
  constructor() {}

  private currentDealerObservable = new BehaviorSubject<any>(null);
  private tokenObservable = new BehaviorSubject<any>(null);
  private pkgObservable = new BehaviorSubject<any>(null);
  private userInactiveObservable = new BehaviorSubject<any>(null);
  private ssoInitializationObservable = new BehaviorSubject<any>(null);
  private userIdObservable = new BehaviorSubject<any>(null);
  private sessionIdObservable = new BehaviorSubject<any>(null);

  currentDealerData = this.currentDealerObservable.asObservable();
  tokenData = this.tokenObservable.asObservable();
  pkgData = this.pkgObservable.asObservable();
  userLastTime = this.userInactiveObservable.asObservable();
  ssoInitialization = this.ssoInitializationObservable.asObservable();
  userId = this.userIdObservable.asObservable();
  sessionId = this.sessionIdObservable.asObservable();

  updateCurrentDealer(data: any) {
    this.currentDealerObservable.next(data);
  }
  updateToken(data: string) {
    this.tokenObservable.next(data);
  }
  updatePkgSelection(data: string) {
    this.pkgObservable.next(data);
  }
  updateuserLastTime(data: any) {
    this.userInactiveObservable.next(data);
  }
  updateSSOInitialization(data: any) {
    this.ssoInitializationObservable.next(data);
  }
  updateUserId(data: any) {
    this.userIdObservable.next(data);
  }
  updateSessionId(data: any) {
    this.sessionIdObservable.next(data);
  }
}
