import { Injectable } from '@angular/core';
import { ObservableDataShareService } from '../data-share/observable-data-share.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  accessToken = null
  constructor(
    private userAccountStore: ObservableDataShareService
    ) {
    this.userAccountStore.tokenData.subscribe(data => {
      this.saveTokens(data);
    })
  }

  getToken(): String {
    return this.accessToken;
  }

  saveTokens(data) {
    if(data){
      this.accessToken = data
    }
  }

  destroyToken() {
    this.accessToken = null;
  }
}
