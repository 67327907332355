<h4>Packages</h4>
<div *ngFor="let opt of option_data">
  <input
    type="checkbox"
    id="opt"
    name="opt"
    value="opt"
    [checked]="opt?.checked"
    (change)="onChange(opt, $event)"
  />
  <label for="opt">{{ opt.code }}</label
  ><br />
</div>
<br />
<br />
<div style="text-align: center">
  <button mat-button class="close" (click)="close()">Filter</button>
</div>
