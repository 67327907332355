import { isLocalOrDev } from "../utils";

const DEBUG_ON = false;

export const DEBUG = (func) => {
  return (label: string, obj?: any) => {
    if (DEBUG_ON || isLocalOrDev()) {
      if (obj) {
        console.log(`DEBUG ${func}: ${label}`, obj);
      } else {
        console.log(`DEBUG ${func}: ${label}`);
      }
    }
    return label;
  };
};
