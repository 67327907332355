export const environment = {
  production: true,
  apiBase: "https://efc.toyota.com",
  vapimsBase: "https://api.vapims.tms.aws.toyota.com",
  backendModelBaseUrl: "https://api.dsmotd.toyota.com/otdgm",
  backendBaseUrl: "https://api.dsmotd.toyota.com/otdfc",
  lexusBase: "https://next.ws.tms.aws.toyota.com",
  vSpecUrl: "https://guest.dealer.toyota.com",
  vSpecUrlApiBase: "https://api.rti.toyota.com",
  analyticsBaseUrl: "<TBD>",
  idle: 1000,
  timeOut: 120,
};
