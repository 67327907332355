<a [routerLink]="['powertrain']" [state]="{displayText,data}" class="general-router">
<div class="mt-3">
    <div class="trim-row" id="{{ data?.grade[0].title }}" [class.active-trim]="selectedGrade==data?.grade[0].code">
        
        <div
            class="bg-image hover-overlay ripple shadow-1-strong rounded"
            data-ripple-color="light"
        >
            <img
                alt="Car Image"
                src="{{ data?.grade[0]?.image }}"
                class="img-fluid order-1 order-lg-2"
            />
        </div>
        <div class="d-flex-bk align-items-center justify-content-between mt-1">
            <div class="card-header">
              {{ data.grade[0].title }}
            </div>
            <hr />

            <div class="row">
              <div class="col-7">
                  <div class="card-price">
                      {{ "$" + formatPrice(data.grade[0].price) }}
                  </div>
                  <div class="card-subheader">
                      Starting MSRP
                      <sup style="cursor: pointer" (click)="callParentPrice()"
                      >*</sup>
                  </div>
              </div>
              <div class="col-5">
                  <div class="card-price">
                    {{ data.grade[0].city_mpg }}/{{ data.grade[0].highway_mpg }}
                  </div>
                  <div class="card-subheader">
                      {{ "Est. MPG" }}
                      <sup style="cursor: pointer" (click)="callParentMpg()">*</sup>
                  </div>
              </div>
          </div>

            
        </div>
    </div>    
</div>

</a>
