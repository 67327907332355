import { environment as dev } from "../../environments/environment.dev";
import { environment as qa } from "../../environments/environment.qa";
import { environment as uat } from "../../environments/environment.uat";
import { environment as prod } from "../../environments/environment.prod";
import { environment } from "../../environments/environment";
import {
  DEV_BASE_URL,
  QA_BASE_URL,
  UAT_BASE_URL,
  PROD_BASE_URL,
} from "../constants/baseurl.constants";

//testing: currently only available in dev/localhost environment
export const getFindCarsEndpoint = () => {
  if (window.location.hostname == DEV_BASE_URL) {
    return dev.findcarUrl;
  } else if (window.location.hostname == QA_BASE_URL) {
    return qa.findcarUrl;
  } else if (window.location.hostname == UAT_BASE_URL) {
    return uat.findcarUrl;
  } else if (window.location.hostname == PROD_BASE_URL) {
    return prod.backendBaseUrl;
  }
  console.log("returning findcarUrl", environment.findcarUrl);
  return environment.findcarUrl;
};

export const getBaseApiEndpoint = () => {
  if (window.location.hostname == DEV_BASE_URL) {
    return dev.backendBaseUrl;
  } else if (window.location.hostname == QA_BASE_URL) {
    return qa.backendBaseUrl;
  } else if (window.location.hostname == UAT_BASE_URL) {
    return uat.backendBaseUrl;
  } else if (window.location.hostname == PROD_BASE_URL) {
    return prod.backendBaseUrl;
  }
  return environment.backendBaseUrl;
};

export const getModelBaseApiEndpoint = () => {
  if (window.location.hostname == DEV_BASE_URL) {
    return dev.backendModelBaseUrl;
  } else if (window.location.hostname == QA_BASE_URL) {
    return qa.backendModelBaseUrl;
  } else if (window.location.hostname == UAT_BASE_URL) {
    return uat.backendModelBaseUrl;
  } else if (window.location.hostname == PROD_BASE_URL) {
    return prod.backendModelBaseUrl;
  }
  return environment.backendModelBaseUrl;
};

export const getAnalyticsApiEndpoint = () => {
  if (window.location.hostname == DEV_BASE_URL) {
    return dev.analyticsBaseUrl;
  } else if (window.location.hostname == QA_BASE_URL) {
    return qa.analyticsBaseUrl;
  } else if (window.location.hostname == UAT_BASE_URL) {
    return uat.analyticsBaseUrl;
  } else if (window.location.hostname == PROD_BASE_URL) {
    return prod.analyticsBaseUrl;
  }
  //NOTE: Analytics on local is set up to use a local environment. To Change this, replace this value with the 'environment.dev.ts' URL.
  return "http://localhost:3000";
};

// export const getApiKeys = () => {
//   if (window.location.hostname == DEV_BASE_URL) {
//     return dev.apiKey;
//   } else if (window.location.hostname == QA_BASE_URL) {
//     return qa.apiKey;
//   } else if (window.location.hostname == UAT_BASE_URL) {
//     return uat.apiKey;
//   } else if (window.location.hostname == PROD_BASE_URL) {
//     return prod.apiKey;
//   }
//   return environment.apiKey;
// };
// export const getAnalyticsApiKeys = () => {
//   if (window.location.hostname == DEV_BASE_URL) {
//     return dev.apiAnalyticsKey;
//   } else if (window.location.hostname == QA_BASE_URL) {
//     return qa.apiAnalyticsKey;
//   } else if (window.location.hostname == UAT_BASE_URL) {
//     return uat.apiAnalyticsKey;
//   } else if (window.location.hostname == PROD_BASE_URL) {
//     return prod.apiAnalyticsKey;
//   }
//   return environment.apiAnalyticsKey;
// };

export const isLocalOrDev = () => {
  if (
    window.location.hostname == DEV_BASE_URL ||
    window.location.hostname.includes("localhost")
  ) {
    return true;
  }
  return false;
};
export const isLocal = () => {
  return window.location.hostname.includes("localhost");
};

export const isProd = () => {
  return window.location.hostname.includes(PROD_BASE_URL);
};
export const getToyotaBaseEndPoint = () => environment.apiBase;
export const getVampisBaseEndpoint = () => environment.vapimsBase;
export const getLexusBaseEndPoint = () => environment.lexusBase;

export const getVspecUrls = () => {
  if (window.location.hostname == DEV_BASE_URL) {
    return {
      apiBase: dev.vSpecUrlApiBase,
      webBase: dev.vSpecUrl,
    };
  } else if (window.location.hostname == QA_BASE_URL) {
    return {
      apiBase: qa.vSpecUrlApiBase,
      webBase: qa.vSpecUrl,
    };
  } else if (window.location.hostname == UAT_BASE_URL) {
    return {
      apiBase: uat.vSpecUrlApiBase,
      webBase: uat.vSpecUrl,
    };
  } else if (window.location.hostname == PROD_BASE_URL) {
    return {
      apiBase: prod.vSpecUrlApiBase,
      webBase: prod.vSpecUrl,
    };
  }
  return {
    apiBase: environment.vSpecUrlApiBase,
    webBase: environment.vSpecUrl,
  };
};
