  <div class="justify-content-center" [hidden]="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only" id="loading"></span>
    </div>
  </div>
  <div class="container" [hidden]="!loading">
    <div>
      <span style="margin-top: 5%; margin-left: 20%; text-align: center">
        <h1 style="font-weight: 500; margin-top: 21px">
          YOUR VEHICLE:
          <span style="font-weight: 500" [innerHTML]="lexDisplayText"></span>
        </h1>
        <h1 class="display-4">Select a location</h1>
      </span>
    </div>
    <div class="col-lg-8 col-xl-6 col-12 offset-lg-2 offset-xl-3">
      <div class="zip-selection-container">
      <div style="font-size: 20px !important; line-height: 30px;">
        <i class="fa-solid fa-location-dot" aria-hidden="true"></i>
        <p>
          Select your Dealer to find inventory nearby
        </p>
      </div>
      <div style="margin: 30px 0">
        <!-- <select [(ngModel)]="selectedDealer" class="form-input" #dealerCode>
          <option [value]="dealer.dealer_number" *ngFor="let dealer of dealer_list">{{dealer.dealer_number}} - {{dealer?.name | lowercase | titlecase}}</option>
        </select> -->
        <div class="row">
          <div class="col-12 col-sm-5 col-md-6 mb-2 ">
        <input type="text" #dealerCode placeholder="Search Here" [(ngModel)]="selectedDealer"  matInput [formControl]="myControl"
        [matAutocomplete]="auto">

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option.dealer_number}}  {{option?.name | lowercase | titlecase }}
          </mat-option>
        </mat-autocomplete>
        </div>
        <div class="col-6 col-sm-4 col-md-2 mb-2">
        <button
          id="btn-submit"
          type="button"
          (click)="validateDealerCode(dealerCode.value)"
         
        >
          SUBMIT
        </button>

      </div>
      <div class="col-6 col-sm-3 col-md-2  mb-2">
      <button 
      id="btn-remove"
      type="button"
      (click)="onClick()"
      >
      CLEAR
     </button>
     </div>
     </div>
        <div
          [ngClass]="validDealer === true ? 'success' : 'error'"
          style="margin-top: 30px"
        >
          {{ message }}
        </div>
      </div>
      <div style="display: block">
        <span>Ideal distance range (mi):{{ 0 }} </span>
        <input
          #slider3
          type="range"
          min="0"
          max="0"
          step="0"
          name="foo"
          (change)="setVal($event)"
        />
        <output for="foo" onforminput="value = foo.valueAsNumber;"></output>
      </div>
      <div class="moveButtons" style="margin: 30px">
        <button
          [disabled]="!validDealer"
          (click)="navigateToFindCar($event)"
          class="next"
        >
          NEXT STEP
        </button>
      </div>
    </div>
    </div>
  </div>
