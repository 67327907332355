<div>
  <a class="img-icon-x-sm close-icon" mat-dialog-close></a>
  <div class="header-container">
    <h3 class="option-header">
      To add that feature, additional features are required.
    </h3>
    <h4
      style="
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        font-family: 'NobelBold', sans-serif !important;
        letter-spacing: 0.5px;
        color: black;
      "
    >
      PLEASE SELECT FROM THE FOLLOWING:
    </h4>
  </div>
</div>
<div class="option" *ngIf="option_data.length > 0">
  <div
    class="package"
    *ngFor="let data of option_data; let i = index"
    (click)="getOption(data, i)"
  >
    <div
      class="tcom-card-content"
      *ngIf="data"
      style="text-align: left"
      [class.option-selected]="i == selectedIdx"
    >
      <ul class="option-row">
        <div *ngFor="let opt of data; let opti = index">
          <li *ngIf="opt" style="display: flex">
            <h4 class="font-weight-bold my-2">
              {{ opt.title }} ${{ formatPrice(opt.price) }}

              <!-- TOOLTIP VERSION -->
              <!-- <mat-icon
                *ngIf="opt.description"
                class="opt-description"
                matTooltip="{{ formatText(opt.description) }}"
                (mouseenter)="$event.stopImmediatePropagation()"
                (mouseleave)="$event.stopImmediatePropagation()"
                matTooltipClass="tooltip-opt-desc"
                matTooltipPosition="below"
                #tooltip="matTooltip"
                (click)="tooltip.toggle()"
                aria-label="icon that displays package details on click"
              >
              </mat-icon> -->

              <!-- DIY VERSION -->
              <mat-icon
                *ngIf="opt.description"
                class="opt-description"
                (click)="updateTooltip(opti + i * 100, $event)"
                aria-label="icon that displays package details on click"
              >
              </mat-icon>

              <div
                *ngIf="opti + i * 100 == selectedTooltip"
                id="package-tooltip"
                class="package-tooltip"
              >
                <div class="tooltip-close">
                  <a
                    class="img-icon-x-white"
                    (click)="updateTooltip(-1, $event)"
                  ></a>
                </div>
                <div
                  class="tooltip-list"
                  innerHTML="{{ formatText(opt.description) }}"
                ></div>
              </div>
            </h4>
          </li>
        </div>
      </ul>
      <hr />
      <div style="display: flex">
        <div class="radio-button">
          <mat-radio-button
            value="data"
            (click)="getOption(data, i)"
            [checked]="i == selectedIdx"
            >SELECT</mat-radio-button
          >
        </div>
        <h3 style="margin-left: 70px; margin-top: 15px">
          {{ getTotal(data) }}
        </h3>
      </div>
    </div>
  </div>
</div>

<div class="no-option" *ngIf="option_data.length == 0">
  No option available.<br />
  Please click CONFIRM to continue
</div>

<div class="row">
  <div class="col-12">
    <div class="moveButtons mt-4">
      <!-- this button is for selecting interior color and navigating to packages -->
      <button
        [disabled]="option_data.length > 0 && options.length == 0"
        [hidden]="type == 'package'"
        (click)="navigateToPackage($event, 'Yes')"
        class="next"
      >
        CONFIRM
      </button>

      <!-- this button is for selecting recursive color  -->
      <button
        [disabled]="option_data.length > 0 && options.length == 0"
        [hidden]="type != 'package'"
        (click)="selectPackage($event)"
        class="next"
      >
        CONFIRM
      </button>
    </div>
  </div>
</div>
