import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
// import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCardModule } from "@angular/material/card";
import { OAuthModule } from "angular-oauth2-oidc";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatIconModule } from "@angular/material/icon";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ModelInfoComponent } from "./modules/toyota/model-series/model-info.component";
import { ModelTrimComponent } from "./modules/toyota/model-grade/model-trim.component";
import { ModelColorComponent } from "./modules/toyota/model-exterior-color/model-color.component";
import { FindMyCarComponent } from "./modules/toyota/find-my-car/find-my-car.component";
import { ZipCodeComponent } from "./modules/toyota/zip-code/zip-code.component";
import { InteriorColorComponent } from "./modules/toyota/interior-color/interior-color.component";
import { PackagesComponent } from "./modules/toyota/packages/packages.component";
import { PwrtrainComponent } from "./modules/toyota/pwrtrain/pwrtrain.component";
import { DealerPopupComponent } from "./modules/shared/dealer-popup/dealer-popup.component";
import { DisclaimerPopupComponent } from "./modules/shared/disclaimer-popup/disclaimer-popup.component";
import { PackageDetailsPopupComponent } from "./modules/shared/package-details-popup/package-details-popup.component";
import { LexusModelComponent } from "./modules/lexus/lexus-model/lexus-model.component";
import { LexusGradeComponent } from "./modules/lexus/lexus-grade/lexus-grade.component";
import { LexusExteriorComponent } from "./modules/lexus/lexus-exterior/lexus-exterior.component";
import { LexusInteriorComponent } from "./modules/lexus/lexus-interior/lexus-interior.component";
import { LexusOptionComponent } from "./modules/lexus/lexus-option/lexus-option.component";
import { LexusZipcodeComponent } from "./modules/lexus/lexus-zipcode/lexus-zipcode.component";
import { LexusFindcarComponent } from "./modules/lexus/lexus-findcar/lexus-findcar.component";
import { OptionPopupComponent } from "./modules/shared/option-popup/option-popup.component";
import { OptionRequirementComponent } from "./modules/shared/option-requirement/option-requirement.component";
import { OptionFilterComponent } from "./modules/shared/option-filter/option-filter.component";
import { LexusDisclaimerPopupComponent } from "./modules/shared/lexus-disclaimer-popup/lexus-disclaimer-popup.component";
import { NavigationPageComponent } from "./modules/shared/navigation-page/navigation-page.component";
import { SharedModule } from "./shared/shared.module";
import { DealerSelectionModalComponent } from "./modules/shared/dealer-selection-modal/dealer-selection-modal.component";
import { TokenInterceptorService } from "./service/interceptors/token-interceptor.service";
import { ImgFallbackDirective } from "./directives/img-fallback.directive";
import { AnalyticsModalComponent } from "./modules/shared/analytics-modal/analytics-modal.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
@NgModule({
  declarations: [
    AppComponent,
    ModelInfoComponent,
    ModelTrimComponent,
    ModelColorComponent,
    FindMyCarComponent,
    ZipCodeComponent,
    InteriorColorComponent,
    PackagesComponent,
    PwrtrainComponent,
    DealerPopupComponent,
    DisclaimerPopupComponent,
    PackageDetailsPopupComponent,
    LexusModelComponent,
    LexusGradeComponent,
    LexusExteriorComponent,
    LexusInteriorComponent,
    LexusOptionComponent,
    LexusZipcodeComponent,
    LexusFindcarComponent,
    OptionPopupComponent,
    OptionRequirementComponent,
    OptionFilterComponent,
    LexusDisclaimerPopupComponent,
    NavigationPageComponent,
    DealerSelectionModalComponent,
    ImgFallbackDirective,
    AnalyticsModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatTooltipModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatIconModule,
    OAuthModule.forRoot(),
    SharedModule,
    NgxChartsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  entryComponents: [DealerPopupComponent, DisclaimerPopupComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
