<div class="container pb-5">
  <div class="mb-5 mt-4 text-center">
    <div>
      <h1 style="font-weight: 500;">
        Your vehicle:
        <span style="font-weight: 400">{{ displayText }}</span>
      </h1>
      <h1 class="display-4">Select a Location</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 offset-xl-3 col-md-10 offset-md-1 col-sm-10 offset-sm-1 col-12" >
      <div style="background-color: var(--bs-white);  padding: 24px">
        <div style="font-size: 20px !important; line-height: 28px;">
          <i class="fa-solid fa-location-dot" aria-hidden="true"></i>
          <p>
            Select your Dealer to find inventory nearby.
          </p>
        </div>
        <div style="margin: 30px 0">
          <div class="row">
            <div class="col-12 col-sm-5 col-md-6 mb-2 ">
              <input type="text" #dealerCode placeholder="Search Here" [(ngModel)]="selectedDealer"  matInput [formControl]="myControl" 
              [matAutocomplete]="auto">
      
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{option.dealer_number}}  {{option?.name | lowercase | titlecase }}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="col-6 col-sm-4 col-md-2 mb-2">
              <div class="ms-3t">
                <button mat-button class="toyota-btn-secondary toyota-btn-sm me-2" (click)="validateDealerCode(dealerCode.value)">Submit</button>
            
                <!-- <dsmds-button (onClickfunc)="validateDealerCode(dealerCode.value)" [buttonConfig]="{
                  'text':'Submit',
                  'type':'secondary',
                   'size' : 'sm'
                    }"></dsmds-button> -->
              </div>
            </div>
            <div class="col-6 col-sm-3 col-md-2  mb-2">
              <div class="ms-3t">
                <button 
                class="toyota-btn-warning"
                type="button"
                (click)="onClick()">
                Clear
               </button>
              </div>
            </div>
    
          </div>
          <!-- <select [(ngModel)]="selectedDealer" class="form-input" #dealerCode>
            <option [value]="dealer.dealer_number" *ngFor="let dealer of dealer_list">{{dealer.dealer_number}} - {{dealer?.name | lowercase | titlecase}}</option>
          </select> -->
          
        
          
          
        
          <div
            [ngClass]="validDealer === true ? 'success' : 'error'"
            style="margin-top: 30px"
          >
            {{ message }}
          </div>
        </div>
        <div style="display: block">
          <span>Ideal distance range (mi):{{ 0 }} </span>
          <input
            #slider3
            type="range"
            min="0"
            max="0"
            step="0"
            name="foo"
            (change)="setVal($event)"
          />
          <output for="foo" onforminput="value = foo.valueAsNumber;"></output>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="moveButtons" style="margin: 30px 0">
              <button
                (click)="navigateToFindCar($event)"
                [disabled]="!validDealer"
          
                class="next"
              >
                Next step
              </button>
            </div>
          </div>
        </div>    
      </div>
    </div>
  </div>
</div>
