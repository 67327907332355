


<div class="justify-content-center" [hidden]="loading">
  <div class="spinner-border" role="status">
    <span class="sr-only" id="loading"></span>
  </div>
</div>
<div class="container pb-4" [hidden]="!loading">
  <div class="row text-center text-white mb-5">
    <div class="model-container">
      <span style="margin-top: 5%; margin-left: 20%; text-align: center">
        <h1 class="container-heading">
          YOUR VEHICLE:
          <span style="font-weight: 500" [innerHTML]="lexDisplayText"></span>
        </h1>
      </span>
      <span style="margin-top: 12%; margin-left: 20%">
        <h1 class="display-4">SELECT AN EXTERIOR COLOR</h1>
      </span>
    </div>
  </div>
  <div class="dsmds-steps-navigations-lexus">
    <dsmds-steps-navigations-lexus [tabnumber]="2" [exteriordata]="exteriordata" ></dsmds-steps-navigations-lexus>
  </div>
  <div class="color-info row" style="display: flex">
    <div class="col-sm-12 col-md-6 col-lg-7">
      <div
        class="bg-image hover-overlay ripple shadow-1-strong rounded"
        data-ripple-color="light"
      >
        <img
          alt="Car Image"
          src="{{ selected_exterior?.ext_image? selected_exterior?.ext_image: 'assets/images/default-car-lexus.png'}}"
          [class.no-image] = "!selected_exterior?.ext_image"
          class="ml-lg-5 order-1 order-lg-2 img-fluid"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-5">
      <div class="colors-buttonn row">
        <div class="col-3 " *ngFor="let data of exterior_color">
          <div class="colors" (click)="getExtImg(data)">
            <div class="buttonGrps">
              <div
                class="hover-overlay ripple shadow-1-strong image exterior-img"
                data-ripple-color="light" 
              >
                <img
                  [ngClass]="{
                   'selected': data?.code == selected_exterior?.code
                  }"

                  
                  
                  src="{{ data.image }}"
                  alt="Car Image"
                  class="ml-lg-5 order-1 order-lg-2 img-fluid"
                />
              </div>
              <label class="labelGrp">
                <h4 class="card-color-name my-2">
                  {{ formatText(data) }}
                  <sup
                    *ngIf="data.price != 0"
                    style="cursor: pointer"
                    (click)="openDialog('lexus-msrp')"
                    >*</sup
                  >
                </h4>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="moveButtons" >
      <dsmds-button-lexus  
        (onClickfunc)="navigateToInteriorColor($event, selected_exterior)" [buttonConfig]="{
          'text':'NEXT STEP',
          'type':'primary',
           'size' : 'lg',
           data: selected_exterior
        }"
        ></dsmds-button-lexus>
  </div>
</div>
