<div class="container">
  <div class="row text-center text-white mb-5">
    <div class="model-container">
      <span style="margin-top: 5%; margin-left: 20%; text-align: center">
        <h1 class="container-heading">
          YOUR VEHICLE:
          <span style="font-weight: 500" [innerHTML]="lexDisplayText"></span>
        </h1>
      </span>
      <span>
        <h1 class="display-4">
          SELECT AN INTERIOR COLOR
        </h1>
      </span>
    </div>
  </div>
  <div class="dsmds-steps-navigations-lexus">
    <dsmds-steps-navigations-lexus [tabnumber]="3" [interiordata]="interiordata"></dsmds-steps-navigations-lexus>
  </div>
  <div class="container1">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">

        <div class="bg-image hover-overlay ripple shadow-1-strong rounded" data-ripple-color="light">
          <img alt="Car Image"
          dsmdsImgFallback="assets/images/toyota-interior-df.png"
            src="{{ interiorImageResult[interior_code_selected?.code]}}"
            [class.no-image]="!interior_code_selected || !interiorImageResult[interior_code_selected?.code]" 
            class="ml-lg-5 order-1 order-lg-2 img-fluid" />
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="row">
          <div class="col-lg-4 col-xl-3 row-cols-xxxl-3 col-md-4 col-sm-3 col-4 mb-4"
            *ngFor="let data of interior_color">

            <div class="interior-colorr">
              <a (click)="getOption(data)" class="general-router-lx" [class.active-int]="intlexcode==data.code">
                <img alt="Car Image" src="{{ data.image? data.image: 'assets/images/default-car-lexus.png'}}"
                  [class.no-image]="!data.image" class="img-fluid" />
                <label class="labelGrp">
                  <div class="card-color-name" [innerHtml]="formatText(data.title)"></div>
                </label>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="moveButtons mb-4" >
    <dsmds-button-lexus  *ngIf="intlexcode"
      (onClickfunc)="navigateToPkg($event)" [buttonConfig]="{
        'text':'NEXT STEP',
        'type':'primary',
         'size' : 'lg',
         data: code
      }"
      ></dsmds-button-lexus>
</div>
</div>