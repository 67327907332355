import { Injectable } from "@angular/core";
import { isLocalOrDev } from "src/app/utils";
import { FEATURE_FLAG_ANALYTICS } from "src/app/constants/flags.constants";
import { ANALYTICS_OVERRIDE_ON } from "src/app/constants/analytics.constants";

@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  constructor() {}

  public isFlag(flag: string) {
    //TODO: Move this to configuration or server side lookup.
    if (flag === FEATURE_FLAG_ANALYTICS) {
      return ANALYTICS_OVERRIDE_ON || isLocalOrDev();
    }
    return false;
  }
}
