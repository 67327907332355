import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FindMyCarService } from "../../../service/find-my-car.service";
import {
  MatDialog,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from "@angular/material/dialog";
import { DealerPopupComponent } from "../../shared/dealer-popup/dealer-popup.component";
import * as _ from "lodash";
import { DisclaimerPopupComponent } from "../../shared/disclaimer-popup/disclaimer-popup.component";
import { ConnectableObservable, Subject, take, takeUntil } from "rxjs";
import { UiStateService } from "../../../service/ui-state.service";
import {
  ETACATEGORY,
  FEATURECATEGORY,
  YEARCATEGORY,
  YEARCATEGORYCURRENT,
} from "../../../constants/findcar.constants";
import { OptionFilterComponent } from "../../shared/option-filter/option-filter.component";
import { TOYOTA_BASE_URL } from "src/app/constants/imagebase.constants";
import { Router } from "@angular/router";
import { getFormattedDate, DEBUG } from "src/app/utils";
import { PROVIDE_FEEDBACK_URL, ROUTES_TOYOTA } from "../../../constants";
import { map } from "lodash";
import { ApiRouteService } from "../../../config/api-routes";
import { ObservableDataShareService } from "../../../service/data-share/observable-data-share.service";
import { AnalyticsService } from "../../../service/analytics/analytics.service";
import { ANALYTICS_TEMPLATE_SEED } from "src/app/constants/analytics.constants";
import { v4 as uuidv4 } from "uuid";

const AVAILABLE_NOW = "Available Now";

@Component({
  selector: "app-find-my-car",
  templateUrl: "./find-my-car.component.html",
  styleUrls: ["./find-my-car.component.css"],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
  ],
})
export class FindMyCarComponent implements OnInit, OnDestroy {
  @Input() isLogoVisible;
  date = new Date();
  carData: any = [];
  carList: any = [];
  cardatalength = 0;
  latest_date: any;
  exact_match: any;
  best_match: any;
  best_upsell_match: any = [];
  your_choice: any;
  loading = false;
  drive = "";
  prev = 0;
  next = false;
  ui_state: any;
  modeselect: any;
  edaselect: any;
  modeyear: any = "none";
  year_options: any;
  eta_options: any = ETACATEGORY;
  checkList: any = FEATURECATEGORY;
  estimatedDateObj: any = {};
  feedbackUrl: string = "";
  selectedFilterMap = new Map<
    string,
    any
  >(); /* string: type title, any: event */
  userId: string = undefined;
  sessionId: string = undefined;

  unsubscribe$ = new Subject();
  constructor(
    private findCarService: FindMyCarService,
    private appState: UiStateService,
    public dialog: MatDialog,
    private router: Router,
    private apiRouteService: ApiRouteService,
    private obDataShareService: ObservableDataShareService,
    private analyticsService: AnalyticsService
  ) {
    /* Extract uistate service selection */
    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        if (
          !state.hasOwnProperty("exterior_color") ||
          state?.exterior_color == null
        ) {
          this.router?.navigate(ROUTES_TOYOTA.slice(0, 4));
        } else if (
          !state.hasOwnProperty("interior_color") ||
          state?.interior_color == null
        ) {
          this.router?.navigate(ROUTES_TOYOTA.slice(0, 5));
        }
        // else if(!state.hasOwnProperty('option_list') || state?.option_list==null){
        //   this.router?.navigate(ROUTES_TOYOTA.slice(0, 6));
        // }
        else {
          this.ui_state = state;
        }
      });

    this.obDataShareService.userId.subscribe((data) => {
      this.userId = data ? data : undefined;
    });

    this.obDataShareService.sessionId.subscribe((data) => {
      this.sessionId = data ? data : undefined;
    });
  }

  ngOnInit(): void {
    this.feedbackUrl = PROVIDE_FEEDBACK_URL;
    try {
      this.modeselect = 100;
      this.edaselect = 100;
      this.year_options =
        parseInt(this.ui_state?.year) == this.date.getFullYear()
          ? YEARCATEGORYCURRENT
          : YEARCATEGORY;
      this.getUserSelection();
      this.getFindCarData();
    } catch (error) {
      console.log(error);
    }
  }

  /* Extract user seed veichle selection */
  getUserSelection() {
    this.your_choice = {
      model_number: this.ui_state?.modelNo,
      series: this.ui_state?.series,
      exterior_color: this.ui_state?.exterior_color?.color_name,
      exterior_color_code: this.ui_state?.exterior_color?.color_code,
      interior_color: this.formatColorName(
        this.ui_state?.interior_color?.color_name
      ),
      interior_color_code: this.ui_state?.interior_color?.color_code,
      drive: this.ui_state?.grade_attributes?.drive_title,
      grade: this.ui_state?.grade,
      model_year: this.ui_state?.year,
      img: this.ui_state.exterior_color?.image,
      jellyImage: this.ui_state.exterior_color?.jellyImage,
      option_packages: this.ui_state?.option_list
        ? this.ui_state?.option_list.join(", ")
        : null,
      option_package_details: this.formatPackageDetails(
        this.ui_state?.option_list ? this.ui_state?.option_list : null,
        this.ui_state?.option_list_titles
          ? this.ui_state?.option_list_titles
          : null,
        this.ui_state?.option_list_details
          ? this.ui_state?.option_list_details
          : null
      ),
      msrp: this.calculateMsrp(),
    };
    console.log(this.your_choice);
    this.drive = this.ui_state?.grade_attributes?.drive_title;
    console.log(this.your_choice);
  }

  /* Calculate seed veichle msrp 
     msrp=trim mrsp+exterior color msrp+ option msrp */
  calculateMsrp() {
    const ext_cost = this.ui_state.exterior_color.color_msrp
      ? this.ui_state.exterior_color.color_msrp
      : 0;
    return (
      this.ui_state.grade_attributes.price +
      this.ui_state.option_msrp +
      ext_cost
    );
  }

  /* Extract seed veichle configuration by calling findcar api */
  getFindCarData() {
    const D = DEBUG("FindMyCarComponent.getFindCarData()");
    D("UI State Captured", this.ui_state);
    console.log('making find car call -- dealer is -- '+this.ui_state?.tySelectedDealerCode);
    // Returns a promise, but don't need to wait.
    this.analyticsService.capture(
      this.apiRouteService.getRoutes().ANALYTICS.SEED_SAVE(),
      ANALYTICS_TEMPLATE_SEED,
      {
        brand: "toyota",
        transaction_id: uuidv4(),
        session_id: this.sessionId,
        user_id: this.userId,
        model_year: this.ui_state?.year,
        model_number: this.ui_state?.modelNo,
        series: this.ui_state?.seriescode,
        categories: this.ui_state?.seriesCategory,
        grade: this.ui_state?.grade,
        drivetrain: this.ui_state?.grade_attributes?.drive_code,
        interior_color: this.ui_state?.interior_color?.color_code,
        exterior_color: this.ui_state?.exterior_color?.color_code,
        dealer_code: this.ui_state?.tySelectedDealerCode,
        option_packages: this.ui_state?.option_list
          ? this.ui_state.option_list.join(",")
          : null,
      }
    );

    this.findCarService
      .updateCarDetails(
        this.ui_state?.year,
        this.ui_state?.modelNo,
        this.ui_state?.tySelectedDealerCode,
        parseInt(this.ui_state?.tySelectedDealerRadii),
        this.ui_state?.exterior_color.color_code,
        this.ui_state?.interior_color.color_code,
        this.ui_state?.option_list ? this.ui_state.option_list.join(",") : null
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (result) => {
          if (typeof result === "object") {
            console.log(
              "result is an object. reassign to result['body']",
              result
            );
            result = result["body"];
          }
          if (result == '"No data for given combination"') {
            this.loading = true;
            this.latest_date = "no results";
            return;
          } else {
            /* Format ETA drivetrain name and interior color to match seed veichle format */
            result.forEach((element) => {
              element["eta"] = this.getEta(element?.wait_days_min);
              element["edaText"] = this.getEstimatedArrival(element);
              element.drivetrain_name_compare = this.formatDrive(
                element?.drivetrain_name_compare
              );
              element.interior_color_name_compare = this.formatColorName(
                element.interior_color_name_compare.split("&")[0]
              );
            });
            // console.log('result', result)

            // result = result.concat(result);
            // result = result.concat(result);
            // result = result.concat(result);
            // console.log('result', result)
            this.carList = result;
            /* Set latest date */
            if (result.length > 0) {
              this.latest_date = result[0]?.latest_date;
            }
            this.getExactMatch(result);
            this.loading = true;
          }
        },
        (error) => {
          this.loading = true;
          this.latest_date = error["error"];
        }
      );
  }

  /* Remove any records that match the first record/exact match
   and limit data displayed to 40 */
  getExactMatch(result) {
    /* Initialize variable */
    this.carData = [];
    this.cardatalength = 0;
    this.exact_match = [];
    this.best_match = [];
    this.best_upsell_match = [];
    this.prev = 0;
    this.next = false;
    /* Initialize result with car data */
    result.forEach((val) => this.carData.push(Object.assign({}, val)));
    /* The first record is exact match. Once you
       get exact match remove it from the list*/
    this.exact_match = result[0];
    this.getPackageDetails(this.exact_match);
    console.log("exact match", this.exact_match);

    this.carData.shift();

    /* Remove any records that match exact match*/
    _.remove(this.carData, function (c) {
      return (
        c["model_year_compare"] === result[0].model_year_compare &&
        c["model_number_compare"] == result[0].model_number_compare &&
        c["exterior_color_code_compare"] ==
          result[0].exterior_color_code_compare &&
        c["interior_color_code_compare"] ==
          result[0].interior_color_code_compare &&
        c["fio_list_compare"] == result[0].fio_list_compare
      );
    });
    /* Restrict max length to 40*/
    this.cardatalength = this.carData.length >= 40 ? 40 : this.carData.length;
    /* Get 3 best match and 1 upsell match*/
    this.getBestUpsellData(false);
  }

  /* Extract top 3 best match and remaining sort based on upsell rank */
  getBestUpsellData(isprev) {
    this.next = isprev;
    /* If prev is less then total recommendations extract data from exisiting list */
    if (this.prev < this.best_upsell_match.length) {
      if (isprev && this.prev == 0) {
        this.prev = this.prev + 4;
      }
      this.best_match = this.best_upsell_match.slice(this.prev, this.prev + 4);
      if (
        this.cardatalength + (this.cardatalength - this.prev) >=
        this.prev + 4
      ) {
        this.prev = this.prev + 4;
      }
    } else if (this.carData.length > 0) {
      /* Sort based on best match and extract 3 records and remove them from the list*/
      this.carData = _.sortBy(this.carData, ["best_match"]);
      const best_match = this.carData.slice(0, 4);
      this.best_upsell_match.push(...best_match);
      /* Remove 3 records from list*/
      this.carData.shift();
      this.carData.shift();
      this.carData.shift();
      this.carData.shift();

      // COMMENTING BECAUSE OF - OOTD-863 (UIU- Remove upsell calculations from the results page)
      // DO NOT NEED TO ADD UPSELL MATCH -- POs MADE A DECISION NOT TO INCLUDE UPSELL
      // UPSELL VEHICLES ARE :  msrp > similarity_matrix.msrp_historic

      /* Sort based on upsell_match  rank and extract 1 records and remove them from the list*/
      // this.carData = _.sortBy(this.carData, ["upsell_match"]);
      // const upsell_match = this.carData.slice(0, 1);
      // this.best_upsell_match.push(...upsell_match);
      // this.carData.shift();
      this.best_match = this.best_upsell_match.slice(this.prev, this.prev + 4);
      /* Update prev index by 4*/
      if (
        this.cardatalength + (this.cardatalength - this.prev) >=
        this.prev + 4
      ) {
        this.prev = this.prev + 4;
      }
    }
    // get package details for each car
    for (let car = 0; car < this.best_match?.length; car++) {
      this.getPackageDetails(this.best_match[car]);
    }
    console.log("this.best_match", this.best_match);
  }

  /* Extract prev 4 data */
  getBestUpsellDataPrev() {
    /* If prev is greater then 4 then extract previous 4 elements in list*/
    if (this.prev > 0) {
      if (this.prev >= this.cardatalength) {
        this.prev = this.prev - 4;
        this.best_match = this.best_upsell_match.slice(
          this.prev - 4,
          this.prev
        );
        this.prev = this.prev - 4;
      } else if (this.prev - 4 >= 0) {
        /* If prev is 0 then set prev index to -4*/
        this.best_match = this.best_upsell_match.slice(
          this.prev - 4,
          this.prev
        );
        this.prev = this.prev - 4;
      }
    }
    if (this.prev == 0) {
      this.next = false;
    }
  }

  /* Open dealer Pop showing vin within/outside dealer*/
  openDialog(data, modalFor = "vin") {
    this.dialog.open(DealerPopupComponent, {
      data: {
        vehicleData: data,
        modalFor: modalFor,
        brand: "T",
      },
    });
  }

  /* Open disclaimer popup*/
  openDialogDisclaimer(type) {
    this.dialog.open(DisclaimerPopupComponent, {
      data: type,
    });
  }

  getImage(image) {
    return TOYOTA_BASE_URL + image;
  }

  /* If grade includes hybrid then add hybrid to series name*/
  getSeriesName(choice) {
    if (
      choice?.grade?.includes("Hybrid") &&
      !choice?.series?.includes("Hybrid")
    ) {
      return choice.series + " Hybrid";
    }
    return choice.series;
  }

  /* Added 1 additional week to eta to increase eta accuracy*/
  getEta(wait_days) {
    if (wait_days) {
      if (wait_days <= 0) {
        return AVAILABLE_NOW;
      } else {
        return (wait_days + 1).toString() + " weeks";
      }
    }
    return AVAILABLE_NOW;
  }

  /* Format price to display in UI*/
  formatPrice(price) {
    return (
      "$" +
      parseInt(price)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  }

  formatExtColor(colorName) {
    let result = colorName?.replace("extra_cost_color", "Extra cost color");
    return result;
  }

  formatDate(latestDate) {
    return latestDate;
  }

  formatDrive(drive) {
    if (drive) {
      if (["AWD", "FWD"].indexOf(this.drive) !== -1) {
        drive = drive.replace("2WD", "FWD");
        drive = drive.replace("4WD", "AWD");
      } else if (["RWD"].indexOf(this.drive) !== -1) {
        drive = drive.replace("2WD", "RWD");
        drive = drive.replace("4WD", "AWD");
      }
      return drive;
    }
  }
  formatColorName(colorName) {
    return colorName
      .replace(/SofTex®|Softex®|SofTex®&nbsp;/g, "SofTex")
      .split("[")[0]
      .trim();
  }

  onSelection(event) {
    for (let i = 0; i < this.checkList.length; i++) {
      if (this.checkList[i].type == "wait_days_min") {
        this.checkList[i].value = event.value;
      }
    }
    this.onCheck("", "", "");
  }

  /**
   * After user edit on filter, this function can check if all selected filters are applied.
   * @param result
   * @returns new result
   */
  applySelectionEDA(event, result) {
    const selectedWeek = parseInt(event?.value) + 1;
    const today = new Date();
    const dateAfterSelectedWeek = new Date();
    dateAfterSelectedWeek.setDate(
      dateAfterSelectedWeek.getDate() + selectedWeek * 7
    );

    /* Assign result to carlist */
    if (selectedWeek < 100) {
      result = result.filter((y) => {
        const vin0 = y?.vin_compare[0];
        const eda = this.estimatedDateObj[vin0];
        if (eda.starts === "AV") {
          return true;
        } else if (eda.starts) {
          const edastarts = new Date(eda.starts);
          const edaends = new Date(eda.ends);
          if (edastarts.getTime() < today.getTime()) {
            if (edaends.getTime() >= today.getTime()) {
              return true;
            }
          } else {
            if (edaends.getTime() <= dateAfterSelectedWeek.getTime()) {
              return true;
            }
          }
        }
        return false;
      });
    }
    // Handling the case for Available now with value 101
    if (selectedWeek == 100) {
      result = result.filter((y) => {
        const vin0 = y?.vin_compare[0];

        const eda = this.estimatedDateObj[vin0];
        if (eda.starts === "AV") {
          return true;
        }
        return false;
      });
    }
    return result;
  }

  applyFiltersMap(result) {
    console.log("applyFiltersMap(RESULT) result: ", result);
    console.log(
      "applyFiltersMap(RESULT) this.selectedFilterMap: ",
      this.selectedFilterMap
    );
    for (let key of this.selectedFilterMap.keys()) {
      console.log(
        "applyFiltersMap(RESULT) key: ",
        key,
        ", this.selectedFilterMap[key]: ",
        this.selectedFilterMap.get(key)
      );
      if (key == "etaDealerArrival") {
        result = this.applySelectionEDA(
          this.selectedFilterMap.get(key),
          result
        );
      }
      if (key == "model_year_compare") {
        if (this.selectedFilterMap.get(key)?.value != "none") {
          result = result.filter((y) => {
            if (
              y.model_year_compare ==
              parseInt(this.selectedFilterMap.get(key)?.value)
            ) {
              return true;
            }
            return false;
          });
        }
      }
      if (key == "model_number_compare") {
        result = result.filter((y) => {
          if (this.selectedFilterMap.get(key)[0]?.checked) {
            if (y.model_number_compare == this.selectedFilterMap.get(key)[1]) {
              return true;
            }
            return false;
          }
          return true;
        });
      }
      if (key == "toms_series_name_compare") {
        result = result.filter((y) => {
          if (this.selectedFilterMap.get(key)[0]?.checked) {
            if (
              y.toms_series_name_compare == this.selectedFilterMap.get(key)[1]
            ) {
              return true;
            }
            return false;
          }
          return true;
        });
      }
      if (key == "grade_spec_name_compare") {
        result = result.filter((y) => {
          if (this.selectedFilterMap.get(key)[0]?.checked) {
            if (
              y.grade_spec_name_compare == this.selectedFilterMap.get(key)[1]
            ) {
              return true;
            }
            return false;
          }
          return true;
        });
      }
      if (key == "drivetrain_name_compare") {
        result = result.filter((y) => {
          if (this.selectedFilterMap.get(key)[0]?.checked) {
            if (
              y.drivetrain_name_compare == this.selectedFilterMap.get(key)[1]
            ) {
              return true;
            }
            return false;
          }
          return true;
        });
      }
      if (key == "interior_color_name_compare") {
        result = result.filter((y) => {
          if (this.selectedFilterMap.get(key)[0]?.checked) {
            if (
              y.interior_color_name_compare ==
              this.selectedFilterMap.get(key)[1]
            ) {
              return true;
            }
            return false;
          }
          return true;
        });
      }
      if (key == "exterior_color_name_compare") {
        result = result.filter((y) => {
          if (this.selectedFilterMap.get(key)[0]?.checked) {
            if (
              y.exterior_color_name_compare ==
              this.selectedFilterMap.get(key)[1]
            ) {
              return true;
            }
            return false;
          }
          return true;
        });
      }
      if (key == "fio_list_compare") {
        result = result.filter((y) => {
          let y_fio_array = y.fio_list_compare.split(",");
          if (this.selectedFilterMap.get(key)[0]) {
            let response_data_array = this.selectedFilterMap
              .get(key)[0]
              .split(", ");
            if (response_data_array.every((r) => y_fio_array.includes(r))) {
              return true;
            }
            return false;
          }
          return true;
        });
      }
    }
    console.log("applyFiltersMap(RESULT) finished. result: ", result);
    return result;
  }

  onSelectionEDA(event) {
    //console.log('onSelectionEDA', event)
    this.selectedFilterMap.set("etaDealerArrival", event);
    let result = [];
    const selectedWeek = parseInt(event?.value) + 1;
    const today = new Date();
    const dateAfterSelectedWeek = new Date();
    dateAfterSelectedWeek.setDate(
      dateAfterSelectedWeek.getDate() + selectedWeek * 7
    );

    /* Assign result to carlist */
    this.carList.forEach((val) => result.push(Object.assign({}, val)));
    if (selectedWeek < 100) {
      result = result.filter((y) => {
        const vin0 = y?.vin_compare[0];

        const eda = this.estimatedDateObj[vin0];
        if (eda.starts === "AV") {
          return true;
        } else if (eda.starts) {
          const edastarts = new Date(eda.starts);
          const edaends = new Date(eda.ends);
          if (edastarts.getTime() < today.getTime()) {
            if (edaends.getTime() >= today.getTime()) {
              return true;
            }
          } else {
            if (edaends.getTime() <= dateAfterSelectedWeek.getTime()) {
              return true;
            }
          }
        }
        return false;
      });
    }
    // Handling the case for Available now with value 101
    if (selectedWeek == 100) {
      result = result.filter((y) => {
        const vin0 = y?.vin_compare[0];

        const eda = this.estimatedDateObj[vin0];
        if (eda.starts === "AV") {
          return true;
        }
        return false;
      });
    }
    result = this.applyFiltersMap(result);
    //estimatedDateObj
    this.getExactMatch(result);
  }

  onSelectionYear(event) {
    this.selectedFilterMap.set("model_year_compare", event);
    let result = [];
    const selectYear = parseInt(event?.value);
    console.log("onSelectionYear(event) event: ", event, event.value == "none");
    /* Assign result to carlist */
    this.carList.forEach((val) => result.push(Object.assign({}, val)));
    if (event.value != "none") {
      result = result.filter((y) => {
        if (y.model_year_compare == selectYear) {
          return true;
        }
        return false;
      });
    }
    result = this.applyFiltersMap(result);
    //estimatedDateObj
    this.getExactMatch(result);
  }
  /* Filter logic */
  onCheck(event, type, value) {
    this.selectedFilterMap.set(type, [event, value]);
    let result = [];
    const selectFilterStatus = event?.checked;
    /* Assign result to carlist */
    this.carList.forEach((val) => result.push(Object.assign({}, val)));
    /* Checklist contains all columns that need filtering. We iterate 
     over each attribute and depending on value filter the result */
    result = result.filter((y) => {
      if (type == "model_number_compare") {
        if (selectFilterStatus) {
          if (y.model_number_compare == value) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      } else if (type == "toms_series_name_compare") {
        if (selectFilterStatus) {
          if (y.toms_series_name_compare == value) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      } else if (type == "grade_spec_name_compare") {
        if (selectFilterStatus) {
          if (y.grade_spec_name_compare == value) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      } else if (type == "drivetrain_name_compare") {
        if (selectFilterStatus) {
          if (y.drivetrain_name_compare == value) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      } else if (type == "interior_color_name_compare") {
        if (selectFilterStatus) {
          if (y.interior_color_name_compare == value) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      } else if (type == "exterior_color_name_compare") {
        if (selectFilterStatus) {
          if (y.exterior_color_name_compare == value) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      }
      return false;
    });
    result = this.applyFiltersMap(result);
    this.getExactMatch(result);
  }

  /*Open option code Filter */
  openOptionDialog(options) {
    let selected_options = [];
    for (let i = 0; i < this.checkList.length; i++) {
      if (this.checkList[i].type == "fio_list_compare") {
        selected_options = this.checkList[i].value;
      }
    }
    this.dialog
      .open(OptionFilterComponent, {
        data: {
          option_data: options.split(","),
          selected_options: selected_options,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response?.data) {
          for (let i = 0; i < this.checkList.length; i++) {
            if (this.checkList[i].type == "fio_list_compare") {
              this.checkList[i].value = response?.data;
            }
          }
          /*Open option code selection filter list */
          this.selectedFilterMap.set("fio_list_compare", response?.data);
          let result = [];
          /* Assign result to carlist */
          this.carList.forEach((val) => result.push(Object.assign({}, val)));
          result = result.filter((y) => {
            let y_fio_array = y.fio_list_compare.split(",");
            if (response?.data[0]) {
              let response_data_array = response?.data[0].split(", ");
              if (response_data_array.every((r) => y_fio_array.includes(r))) {
                return true;
              }
              return false;
            }
            return true;
          });
          result = this.applyFiltersMap(result);
          //estimatedDateObj
          this.getExactMatch(result);
        }
      });
  }
  getFormatted(dateValue, agg) {
    if (
      Object.prototype.toString.call(dateValue) === "[object Array]" &&
      dateValue.length
    ) {
      var filtered = dateValue.filter(function (el) {
        return el != null;
      });
      let dateV = null;
      if (filtered.length) {
        if (agg === "min") {
          dateV = Math.min(...filtered.filter((a) => a !== null));
        } else if (agg === "max") {
          dateV = Math.max(...filtered.filter((a) => a !== null));
        }
      }
      return dateV ? getFormattedDate(dateV) : "";
    } else {
      return "";
    }
  }

  getEstimatedArrival(carData) {
    //console.log('carData', carData?.vin_compare[0])
    if (!carData || carData === undefined) {
      return "";
    }
    let isGcatg = [];
    if (carData?.category) {
      const categories = carData?.category;
      isGcatg = categories.filter((ctg) => {
        return ctg[0] == "G";
      });
    }
    if (
      isGcatg.length ||
      this.getEta(carData?.wait_days_min) == AVAILABLE_NOW
    ) {
      this.estimatedDateObj[carData?.vin_compare[0]] = {
        starts: "AV",
        ends: "AV",
      };
      return AVAILABLE_NOW;
    } else {
      const st = this.getFormatted(carData?.etaw_start_compare, "min");
      const ed = this.getFormatted(carData?.etaw_end_compare, "max");
      this.estimatedDateObj[carData?.vin_compare[0]] = {
        starts: st,
        ends: ed,
      };
      return st + " - " + ed;
    }
  }
  navigateToToyota() {
    this.router.navigate(["/toyota"]);
  }
  onClickfunc(e) {}
  onClickRedirect() {
    this.appState?.setState({
      seriescode: null,
      seriesCategory: null,
      year: null,
      series: null,
      grade: null,
      gradeData: null,
      modelNo: null,
      grade_attributes: null,
      option_data: null,
      exterior_color: null,
      interior_color: null,
      option_list: null,
      option_msrp: null,
      optionData: null,
    });
    this.navigateToToyota();
  }
  ngOnDestroy() {
    this.unsubscribe$.complete();
  }

  getPackageDetails(car) {
    try {
      //using passed in car data, call getoptions api
      this.findCarService
        .getOptionPackage(car.model_number_compare, car.model_year_compare)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          error: (err) => {
            console.log(err);
          },
          next: (data) => {
            let car_list = car?.fio_list_compare.split(",");
            //check for extra spaces only if there is a list
            console.log(car_list, car_list.length);
            if (car_list.length == 1 && car_list[0] == "") {
              car_list = ["FE"];
            } else if (car_list.length >= 1) {
              car_list[car_list.length - 1] = car_list[
                car_list.length - 1
              ].replace(/\s+/g, "");
              car_list.push("FE");
              car_list.sort();
            }
            console.log(data, car_list);

            //determine which option is the right one by comparing car's fio_list to getoptions options_list
            for (let i = 0; i < data.length; i++) {
              //only compare if same size
              if (data[i].options_list.length == car_list.length) {
                let packageMatch = true;

                let sorteddataopt = data[i].options_list.sort();

                //sort each list and compare all elements
                for (let j = 0; j < car_list.length; j++) {
                  if (sorteddataopt[j] != car_list[j]) {
                    packageMatch = false;
                    break;
                  }
                }

                //if all elements match, format the data appropriately and push to option_package_details
                if (packageMatch == true) {
                  console.log(car_list, data[i].options_titles);
                  car.option_package_details = this.formatPackageDetails(
                    data[i].options_list,
                    data[i].options_titles,
                    data[i].options_descriptions
                  );
                  return;
                }
              }
            }
            console.log(car.option_package_details);
          },
        });
    } catch (error) {
      console.log(error);
    }
  }

  formatPackageDetails(pList, pTitles, pDescs) {
    console.log("THE LISTS", pList, pTitles);
    let finalList = [];
    let packageString = "";
    for (let i = 0; i < pTitles?.length; i++) {
      //skip the FE package
      if (pList[i] != "FE") {
        let title = this.titleCase(pTitles[i]);
        let desc = pDescs[i];
        packageString = pList[i] + " - " + title;
        finalList.push(packageString);
      }
    }
    console.log("print finalList", finalList);
    return finalList;
  }

  titleCase(string) {
    let str = string.toLowerCase();
    str = str.split(" ");
    //make each word capitalized
    for (let i = 0; i < str.length; i++) {
      //find first alpha character
      let alpha = 0;
      for (let j = 0; j < str[i].length; j++) {
        //if character is alphabetical, set alpha and return to outer for loop
        if (/[a-zA-Z]/.test(str[i].charAt(j))) {
          alpha = j;
          break;
        }
      }

      //add [any pre-capital characters] + [capitalized character] + [all remaining chars]
      str[i] =
        str[i].slice(0, alpha) +
        str[i].charAt(alpha).toUpperCase() +
        str[i].slice(alpha + 1);
    }

    //rebuild array into string and return
    str = str.join(" ");
    return str;
  }
}
