import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable, Subject, take, takeUntil } from "rxjs";

import { map, startWith } from "rxjs/operators";
import { FindMyCarService } from "src/app/service/find-my-car.service";
import { UiStateService } from "../../../service/ui-state.service";

@Component({
  selector: "dsmds-lexus-zipcode",
  templateUrl: "./lexus-zipcode.component.html",
  styleUrls: ["./lexus-zipcode.component.css"],
})
export class LexusZipcodeComponent implements OnInit, OnDestroy {
  @ViewChild("slider3", { read: ElementRef })
  public sliderOne: ElementRef;
  myControl = new FormControl();
  personalDetailsStep: FormGroup;
  list_dealer: any;
  message: string = "";
  loading: boolean = false;
  radii: any = 0;
  validDealer: any;
  dealer_code: any;
  lexDisplayText: any;
  unsubscribe$ = new Subject();
  dealer_list: any = [];
  filteredOptions: Observable<any[]>;
  constructor(
    private appState: UiStateService,
    private router: Router,
    private findCarService: FindMyCarService
  ) {}

  ngAfterViewInit(): void {
    if (this.sliderOne) {
      this.sliderOne.nativeElement.value = this.radii;
    }
  }

  validateDealerCode(dealerCode) {
    if (dealerCode == "" || this.selectedDealer == null) {
      this.message = "Invalid Code";
      this.validDealer = null;
    } else {
      if (dealerCode[0] == "6") {
        this.message = "Valid Code";
        this.validDealer = true;
        this.dealer_code = this.selectedDealer?.dealer_number;
      } else {
        this.message = "Invalid Code";
        this.validDealer = false;
      }
    }
  }

  setVal(e) {
    this.radii = e.target.value;
  }

  selectedDealer: any;

  ngOnInit(): void {
    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        if (state.lexSelectedInterior === undefined) {
          this.router.navigate(["/"]);
        }
        this.list_dealer = [state.claims?.dealer_number];
        this.dealer_list = state.dealer_list;
        if (state.current_dealer) {
          this.selectedDealer = state.current_dealer;
        }

        this.list_dealer = [state.claims?.dealer_number];
        if (state.claims?.user_type == "Dealer-User") {
          this.dealer_list = this.dealer_list.filter(
            (value) => value.dealer_number == this.selectedDealer.dealer_number
          );
        }
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(""),
          map((state) =>
            state ? this._filter(state) : this.dealer_list.slice()
          )
        );
        this.lexDisplayText =
          state.lexGradeTitle +
          " " +
          state.lexDriveTitle +
          " IN " +
          state.lexDisplayExterior +
          " WITH " +
          state.lexSelectedInterior?.title.toUpperCase();
        +" [" + state?.lexSelectedoptionCode?.join(",") + "]";
        this.findCarService
          .getValidOption(
            state.lexGradeCode,
            state.lexYear,
            state?.lexSelectedoptionCode?.join(",")
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (data) => {
              // console.log(data);
              this.loading = true;
              if (data.length > 0) {
                const option_length =
                  data[0].option_length_intersect == 0
                    ? 1
                    : data[0].option_length_intersect;
                const length =
                  data[0].fio_list.split(",").length != 0
                    ? data[0].fio_list.split(",").length
                    : 1;
                const lexOptionDiscount = option_length
                  ? option_length / length
                  : 1;

                this.appState.setState({
                  lexOptionCode: data[0].fio_list,
                  lexOptionDiscount: lexOptionDiscount,
                });
              } else {
                this.appState.setState({
                  lexOptionCode: state?.lexSelectedoptionCode.join(","),
                  lexOptionDiscount: 1,
                });
              }
            },
            (error) => {
              this.loading = true;
            }
          );
      });
  }

  onClick() {
    this.validDealer = false;
    this.selectedDealer = null;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((state) => (state ? this._filter(state) : this.dealer_list.slice()))
    );
  }

  navigateToFindCar(event) {
    this.appState.setState({
      lexSelectedDealerCode: this.dealer_code,
      lexSelectedDealerRadii: this.radii,
    });
    event.stopPropagation();
    this.router.navigate(["lexus", "findmycar"]);
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();

    return this.dealer_list.filter(
      (state) =>
        state.name.toLowerCase().includes(filterValue) ||
        state.dealer_number.toLowerCase().includes(filterValue)
    );
  }
  displayFn(rowInfo: any): string {
    if (rowInfo && rowInfo?.name)
      return rowInfo.dealer_number + " " + rowInfo?.name;
    else return null;
  }
  ngOnDestroy() {
    this.unsubscribe$.complete();
  }
}
