<div class="container">
  <!-- <div class="row text-center text-white mb-5">
    <div class="model-container">
      <div>
        <span style="margin-top: 12%; margin-left: 20%">
          <h1 class="display-4" style="margin-left: -100px">Select a series</h1>
        </span>
      </div>
    </div>
  </div> -->
  <div class="row text-center text-white mt-4 mb-4">
    <div class="vehicle-container">
      <h1 style="font-weight: 500" >
        All Toyota Vehicles
      </h1>
      <h1 class="display-4">Select a Series</h1>
    </div>
  </div>
  <div class="tc-dropdown">
    <button class="tc-dropbtn">{{this.category}}</button>
    <div class="tc-dropdown-content">
      <button 
      [ngClass]="{ active: this.category === 'Cars & Minivan' }"
      (click)="openSeriesCategory('Cars & Minivan')">Cars & Minivan</button>
      <button
      [ngClass]="{ active: this.category === 'Trucks' }"
      (click)="openSeriesCategory('Trucks')">Trucks</button>
      <button
      [ngClass]="{ active: this.category === 'Crossovers & SUVs' }"
      (click)="openSeriesCategory('Crossovers & SUVs')">Crossovers & SUVs</button>
      
      <button
      [ngClass]="{ active: this.category === 'Electrified' }"
      (click)="openSeriesCategory('Electrified')">Electrified</button>
      
    </div>
  </div>
  <div class="tab tc-tab">
    <button
      [ngClass]="{ active: this.category === 'Cars & Minivan' }"
      class="tablinks"
      (click)="openSeriesCategory('Cars & Minivan')"
    >
      Cars & Minivan
    </button>
    <button
      [ngClass]="{ active: this.category === 'Trucks' }"
      class="tablinks"
      (click)="openSeriesCategory('Trucks')"
    >
      Trucks
    </button>
    <button
      [ngClass]="{ active: this.category === 'Crossovers & SUVs' }"
      class="tablinks"
      (click)="openSeriesCategory('Crossovers & SUVs')"
    >
      Crossovers & SUVs
    </button>
    <button
      [ngClass]="{ active: this.category === 'Electrified' }"
      class="tablinks"
      (click)="openSeriesCategory('Electrified')"
    >
      Electrified
    </button>
  </div>
  <div style="padding-top: 5px; ">
    Vehicles on this page are for illustrative purposes only.
  </div>
  <div [hidden]="category != 'Crossovers & SUVs'">
    <div class="model-info-p">
      <div class="row">
        <div [ngClass]="gridClass" *ngFor="let data of crossover">
          <dsmds-vehicle-card
            [data]="data" 
            [category]='category'
            [selectedModel]="selectedModel"
            (openDialogFromChild)="openDialog($event)">
          </dsmds-vehicle-card>
        </div>
      </div>
      
    </div>
  </div>

  <div [hidden]="category != 'Cars & Minivan'">
    <div class="model-info-p">

      <div class="row">
        <div [ngClass]="gridClass" *ngFor="let data of minivan">
          <dsmds-vehicle-card
            [data]="data" 
            [category]='category'
            [selectedModel]="selectedModel"
            (openDialogFromChild)="openDialog($event)">
          </dsmds-vehicle-card>
        </div>
      </div>
      
    </div>
  </div>
  
  <div [hidden]="category != 'Electrified'">
    <div class="model-info-p">

      <div class="row">
        <div [ngClass]="gridClass" *ngFor="let data of electrified">
          <dsmds-vehicle-card
            [data]="data" 
            [category]='category'
            [selectedModel]="selectedModel"
            (openDialogFromChild)="openDialog($event)">
          </dsmds-vehicle-card>
        </div>
      </div>
      
    </div>
  </div>

  <div [hidden]="category != 'Trucks'">
    <div class="model-info-p">

      <div class="row">
        <div [ngClass]="gridClass" *ngFor="let data of truck">
          <dsmds-vehicle-card
            [data]="data" 
            [category]='category'
            [selectedModel]="selectedModel"
            (openDialogFromChild)="openDialog($event)">
          </dsmds-vehicle-card>
        </div>
      </div>
      
    </div>
  </div>
</div>
