<mat-dialog-content>
  <h1 mat-dialog-title>{{ modalTitle }}</h1>
  <div
    class="justify-content-center"
    style="position: relative"
    [hidden]="!isLoading"
  >
    <div
      class="spinner-border t-loader t-loader-sm"
      role="status"
      style="position: absolute; width: 100%"
    >
      <span
        [ngClass]="{
          'sr-only': true,
          't-loader-spinner': true,
          't-loader-sm': true,
          'l-loader': brand !== 'T'
        }"
      ></span>
    </div>
  </div>
  <div mat-dialog-content>
    <span
      style="font-weight: 500"
      [hidden]="modalFor === 'vspec' || within_dealer_vin.length <= 0"
      >Within Dealer</span
    >

    <div [hidden]="within_dealer_vin.length <= 0" id="withindealer-1">
      <ul>
        <li *ngFor="let vin of within_dealer_vin" class="mb-2">
          <div class="d-flex v-link">
            {{ vin.vin }}
            <mat-icon
              class="copy-icon"
              [class.lxs]="brand !== 'T'"
              *ngIf="modalFor !== 'vspec'"
              alt="copy vin to clipboard"
              (click)="copyToClipBoard(vin?.vin)"
            ></mat-icon>
            <span
              title="View v-spec"
              *ngIf="modalFor === 'vspec'"
              (click)="modalFor === 'vspec' && onNavigateToVSpec(vin?.vin)"
            >
              <mat-icon class="t-icon" [class.lxs]="brand !== 'T'"
                >open_in_new</mat-icon
              >
            </span>
          </div>
          <div [hidden]="!vin?.vinEta">[{{ vin?.vinEta }}]</div>
        </li>
      </ul>
    </div>

    <span
      style="font-weight: 500"
      [hidden]="modalFor === 'vspec' || outside_dealer_vin.length <= 0"
    >
      Outside Dealer</span
    >

    <div [hidden]="outside_dealer_vin.length <= 0">
      <ul>
        <li *ngFor="let vin of outside_dealer_vin" class="mb-2">
          <div
            class="d-flex v-link"
            (click)="
              modalFor === 'vspec' && onNavigateToVSpec(vin?.vin_compare)
            "
          >
            {{ vin.vin_compare }}
            <mat-icon
              class="copy-icon"
              [class.lxs]="brand !== 'T'"
              *ngIf="modalFor !== 'vspec'"
              alt="copy vin to clipboard"
              (click)="copyToClipBoard(vin?.vin_compare)"
            ></mat-icon>
            <span title="View v-spec" *ngIf="modalFor === 'vspec'">
              <mat-icon class="t-icon" [class.lxs]="brand !== 'T'"
                >open_in_new</mat-icon
              >
            </span>
          </div>
          <div [hidden]="!vin?.vinEta">[{{ vin?.vinEta }}]</div>
        </li>
      </ul>
    </div>
  </div>
  <br />
  <br />
  <div style="text-align: center">
    <button mat-button class="close" [mat-dialog-close]="true">close</button>
  </div>
</mat-dialog-content>
