import { HttpErrorResponse } from "@angular/common/http";

export const getToyotaDisplayHeader = (states ): string => {
    let headersArr = []
    let contn = 0;
    if(states.hasOwnProperty('series') && states.series !==null){
        headersArr.push(states.series);
        contn = 1
    }
    if(contn === 1 && states.hasOwnProperty('grade') && states.grade !==null){
        headersArr.push(states.grade?.title)
        contn = 2
    }
    if(contn === 2 && states.hasOwnProperty('grade_attributes') && states.grade_attributes !==null){
        headersArr.push(states.grade_attributes?.engine_title)
        contn = 3
    }
    if(contn === 3 && states.hasOwnProperty('exterior_color') && states.exterior_color !==null){
        headersArr.push('in')
        headersArr.push(states.exterior_color?.color_name.replace(
            "[extra_cost_color]",
            ""
          ))
        contn = 4
    }
    if(contn === 4 && states.hasOwnProperty('interior_color') && states.interior_color !==null){
        headersArr.push('with')
        headersArr.push(formatColorName(states.interior_color?.color_name))
        contn = 5
    }
    return headersArr.join(' ')
}
export const formatColorName= (colorName) => {
    return colorName
      ?.replace(/SofTex®|Softex®|SofTex®&nbsp;/g, "SofTex")
      .split("[")[0];
}
export const errorStatusCode=(err) => {
    // let errMsg = err.error;

    // err.status ? `Status: ${err.status} - Text: ${err.statusText}` : "Server error";

    // console.log(err.status);
    console.log('.................error',err);
      let errMessage='';
     if (err.status === 500) {
     
         errMessage="500 Internal Server Error.Try after sometime.";
   
       }
   
       else if (err.status === 404) {
   
         errMessage="404.Sorry this URL does not exist or no longer available";
   
       }
       else if (err.status === 503) {
   
         errMessage="Service Unavailable.Try after sometime.";
   
       }
       else if (err.status === 403) {
   
         errMessage="403 Forbidden error.Require authorization.";
   
       }
 
   
       else {
         errMessage="400 ERROR. The URL accessed is invalid, please enter the correct URL.";
        
   
       }
       //  handle500Error = (err: HttpErrorResponse) => {
 
       //   this.createErrorMessage(err);
     
       //   this.router.navigate(['/500']);
     
       // }
     
       // private handle404Error = (error: HttpErrorResponse) => {
     
       //   this.createErrorMessage(error);
     
       //   this.router.navigate(['/404']);
     
       // }
     
       // private handleOtherError = (error: HttpErrorResponse) => {
     
       //   this.createErrorMessage(error); //TODO: this will be fixed later;
     
       // }
     
       // private createErrorMessage = (error: HttpErrorResponse) => {
     
       //   this.errorMessage = error.error ? error.error : error.statusText;
     
       // }
       
     
       
       return errMessage;
  }