<button mat-button class="close-icon" [mat-dialog-close]="true">X</button>
<h3
  style="font-weight: 700"
  class="font-italic text-muted mb-0 small"
  [innerHtml]="
    formatText(option_data?.title) +
    ' [' +
    option_data?.code +
    ']' +
    '  $' +
    formatPrice(option_data?.price)
  "
></h3>

<h3 [innerHTML]="formatText(option_data?.description)"></h3>
