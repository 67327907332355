const FEATURECATEGORY = [
  {
    type: "grade_spec_name_compare",
    value: "none",
  },
  {
    type: "drivetrain_name_compare",
    value: "none",
  },
  {
    type: "interior_color_name_compare",
    value: "none",
  },
  {
    type: "exterior_color_name_compare",
    value: "none",
  },
  {
    type: "fio_list_compare",
    value: [],
  },
  {
    type: "model_number_compare",
    value: "none",
  },
  {
    type: "model_year_compare",
    value: "none",
  },
  {
    type: "toms_series_name_compare",
    value: "none",
  },
  {
    type: "wait_days_min",
    value: 100,
  },
];

const ETACATEGORY = [
  {
    // Max ETA value
    value: 100,
    displayValue: "All",
  },
  {
    value: 99,
    displayValue: 'Available Now'
  },
  {
    value: 1,
    displayValue: "0-2 weeks",
  },
  {
    value: 3,
    displayValue: "0-4 weeks",
  },
  {
    value: 5,
    displayValue: "0-6 weeks",
  },
  {
    value: 7,
    displayValue: "0-8 weeks",
  },
];

const YEARCATEGORYCURRENT = [
  {
    value: "none",
    displayValue: "All",
  },
  {
    value: new Date().getFullYear(),
    displayValue: new Date().getFullYear(),
  },
];

const YEARCATEGORY = [
  {
    value: "none",
    displayValue: "All",
  },
  {
    value: new Date().getFullYear(),
    displayValue: new Date().getFullYear(),
  },
  {
    value: new Date().getFullYear() + 1,
    displayValue: new Date().getFullYear() + 1,
  },
];
export { FEATURECATEGORY, ETACATEGORY, YEARCATEGORYCURRENT, YEARCATEGORY };
