import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { VehicleCardComponent } from './components/vehicle-card/vehicle-card.component';
import { RouterModule, Routes } from "@angular/router";
import { VehicleCardGradeComponent } from './components/vehicle-card-grade/vehicle-card-grade.component';
import { VehicleCardPowertrainComponent } from './components/vehicle-card-powertrain/vehicle-card-powertrain.component';
import { ButtonComponent } from './ui-components/button/button.component';
import { ButtonLexusComponent } from './ui-components/button-lexus/button-lexus.component';
import { ButtonRoundComponent } from './ui-components/button-round/button-round.component';
import { LogoIconComponent } from './assets/logo-icon/logo-icon.component';
import { LogoSmallIconComponent } from './assets/logo-small-icon/logo-small-icon.component';
import { StepsNavigationsComponent } from './components/steps-navigations/steps-navigations.component';
import { StepsNavigationsLexusComponent } from './components/steps-navigations-lexus/steps-navigations-lexus.component';


@NgModule({
  declarations: [
    VehicleCardComponent,
    VehicleCardGradeComponent,
    VehicleCardPowertrainComponent,
    ButtonComponent,
    ButtonLexusComponent,
    ButtonRoundComponent,
    LogoIconComponent,
    LogoSmallIconComponent,
    StepsNavigationsComponent,
    StepsNavigationsLexusComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTabsModule
  ],
  exports: [
    VehicleCardComponent,
    VehicleCardGradeComponent,
    VehicleCardPowertrainComponent,
    ButtonComponent,
    ButtonLexusComponent,
    ButtonRoundComponent,
    LogoIconComponent,
    LogoSmallIconComponent,
    StepsNavigationsComponent,
    StepsNavigationsLexusComponent
  ]
})
export class SharedModule { }
