import { Injectable } from "@angular/core";

import {
  getVspecUrls,
  getBaseApiEndpoint,
  getModelBaseApiEndpoint,
  getAnalyticsApiEndpoint,
  getToyotaBaseEndPoint,
  getVampisBaseEndpoint,
  getLexusBaseEndPoint,
  getFindCarsEndpoint,
} from "../utils";

// date range for series
const dateRange =
  (new Date().getFullYear() + 1).toString() +
  "," +
  new Date().getFullYear().toString();

@Injectable({ providedIn: "root" })
export class ApiRouteService {
  getRoutes() {
    return {
      ANALYTICS: {
        USER_LOGIN: () => `${getAnalyticsApiEndpoint()}/analytics/user/login`,
        USER_LOGOUT: () => `${getAnalyticsApiEndpoint()}/analytics/user/logout`,
        USERS_SESSIONS_ALL: () =>
          `${getAnalyticsApiEndpoint()}/analytics/user/sessions/all`,
        USERS_SESSIONS: () =>
          `${getAnalyticsApiEndpoint()}/analytics/user/sessions`,
        SEED_SAVE: () => `${getAnalyticsApiEndpoint()}/analytics/seed/save`,
        SEED_ALL: () => `${getAnalyticsApiEndpoint()}/analytics/seed/all`,
      },
      FINDMYCAR: {
        UPDATE: () => `${getFindCarsEndpoint()}/findcars`,
        // In future remove code
        GETMODEL: (modelNumber) =>
          `${getModelBaseApiEndpoint()}/getmodelinfo?model_year=` +
          new Date().getFullYear().toString() +
          `&model_number=${modelNumber}`,
        GETEXTERIOR: (modelNumber) =>
          `${getModelBaseApiEndpoint()}/getexteriorcolor?model_year=` +
          new Date().getFullYear().toString() +
          `&model_number=${modelNumber}`,
        GETINTERIOR: (modelNumber) =>
          `${getModelBaseApiEndpoint()}/getinteriorcolor?model_year=` +
          new Date().getFullYear().toString() +
          `&model_number=${modelNumber}`,
        GETOPTIONS: (modelNumber, year) =>
          `${getModelBaseApiEndpoint()}/getoptions?model_year=` +
          year +
          `&model_number=${modelNumber}`,
        GETCONFLICT: (modelNumber, year) =>
          `${getModelBaseApiEndpoint()}/getoptionconflict?model_year=` +
          year +
          `&model_number=${modelNumber}`,
        GETREQUIREMENT: (modelNumber, year) =>
          `${getModelBaseApiEndpoint()}/getoptionrequirement?model_year=` +
          year +
          `&model_number=${modelNumber}`,
        GETDEALER: (dealerCode) =>
          `${getModelBaseApiEndpoint()}/validatedealercode?dealercode=${dealerCode}`,

        GETAPIKEY: () => `${getModelBaseApiEndpoint()}/getapikey`,
        GETVALIDDEALERS: () => `${getModelBaseApiEndpoint()}/dealers`,

        GETVALIDOPTION: (modelNumber, year, fiolist) =>
          `${getModelBaseApiEndpoint()}/getvalidoption?model_year=` +
          year +
          `&model_number=${modelNumber}` +
          `&optionlist=${fiolist}`,
      },
      TOYOTA: {
        GETTRIM: (type) =>
          `${getToyotaBaseEndPoint()}/vehiclecontent/v2/` +
          type +
          `/NATIONAL/EN/trims?&attributes=trims,price,title&year=` +
          dateRange,
        GETLEXUSIMGE: (series, year, appKey) =>
          `${getLexusBaseEndPoint()}/config/services/configuration/3d/getImages?brand=LEX&config=base&region=NATIONAL&year=` +
          year +
          `&series=` +
          series +
          `&language=EN&appkey=${appKey}`,
        GETTOYOTAIMGE: (series, year, appKey) =>
          `${getLexusBaseEndPoint()}/config/services/configuration/3d/getImages?brand=TOY&config=base&region=NATIONAL&year=` +
          year +
          `&series=` +
          series +
          `&language=EN&appkey=${appKey}`,
        GET: (type) =>
          `${getVampisBaseEndpoint()}/query/availableSeries/` +
          type +
          `/NATIONAL/EN?years=` +
          dateRange,
        GETSERIES: (type, seriesName, year) =>
          `${getToyotaBaseEndPoint()}/vehiclecontent/v1/` +
          type +
          `/NATIONAL/EN/all?year=` +
          year +
          `&series=${seriesName}`,

        GETMODEL: (type) =>
          `${getToyotaBaseEndPoint()}/vehiclecontent/v1/` +
          type +
          `/NATIONAL/EN/series?year=` +
          dateRange,
        GETIMAGE: (type) =>
          `${getToyotaBaseEndPoint()}/vehiclecontent/v2/` +
          type +
          `/NATIONAL/EN/colors?attributes=exterior&year=` +
          dateRange,
      },
      VSPEC: {
        GETHASHKEY: () =>
          `${
            getVspecUrls()["apiBase"]
          }/token-service?tokenName=vspec&systemName=System-OTD`,
      },
    };
  }
}
