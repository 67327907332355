import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: "dsmds-option-filter",
  templateUrl: "./option-filter.component.html",
  styleUrls: ["./option-filter.component.css"],
})
export class OptionFilterComponent implements OnInit {
  option_data: any = [];
  select_option: any = [];
  optArray: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<OptionFilterComponent>
  ) {
    data.option_data?.forEach((code) => {
      this.option_data.push({
        code: code,
        checked: data.selected_options.includes(code) ? true : false,
      });
    });
  }

  onChange(opt, event) {
    if (event.target.checked) {
      opt.checked = true;
      this.optArray.push(opt.code.trim());
    } else {
      opt.checked = false;
      let index = this.optArray.findIndex((x) => x.value == opt.code);
      this.optArray.splice(index, 5);
    }
  }

  close() {
    this.dialogRef.close({
      data: this.option_data
        .filter((x) => x.checked === true)
        .map(function (obj) {
          return obj.code;
        }),
    });
  }

  ngOnInit(): void {}
}
