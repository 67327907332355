import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, take } from "rxjs";
import { ApiRouteService } from "../config/api-routes";
import { UiStateService } from "./ui-state.service";

@Injectable({
  providedIn: "root",
})
export class FindMyCarService {
  public data: Observable<Object> = new Observable<Object>();
  apikey: string;
  efc_apikey: string;
  vami_apiKey: string;
  analytics_apiKey: string;
  constructor(
    private http: HttpClient,
    private apiRouteService: ApiRouteService,
    private appState: UiStateService
  ) {}

  updateCarDetails(
    year: string,
    number: string,
    dealercode: string,
    miles: number,
    exteriorColor: string,
    interiorColor: string,
    packages: string,
    msrp: number = null,
    type: string = null,
    option_discount: number = null
  ): Observable<any> {
    const reqBody = {
      model_year: year,
      model_number: number,
      dealercode: dealercode,
      distance_miles: miles,
      exterior_color: exteriorColor,
      interior_color: interiorColor,
      option_packages: packages,
      msrp: msrp,
      type: type,
      option_discount: option_discount,
    };
    const body = JSON.stringify(reqBody);
    this.getApiStateKey();
    //let headers = new HttpHeaders().set("x-api-key", this.apikey);
    let headers = new HttpHeaders().set("x-api-key", this.apikey);

    return this.http.post(
      this.apiRouteService.getRoutes().FINDMYCAR.UPDATE(),
      body,
      { headers: headers }
    );
  }

  getSeriesDetials(
    type: string,
    seriesName: string,
    year: string
  ): Observable<any> {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.efc_apikey);
    return this.http.get(
      this.apiRouteService.getRoutes().TOYOTA.GETSERIES(type, seriesName, year),
      { headers: headers }
    );
  }

  getModelImage(type): Observable<any> {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.efc_apikey);
    return this.http.get(
      this.apiRouteService.getRoutes().TOYOTA.GETIMAGE(type),
      { headers: headers }
    );
  }
  getLexusImage(code, year?: string): Observable<any> {
    this.getApiStateKey();
    return this.http.get(
      this.apiRouteService
        .getRoutes()
        .TOYOTA.GETLEXUSIMGE(code, year, this.efc_apikey)
    );
  }
  getToyotaImage(code, year): Observable<any> {
    return this.http.get(
      this.apiRouteService
        .getRoutes()
        .TOYOTA.GETTOYOTAIMGE(code, year, this.efc_apikey)
    );
  }
  getToyotaApi(type) {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.vami_apiKey);
    return this.http.get(this.apiRouteService.getRoutes().TOYOTA.GET(type), {
      headers: headers,
    });
  }
  getToyotaSeriesApi(type) {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.efc_apikey);
    return this.http.get(
      this.apiRouteService.getRoutes().TOYOTA.GETMODEL(type),
      { headers: headers }
    );
  }
  getTrimInfo(type) {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.efc_apikey);
    return this.http.get(
      this.apiRouteService.getRoutes().TOYOTA.GETTRIM(type),
      { headers: headers }
    );
  }
  getModelInfo(modelNumber): Observable<any> {
    this.getApiStateKey();
    // let headers = new HttpHeaders().set("x-api-key", this.apikey);
    let headers = new HttpHeaders().set("x-api-key", this.apikey);

    return this.http.get(
      this.apiRouteService.getRoutes().FINDMYCAR.GETMODEL(modelNumber),
      { headers: headers }
    );
  }
  getExteriorColor(modelNumber): Observable<any> {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.apikey);
    return this.http.get(
      this.apiRouteService.getRoutes().FINDMYCAR.GETEXTERIOR(modelNumber),
      { headers: headers }
    );
  }
  getInteriorColor(modelNumber): Observable<any> {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.apikey);
    return this.http.get(
      this.apiRouteService.getRoutes().FINDMYCAR.GETINTERIOR(modelNumber),
      { headers: headers }
    );
  }
  getOptionPackage(modelNumber, year): Observable<any> {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.apikey);
    return this.http.get(
      this.apiRouteService.getRoutes().FINDMYCAR.GETOPTIONS(modelNumber, year),
      { headers: headers }
    );
  }
  getDealerList(): Observable<any> {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.apikey);
    return this.http.get(
      this.apiRouteService.getRoutes().FINDMYCAR.GETVALIDDEALERS(),
      { headers: headers }
    );
  }

  getOptionRequirement(modelNumber, year): Observable<any> {
    this.getApiStateKey();
    //let headers = new HttpHeaders().set("x-api-key", this.apikey);
    let headers = new HttpHeaders().set("x-api-key", this.apikey);

    return this.http.get(
      this.apiRouteService
        .getRoutes()
        .FINDMYCAR.GETREQUIREMENT(modelNumber, year),
      { headers: headers }
    );
  }
  getOptionConstrain(modelNumber, year): Observable<any> {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.apikey);
    return this.http.get(
      this.apiRouteService.getRoutes().FINDMYCAR.GETCONFLICT(modelNumber, year),
      { headers: headers }
    );
  }
  getValidOption(modelNumber, year, fiolist): Observable<any> {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.apikey);
    return this.http.get(
      this.apiRouteService
        .getRoutes()
        .FINDMYCAR.GETVALIDOPTION(modelNumber, year, fiolist),
      { headers: headers }
    );
  }
  validateDealerCode(dealerCode): Observable<any> {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.apikey);
    return this.http.get(
      this.apiRouteService.getRoutes().FINDMYCAR.GETDEALER(dealerCode),
      { headers: headers }
    );
  }

  getDealerInfo(url, token): Observable<any> {
    this.getApiStateKey();
    let headers = new HttpHeaders().set("x-api-key", this.apikey);
    return this.http.get(
      // "https://api.rti.toyota.com/dealer-info/dealers/14046",
      "https://api.rti.qa.toyota.com/dealer-inventory/vehicles/JTDKAMFU3N3169958/vspec",
      { headers: headers }
    );
  }

  getVspecHaskKey(): Observable<any> {
    return this.http.get(this.apiRouteService.getRoutes().VSPEC.GETHASHKEY());
  }

  getApiKey(): Observable<any> {
    let headers = new HttpHeaders();
    return this.http.get(
      this.apiRouteService.getRoutes().FINDMYCAR.GETAPIKEY(),
      { headers: headers }
    );
  }

  getApiStateKey() {
    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        if ("otd_api_key" in state) {
          this.apikey = state?.otd_api_key;
          this.efc_apikey = state?.efc_api_key;
          this.vami_apiKey = state?.vami_api_key;
          this.analytics_apiKey = state?.analytics_api_key;
        }
      });
  }
}
