<div class="container pb-5 ">
  <div class="row text-center text-white mt-4 mb-4">
    <div class="vehicle-container">
      <h1 style="font-weight: 500" >
        Your vehicle: <span style="font-weight: 400">{{ displayText }}</span>
      </h1>
      <h1 class="display-4">Select a Grade</h1>
    </div>
  </div>
  <div class="dsmds-steps-navigations">
    <dsmds-steps-navigations [tabnumber]="1"></dsmds-steps-navigations>
  </div>
  <div style="padding-top: 5px; ">
    Vehicles on this page are for illustrative purposes only.
  </div>
  <div class="trim-n">
    <div class="row">
      <div [ngClass]="gridClass" *ngFor="let data of featureData">
        <dsmds-vehicle-card-grade 
        [data]="data" 
        [displayText]="displayText" 
        [selectedGrade]="selectedGrade" 
        (openDialogFromChild)="openDialog($event)"></dsmds-vehicle-card-grade>
      </div>
    </div>
  </div>
</div>
<div class="handling"> <h1 >{{errMessage}}</h1></div>