import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { range } from "lodash";
import { take } from "rxjs";
import { UiStateService } from "src/app/service/ui-state.service";
import { OptionRequirementComponent } from "../../shared/option-requirement/option-requirement.component";
import * as _ from "lodash";
import {
  LEXUS_BASE_URL,
  TOYOTA_BASE_URL,
} from "src/app/constants/imagebase.constants";
import { Console } from "console";

@Component({
  selector: "dsmds-lexus-interior",
  templateUrl: "./lexus-interior.component.html",
  styleUrls: ["./lexus-interior.component.css"],
})
export class LexusInteriorComponent implements OnInit {
  interior_color: any = [];
  interior_code: any = [];
  interior_code_selected: any;
  interiorImageResult: any = {};
  options: any = [];
  lexDisplayText: any;
  requirementinfo: any;
  color_combination: any;
  code: any;
  extCode: any;
  interiordata: any = {};
  lexGradeCodeSelected: any = null;
  intlexcode: any = null;
  intcolourselected: any = null;
  loading = false;
  required_options_code: boolean = false;

  constructor(
    private appState: UiStateService,
    public activeRoute: ActivatedRoute,
    public dialog: MatDialog,
    public router: Router
  ) {
    /* Extract model no from route */
    this.code = this.activeRoute.snapshot.params["gradeCode"];
    this.interiordata["gradeCode"] = this.code;
    this.appState
      ?.getState()
      .pipe(take(1))
      .subscribe((state) => {
        // if(state && state.hasOwnProperty('lexSeriesTitle')){
        //   this.modelNumber = state.lexSeriesTitle;
        //   this.year = state.lexYear;
        // }
        // else{
        //   this.router.navigate(["/"]);
        // }
      });
  }

  /* Extract interior color information and displaytext based on previously
    stored series data */
  ngOnInit(): void {
    //console.log('............appstate',this.appState.getState());
    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        //console.log('............4500appstate',state);

        if (state.lexSeriesData === undefined) {
          this.router.navigate(["/"]);
        }
        this.lexDisplayText =
          state.lexGradeTitle +
          " " +
          state.lexDriveTitle +
          " IN " +
          state.lexDisplayExterior;
        const interior_color = state.lexSeriesData?.colors?.interior;
        const combination = state.lexRequirementInfo;
        const extCode = state?.lexSelectedExterior?.codeExt;
        this.requirementinfo = state.lexRequirementInfo;
        this.color_combination = state.lexSeriesData?.color_combinations;
        if (
          state.hasOwnProperty("lexRequiredOptions") &&
          state?.lexRequiredOptions
        ) {
          this.required_options_code = true;
        }
        const icolor_combination = state?.lexIntImageData;
        this.getImage(icolor_combination);
        this.extCode = extCode;
        // console.log("1234-combination", combination)
        // console.log("1234-extCode", extCode)
        // console.log("1234-interior_color", interior_color)
        // console.log("1234-options", this.interiorImageResult)
        this.getCombination(combination, extCode);
        this.getInteriorColor(interior_color);
        this.getOptionData(state.lexSeriesData.options);
        this.options = this.options.filter((x) => x.status == "LIVE");
        this.interior_color = this.interior_color.filter(
          (x) =>
            x.status == "LIVE" &&
            (this.interior_code.includes(x.code) ||
              this.interior_code.includes(x.codeInt)) &&
            x.type == "interiorcolor"
        );
        this.interior_code_selected = this.interior_color[0];
        this.appState
          ?.getState()
          .pipe(take(1))
          .subscribe((state) => {
            if (
              state &&
              state.hasOwnProperty("lexSelectedInterior") &&
              state.lexSelectedInterior
            ) {
              this.interior_code_selected = state.lexSelectedInterior;
              //this.intcolourselected = state.lexSelectedInterior;
              this.intlexcode = this.interior_code_selected?.code;
              let temp = this.interior_color.filter(
                (x) => x.code == this.intlexcode
              );
              this.interior_code_selected = temp[0];
            } else {
              this.interior_code_selected = this.interior_color[0];
              this.intlexcode = this.interior_code_selected?.code;
              let temp = this.interior_color.filter(
                (x) => x.code == this.intlexcode
              );
              this.interior_code_selected = temp[0];
            }
          });
        this.loading = true;
      });
  }
  getImage(ext_img) {
    let model = _.values(ext_img).flat();
    model.forEach((element) => {
      this.interiorImageResult[element?.code] =
        LEXUS_BASE_URL + element?.["3dimage"]?.["700_645_PNG"]["1"]?.[0];
    });
  }

  getInteriorColor(interior_color) {
    for (let i = 0; i < interior_color.length; i++) {
      let applicable = interior_color[i].applicable;
      for (let j = 0; j < applicable?.length; j++) {
        if (applicable[j].trim == this.code) {
          this.interior_color.push({
            type: interior_color[i].type,
            code: interior_color[i].code,
            codeInt: interior_color[i].code.slice(-2),
            title: interior_color[i].title,
            image:
              LEXUS_BASE_URL + applicable[j]?.override?.images?.on_swatch_image,
            status: interior_color[i]?.visibility?.status?.status_code,
          });
        }
      }
    }
  }
  navigateToPkg(event) {
    event.stopPropagation();
    if (this.required_options_code) {
      this.appState.setState({
        lexSelectedoptionCode: [],
      });
      this.router.navigate(["lexus", "packages", this.code]);
    } else {
      // pop up
      if (this.interior_code_selected) {
        this.getOption(this.interior_code_selected);
      }
      //this.router.navigate(["lexus", "packages", this.interior_code_selected]);
    }
  }

  /* Extract interior color information based on exterior color */
  getCombination(combination, extCode) {
    /* Extract interior color information based on exterior color 
      lead code in option requirement data */
    let intCode = [];
    combination
      .filter(
        (x) =>
          x.item_type_indicator == "I" &&
          x.lead_item_type_indicator == "E" &&
          x.lead_item_code == extCode
      )
      .map(function (obj) {
        intCode.push(obj.item_code);
      });
    intCode = intCode.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    });
    /* If there are no combinations in option requirement get 
      interior color bases on series color combination data */
    if (intCode.length == 0) {
      if (extCode.length == 3) {
        extCode = "0" + extCode;
      }
      for (let i = 0; i < this.color_combination?.length; i++) {
        if (
          this.color_combination[i].applicable.trim == this.code &&
          this.color_combination[i]?.exterior?.code == extCode
        ) {
          this.interior_code.push(this.color_combination[i]?.interior?.code);
        }
      }
    } else {
      this.interior_code = intCode;
    }
  }

  formatText(text) {
    if (text) {
      return text.replace(/ *\[[^\]]*]/g, "").toUpperCase();
    }
  }

  /* Extract option information based on exterior color and interior color */
  getOption(intcode) {
    console.log("intcode", intcode);
    this.interior_code_selected = intcode;
    this.intlexcode = intcode?.code;
    let opt_code_final = [];
    let opt_data = [];
    /* Extract all codes that have E and I as lead item code and item code */
    const opt_req = this.requirementinfo
      ?.filter(
        (x) =>
          x.item_type_indicator == "I" &&
          x.lead_item_type_indicator == "E" &&
          x.item_code == intcode.codeInt &&
          x.lead_item_code == this.extCode
      )
      .map(function (obj) {
        return obj.requirement_id;
      });
    /* Based on the option code filter all items that have O as indicator and 
       get all option codes */
    let opt_code = [];
    opt_req?.forEach((element) => {
      opt_code.push(
        this.requirementinfo
          .filter(
            (x) => x.requirement_id == element && x.item_type_indicator == "O"
          )
          .map(function (obj) {
            return obj.item_code;
          })
      );
    });

    opt_code_final = this.getOptionCombination(opt_code, intcode);

    if (opt_code_final.length == 0 && opt_code.length > 0) {
      opt_code_final.push(opt_code);
    }
    /* Get unique option combination */
    opt_code_final = _.uniqWith(opt_code_final, _.isEqual);
    /* Get information corresponding to each option code */
    opt_code_final.forEach((element) => {
      opt_data.push(this.options.filter((x) => element.includes(x.code)));
    });

    opt_data = _.uniqWith(opt_data, _.isEqual);

    opt_data = opt_data.filter((x) => x.length > 0);
    /* Open option requirement popup with list of option codes IF options list exist*/
    if (opt_data.length > 0) {
      const dialogRef = this.dialog.open(OptionRequirementComponent, {
        data: {
          option_data: opt_data,
          selected_interior: intcode,
          code: this.code,
        },
      });
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp && resp?.data) {
          this.required_options_code = true;
          if (resp?.next && resp?.next === "Yes") {
            this.appState.setState({
              lexSelectedoptionCode: [],
            });
            this.router.navigate(["lexus", "packages", this.code]);
          }
        }
      });
    }
    //no options available: set app state appropriately and wait for 'next step' click
    else {
      this.required_options_code = true;

      this.appState.setState({
        lexSelectedInterior: intcode,
        lexintcode: intcode.code,
        lexRequiredOptions: this.code,
        lexSelectedoptionCode: [],
      });
    }
  }

  getOptionCombination(opt_code, intcode) {
    let opt_code_final = [];
    let checker = (arr, target) => target.every((v) => arr.includes(v));
    /* Iterate over each option code and find option code related to each code */
    opt_code.forEach((element) => {
      if (element.length > 0) {
        element.forEach((i) => {
          const opt_req = this.requirementinfo
            .filter(
              (x) =>
                x.lead_item_type_indicator == "O" &&
                x.lead_item_code == i.trim()
            )
            .map(function (obj) {
              return obj;
            });

          if (opt_req.length > 0) {
            /* Group by requirement id to identify option code dependency */
            const code_comb = _.chain(opt_req)
              .groupBy("requirement_id")
              .map((value, key) => ({ title: key, item: value }))
              .value();
            code_comb.forEach((x) => {
              const option_ei = x.item
                .filter(
                  (x) =>
                    x.item_type_indicator == "E" ||
                    (x.item_type_indicator == "I" &&
                      x.item_code != intcode.codeInt)
                )
                .map(function (obj) {
                  return obj.item_code;
                });
              if (option_ei.length == 0) {
                const op = [];
                x.item.forEach((y) => {
                  op.push(y.item_code);
                });
                op.push(x.item[0].lead_item_code);

                if (checker(op, element)) {
                  opt_code_final.push(op.sort());
                }
              }
            });
          }
        });
      }
    });
    return opt_code_final;
  }

  getOptionData(options) {
    for (let i = 0; i < options.length; i++) {
      let applicable = options[i].applicable;
      for (let j = 0; j < applicable.length; j++) {
        if (applicable[j].trim == this.code && options[i].type == "FIO") {
          this.options.push({
            code: options[i]?.code,
            title: options[i]?.title,
            description: options[i]?.description,
            price: applicable[j]?.override?.price?.msrp
              ? applicable[j]?.override?.price?.msrp
              : options[i]?.price?.msrp
              ? options[i]?.price?.msrp
              : 0,
            status: options[i]?.visibility?.status?.status_code,
          });
        }
      }
    }
  }
}
