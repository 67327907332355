<div class="justify-content-center" [hidden]="loading">
  <div class="spinner-border" role="status">
    <span class="sr-only" id="loading"></span>
  </div>
</div>
<div class="container pb-5" [hidden]="!loading">
  <div class="row text-center text-white mb-4 mt-4">
    <div class="vehicle-container">
      <h1 style="font-weight: 500">
        Your vehicle:
        <span style="font-weight: 400">{{ displayText }}</span>
      </h1>
      <h1 class="display-4">Select a Package</h1>
    </div>
  </div>
  <div class="dsmds-steps-navigations">
    <dsmds-steps-navigations [tabnumber]="5"></dsmds-steps-navigations>
  </div>

  <div class="tcom-card-group-wrapper-c row mt-3">
    <div
      class="col-lg-4 col-xl-3 row-cols-xxxl-5 col-md-6 col-12 mb-3"
      *ngFor="let data of option_list"
    >
      <div
        class="package"
        (click)="navigateToZipcode($event, data)"
        [class.package-active]="
          selectedPackage?.price == data?.price &&
          selectedPackage?.sortedOpt[0].title == data.sortedOpt[0].title &&
          selectedPackage?.sortedOpt[1]?.title == data.sortedOpt[1]?.title &&
          selectedPackage?.sortedOpt[2]?.title == data.sortedOpt[2]?.title &&
          selectedPackage?.sortedOpt.length == data.sortedOpt.length
        "
      >
        <div
          class="tcom-card-background"
          *ngIf="
            data.options_descriptions != undefined &&
            data.options_descriptions != null
          "
        ></div>
        <div
          class="tcom-card-content"
          style="text-align: left"
          id="{{ data.options_titles }}"
        >
          <div class="card-header">
            {{
              data.options_titles.length <= 2
                ? data.sortedOpt[0].title
                : data.sortedOpt[0].title + " with options"
            }}
          </div>
          <div class="tcom-package-card-msrp">
            <div class="card-price">
              {{ "$" + data.price }}
            </div>
            <div class="tcom-package-msrp-title">
              MSRP
              <sup style="cursor: pointer" (click)="openDialog('msrp')">*</sup>
            </div>
          </div>

          <ul class="tcom-card-bullets">
            <div *ngFor="let dataInn of data.sortedOpt; let i = index">
              <li *ngIf="dataInn">
                <h4 class="font-weight-bold my-2">
                  {{ formatText(dataInn.title) }}
                  {{ "    " + "[" + dataInn.optionList + "]" }}
                </h4>
              </li>
            </div>
          </ul>
          <div class="tcom-link" (click)="openPackagesDialog(data)">
            View All Details
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5" *ngIf="option_list && option_list.length == 0">
      No Package Available
    </div>
  </div>
</div>
<div class="handling">
  <h1>{{ errMessage }}</h1>
</div>
