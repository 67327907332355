import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { result } from "lodash";
import { DEALER_USER, ROUTES_TOYOTA } from "src/app/constants";
//import { FindMyCarService } from "../../../service/find-my-car.service";
import { UiStateService } from "../../../service/ui-state.service";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, Subject, take, takeUntil } from "rxjs";

import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-zip-code",
  templateUrl: "./zip-code.component.html",
  styleUrls: ["./zip-code.component.css"],
})
export class ZipCodeComponent implements OnInit {
  @ViewChild("slider3", { read: ElementRef })
  public sliderOne: ElementRef;
  list_dealer: any;
  message: string = "";
  radii: any = 0;
  validDealer: any;
  dealer_code: any;
  displayText: any;
  myControl = new FormControl();
  personalDetailsStep: FormGroup;
  unsubscribe$ = new Subject();
  dealer_list: any = [];
  filteredOptions: Observable<any[]>;
  constructor(private appState: UiStateService, public router: Router) {}

  ngAfterViewInit(): void {
    this.sliderOne.nativeElement.value = this.radii;
  }

  /* Validate Dealer based on user claim */
  validateDealerCode(e) {
    if (e == "") {
      this.message = "Invalid Code";
      this.validDealer = null;
    } else {
      let dealerCode = "";
      if (this.selectedDealer) {
        dealerCode = this.selectedDealer?.dealer_number;
      }
      if (dealerCode[0] !== "6") {
        //if (this.list_dealer.indexOf(dealerCode) !== -1) {
        this.message = "Valid Code";
        this.validDealer = true;
        this.dealer_code = dealerCode;
      } else {
        this.message = "Invalid Code";
        this.validDealer = false;
      }
    }
  }

  navigateToFindCar(event) {
    this.appState.setState({
      tySelectedDealerCode: this.dealer_code,
      tySelectedDealerRadii: this.radii,
    });
    event.stopPropagation();
    this.router.navigate(ROUTES_TOYOTA);
  }

  setVal(e) {
    this.radii = e?.target.value;
  }

  selectedDealer: any;
  selectedDealerType = "Toyota";
  ngOnInit(): void {
    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        if (state.toyotaOptionData === undefined) {
          this.router.navigate(ROUTES_TOYOTA.slice(0, 5));
        }
        let options_list = state.toyotaOptionData.options_list;
        this.displayText =
          state.displayText + " " + "[" + options_list.join(",") + "]";

        this.appState.setState({
          option_list: state.toyotaOptionData.options_list,
          option_msrp: state.toyotaOptionData.price,
        });

        this.dealer_list = state.dealer_list;
        if (state.current_dealer) {
          this.selectedDealer = state.current_dealer;
          //this.selectedDealer = state.current_dealer?.dealer_number
        }

        this.list_dealer = [state.claims?.dealer_number];
        if (state.claims?.user_type == DEALER_USER) {
          if (this.selectedDealer) {
            this.dealer_list = this.dealer_list.filter(
              (value) =>
                value.dealer_number == this.selectedDealer?.dealer_number
            );
          } else {
            this.dealer_list = [];
          }
        }
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(""),
          map((state) =>
            state ? this._filter(state) : this.dealer_list.slice()
          )
        );
      });
  }
  private _filter(value: string) {
    const filterValue = value.toLowerCase();

    return this.dealer_list.filter(
      (state) =>
        state.name.toLowerCase().includes(filterValue) ||
        state.dealer_number.toLowerCase().includes(filterValue)
    );
  }
  displayFn(rowInfo: any): string {
    if (rowInfo && rowInfo?.name)
      return rowInfo.dealer_number + " " + rowInfo?.name;
    else return null;
  }
  onClick() {
    this.validDealer = false;
    this.selectedDealer = null;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((state) => (state ? this._filter(state) : this.dealer_list.slice()))
    );
  }
}
