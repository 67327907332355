<div class="justify-content-center" [hidden]="loading">
  <div class="spinner-border" role="status">
    <span class="sr-only" id="loading"></span>
  </div>
</div>
<div class="container pt-3 pb-4" [hidden]="!loading">
  <div class="row text-center text-white mb-5">
    <div class="vehicle-container">
      <span style="margin-top: 5%; margin-left: 20%; text-align: center">
        <h1 class="container-heading">
          YOUR VEHICLE:
          <span style="font-weight: 500" [innerHTML]="lexDisplayText"></span>
        </h1>
      </span>
      <span style="text-align: center">
        <h1 class="display-4">SELECT A PACKAGE</h1>
      </span>
    </div>
  </div>
  <div class="dsmds-steps-navigations-lexus">
    <dsmds-steps-navigations-lexus [tabnumber]="4" [packagedata]="packagedata"></dsmds-steps-navigations-lexus>
  </div>

  <div class="row">
    <div class="col-md-7">
      <div class="imager">
        <img
          alt="Car Image"
          dsmdsImgFallback="assets/images/lexus-option-df.png"
          src="{{ selected_option?.images }}"
          class="img-fluid"
        />
      </div>
    </div>
    <div class="col-md-5">
      <div class="imager">
        <div class="options">
          <span
            style="
              margin-top: 12%;
              margin-left: 20%;
              margin-bottom: 2%;
              text-align: center;
            "
          >
            <h1 class="display-4">PACKAGES</h1>
          </span>
          <div class="trim">
            <div class="row" *ngFor="let data of package">
              <div class="col-12">
            
              <div class="trim-row" (click)="getImage(data)">
                <div class="col-md">
                  <div class="col-lg-4col-md-12 mb-4 mb-lg-0">
                    <div
                      class="bg-image hover-overlay ripple shadow-1-strong rounded"
                      data-ripple-color="light"
                    >
                      <div style="display: flex">
                        <div>
                          <h3
                            style="font-weight: 700"
                            class="font-italic text-muted mb-0 small"
                            [innerHtml]="
                              formatText(data?.title) + ' [' + data?.code + ']'
                            "
                          ></h3>
                        </div>
                        <div style="margin-left: auto; margin-right: 0">
                          <em class="arrow right" (click)="getOption(data)"></em>
                        </div>
                      </div>
                      <div style="display: flex">
                        <div class="font-italic text-muted mb-0 small">
                          <div class="card-price">
                            {{ "$" + formatPrice(data?.price)
                            }}<sup
                              *ngIf="data.price != 0"
                              style="cursor: pointer"
                              (click)="openDialog('lexus-msrp')"
                              >*</sup
                            >
                          </div>
                        </div>
                        <div
                          class="btnadd mt-2"
                          [hidden]="!data.add"
                          (click)="addPackage(data)"
                        >
                          ADD
                        </div>
                        <div
                          class="btnremove mt-2"
                          [hidden]="!data.remove"
                          (click)="removePackage(data)"
                        >
                          REMOVE
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <hr />
          <span style="margin-left: 20%; margin-bottom: 2%; text-align: center">
            <h1 class="display-4">INDIVIDUAL OPTIONS</h1>
          </span>
          <div class="trim">
            <div class="row" *ngFor="let data of options">
              <div class="col-12">
              <div class="trim-row" (click)="getImage(data)">
                <div class="col-md">
                  <div class="col-lg-4col-md-12 mb-4 mb-lg-0">
                    <div
                      class="bg-image hover-overlay ripple shadow-1-strong rounded"
                      data-ripple-color="light"
                    >
                      <div style="display: flex">
                        <div>
                          <h3
                            style="font-weight: 700"
                            class="font-italic text-muted mb-0 small"
                            [innerHtml]="
                              formatText(data?.title) + ' [' + data?.code + ']'
                            "
                          ></h3>
                        </div>
                        <div style="margin-left: auto; margin-right: 0">
                          <em class="arrow right" (click)="getOption(data)"></em>
                        </div>
                      </div>
                      <div style="display: flex">
                        <div class="font-italic text-muted mb-0 small">
                          <div class="card-price">
                            {{ "$" + formatPrice(data?.price)
                            }}<sup
                              *ngIf="data.price != 0"
                              style="cursor: pointer"
                              (click)="openDialog('lexus-msrp')"
                              >*</sup
                            >
                          </div>
                        </div>
                        <div
                          class="btnadd mt-2"
                          [hidden]="!data.add"
                          (click)="addPackage(data)"
                        >
                          ADD
                        </div>
                        <div
                          class="btnremove mt-2"
                          [hidden]="!data.remove"
                          (click)="removePackage(data)"
                        >
                          REMOVE
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  </div>
  <div class="moveButtons">
    <a (click)="navigateToZipcode($event,'nextstep')" class="next">NEXT STEP</a>
  </div>
</div>
