import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiRouteService } from "../../../config/api-routes";
import { ANALYTICS_OPTIONS } from "src/app/constants/analytics.constants";
import { AnalyticsService } from "src/app/service/analytics/analytics.service";
@Component({
  selector: "dsmds-analytics-modal",
  templateUrl: "./analytics-modal.component.html",
  styleUrls: ["./analytics-modal.component.css"],
})
export class AnalyticsModalComponent implements OnInit {
  brand = "";

  options = [];
  selected = 1;

  data = [];
  view: [number, number] = [700, 400];
  seedType = "";
  error = false;

  colorScheme = "cool";
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public input: any,
    private apiRouteService: ApiRouteService,
    private analyticsService: AnalyticsService,
    public dialogRef: MatDialogRef<AnalyticsModalComponent>
  ) {
    this.brand = input.brand;
    if (this.brand) {
      this.brand == this.brand.toLowerCase();
      if (this.brand === "toyota" || this.brand === "lexus") {
        const opt = JSON.parse(JSON.stringify(ANALYTICS_OPTIONS));
        for (let i = 0; i < opt.length; i++) {
          opt[i].name = opt[i].name.replace(
            /BRAND/g,
            this.brand.charAt(0).toUpperCase() + this.brand.slice(1)
          );
        }
        this.options = opt;
      }
    }
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.data = [];
    this.error = false;
    if (this.selected === 1) {
      this.processAnalytics(
        this.apiRouteService.getRoutes().ANALYTICS.USERS_SESSIONS_ALL(),
        (r) => {
          let total = 0;
          for (let i = 0; i < r.length; i++) {
            total += r[i].count;
          }
          this.data = new Array(r.length);
          for (let i = 0; i < r.length; i++) {
            this.data[i] = {
              name: r[i].name,
              value: ((r[i].count / total) * 100).toFixed(2),
            };
          }
        }
      );
    } else if (this.selected === 2) {
      this.processAnalytics(
        `${this.apiRouteService.getRoutes().ANALYTICS.USERS_SESSIONS()}?days=5`,
        (r) => {
          this.data = r;
        }
      );
    } else if (this.selected === 3) {
      this.processAnalytics(
        `${this.apiRouteService
          .getRoutes()
          .ANALYTICS.USERS_SESSIONS()}?days=30`,
        (r) => {
          this.data = r;
        }
      );
    } else if (this.selected === 4) {
      this.seedType = "Model Year";
      this.getSeedAndSetNameValue("model_year");
    } else if (this.selected === 5) {
      this.seedType = "Model Number";
      this.getSeedAndSetNameValue("model_number");
    } else if (this.selected === 6) {
      this.seedType = "Series";
      this.getSeedAndSetNameValue("series");
    } else if (this.selected === 7) {
      this.seedType = "Series Category";
      this.getSeedAndSetNameValue("series_category");
    } else if (this.selected === 8) {
      this.seedType = "Grade";
      this.getSeedAndSetNameValue("grade");
    } else if (this.selected === 9) {
      this.seedType = "Drivetrain";
      this.getSeedAndSetNameValue("drivetrain");
    } else if (this.selected === 10) {
      this.seedType = "Interior Color";
      this.getSeedAndSetNameValue("interior_color");
    } else if (this.selected === 11) {
      this.seedType = "Exterior Color";
      this.getSeedAndSetNameValue("exterior_color");
    }
  }

  close() {
    this.dialogRef.close({ data: null });
  }

  getSeedAndSetNameValue(field: string) {
    this.processAnalytics(
      `${this.apiRouteService.getRoutes().ANALYTICS.SEED_ALL()}?brand=${
        this.brand
      }&&fields=${field}`,
      (r) => {
        this.data = r;
        let da = [];
        for (let i = 0; i < r.length; i++) {
          if (r[i][field]) {
            let o = undefined;
            for (let j = 0; j < da.length; j++) {
              if (da[j].name === r[i][field]) {
                o = da[j];
                break;
              }
            }
            if (o) {
              o.value++;
            } else {
              da.push({
                name: r[i][field],
                value: 1,
              });
            }
            o = undefined;
          }
        }
        this.data = new Array(da.length);
        for (let i = 0; i < da.length; i++) {
          this.data[i] = {
            name: da[i].name,
            value: da[i].value,
          };
        }
      }
    );
  }

  processAnalytics(endpoint: string, callback: (d: any) => void) {
    this.analyticsService.get(
      endpoint,
      (d) => callback(d),
      (error) => {
        this.error = true;
      }
    );
  }
}
