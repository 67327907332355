import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "dsmds-vehicle-card-powertrain",
  templateUrl: "./vehicle-card-powertrain.component.html",
  styleUrls: ["./vehicle-card-powertrain.component.css"],
})
export class VehicleCardPowertrainComponent implements OnInit {
  @Input() rowData: any = {};
  @Input() displayText: any;
  @Input() selectedPwrtrain: any;

  constructor() {}

  ngOnInit(): void {}

  formatText(text) {
    if (text) {
      return text.replace(/ *\[[^\]]*]/g, "");
    }
  }

  formatPrice(price) {
    return parseInt(price)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  formatDescriptionText(text) {
    if (text) {
      text = text.replaceAll("• ", "");
      text = text.replace(/ *\[[^\]]*]/g, "");
    }
    return text;
  }
}
