import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";
import { LexusExteriorComponent } from "./modules/lexus/lexus-exterior/lexus-exterior.component";
import { LexusFindcarComponent } from "./modules/lexus/lexus-findcar/lexus-findcar.component";
import { LexusGradeComponent } from "./modules/lexus/lexus-grade/lexus-grade.component";
import { LexusInteriorComponent } from "./modules/lexus/lexus-interior/lexus-interior.component";
import { LexusModelComponent } from "./modules/lexus/lexus-model/lexus-model.component";
import { LexusOptionComponent } from "./modules/lexus/lexus-option/lexus-option.component";
import { LexusZipcodeComponent } from "./modules/lexus/lexus-zipcode/lexus-zipcode.component";
import { NavigationPageComponent } from "./modules/shared/navigation-page/navigation-page.component";
import { FindMyCarComponent } from "./modules/toyota/find-my-car/find-my-car.component";
import { InteriorColorComponent } from "./modules/toyota/interior-color/interior-color.component";
import { ModelColorComponent } from "./modules/toyota/model-exterior-color/model-color.component";
import { ModelTrimComponent } from "./modules/toyota/model-grade/model-trim.component";
import { ModelInfoComponent } from "./modules/toyota/model-series/model-info.component";
import { PackagesComponent } from "./modules/toyota/packages/packages.component";
import { PwrtrainComponent } from "./modules/toyota/pwrtrain/pwrtrain.component";
import { ZipCodeComponent } from "./modules/toyota/zip-code/zip-code.component";

const routes: Routes = [
  { path: "", component: NavigationPageComponent },
  { path: "toyota", component: ModelInfoComponent, pathMatch: "full" },
  { path: "toyota/trim", component: ModelTrimComponent, pathMatch: 'full' },
  { path: "toyota/trim/powertrain", component: PwrtrainComponent, pathMatch: 'full' },
  {
    path: "toyota/trim/powertrain/exteriorcolor",
    component: ModelColorComponent, pathMatch: 'full',
  },
  {
    path: "toyota/trim/powertrain/exteriorcolor/interiorColor",
    component: InteriorColorComponent, pathMatch: 'full',
  },
  {
    path: "toyota/trim/powertrain/exteriorcolor/interiorColor/packages",
    component: PackagesComponent, pathMatch: 'full',
  },
  {
    path: "toyota/trim/powertrain/exteriorcolor/interiorColor/packages/zipcode",
    component: ZipCodeComponent, pathMatch: 'full'
  },
  {
    path: "toyota/trim/powertrain/exteriorcolor/interiorColor/packages/zipcode/findMyCar",
    component: FindMyCarComponent, pathMatch: 'full',
  },

  // Lexus Route
  { path: "lexus", component: LexusModelComponent, pathMatch: "full" },
  { path: "lexus/trim/:seriesCode", component: LexusGradeComponent },
  { path: "lexus/exterior/:gradeCode", component: LexusExteriorComponent },
  { path: "lexus/interior/:gradeCode", component: LexusInteriorComponent },
  { path: "lexus/packages/:gradeCode", component: LexusOptionComponent },
  { path: "lexus/zipcode", component: LexusZipcodeComponent },
  { path: "lexus/findmycar", component: LexusFindcarComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
