import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'dsmds-button-lexus',
  templateUrl: './button-lexus.component.html',
  styleUrls: ['./button-lexus.component.css']
})
export class ButtonLexusComponent implements OnInit {
  @Input() buttonConfig: any;
  @Output() onClickfunc = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  onClickButton(e) {
    this.onClickfunc.emit(e);
  }

}
