import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dsmds-logo-icon',
  templateUrl: './logo-icon.component.html',
  styleUrls: ['./logo-icon.component.css']
})
export class LogoIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
