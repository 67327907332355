<div class="dealer-selection-modal">
    <h2 mat-dialog-title class="mb-1" >{{selectedDealer ? "Change Dealer" : "Select a Dealer"}}</h2>
                
    <div mat-dialog-content>
        <div class="row my-2">
            <div class="col-12 col-sm-6 col-md-7 col-xxl-8">
                <h4 *ngIf="currentDealer">Current dealer: {{currentDealer?.name | lowercase | titlecase}} 
                <small> {{currentDealer?.dealer_number}}</small></h4>
            </div>
            <div class="col-12 col-sm-6 col-md-5 col-xxl-4">
                <div><input type="text" class="form-control" placeholder="Search Here" [formControl]="myControl"  >
                </div>
               <!-- <mat-autocomplete #auto="matAutocomplete"  [displayWith]="displayFn">
                    <mat-option *ngFor="let dealer of filteredOptions | async" [value]="dealer" >
                        {{dealer}}
                        
                    </mat-option>
                </mat-autocomplete> -->
            </div>
        </div>
        <div class="row scroll-dealers">
            <div *ngFor="let dealer of filteredOptions | async" class="col-lg-6 col-sm-6 col-12">
                <mat-card class="my-2" (click)="onSelectDealer(dealer)">
                    <div class="row">
                        <div class="col-9">
                            {{dealer?.name | lowercase | titlecase}}<br>
                            <small>{{dealer?.dealer_number}}</small>
                        </div>
                        <div class="col-3" >
                            <mat-icon *ngIf="dealer?.dealer_number==selectedDealer?.dealer_number" class="success-icon">check_circle</mat-icon>
                        </div>
                       
                    </div>
                </mat-card>

            </div>

        </div>
    
    </div>
    <div mat-dialog-actions style="position: absolute; bottom: 0; width: 100%">
        <div class="ms-auto">
            <button mat-button class="toyota-btn-secondary bwd toyota-btn-sm me-2" (click)="cancel()">Cancel</button>
            <button mat-button cdkFocusInitial class="toyota-btn-primary bwd toyota-btn-sm" (click)="confirm()">Save</button>
        </div>    
    </div>
</div>
