import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { TokenService } from '../auth/token.service';
import {OAuthService} from "angular-oauth2-oidc";

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private jwtService: TokenService,
    private oauthService: OAuthService,
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.oauthService.getAccessToken();
    const substring = 'oauth2'
    if (token && !request.urlWithParams.includes(substring)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((error) => {
        console.log(error)
        return throwError(() => error);
      })
    );
  }
}
