<div class="analytics-selection-modal">
  <h2 mat-dialog-title class="mb-1">Analytics</h2>
  <div mat-dialog-content style="height: 80%">
    <mat-select [(value)]="selected" (selectionChange)="refresh()">
      <mat-option *ngFor="let option of options" [value]="option.value">{{
        option.name
      }}</mat-option>
    </mat-select>
    <div *ngIf="error">
      <mat-error>An error retrieving Analytics data occurred.</mat-error>
    </div>
    <div
      class="container"
      style="height: 300px; width: 100%"
      *ngIf="!error && selected === 1"
    >
      <ngx-charts-pie-chart
        [results]="data"
        [scheme]="colorScheme"
        style="height: 290px"
      >
        <ng-template #tooltipTemplate let-model="model">
          <h1>
            {{ model.name }}
          </h1>
          <h2>{{ model.value }}%</h2>
        </ng-template>
      </ngx-charts-pie-chart>
    </div>
    <div
      class="container"
      style="height: 300px; width: 100%"
      *ngIf="!error && (selected === 2 || selected === 3)"
    >
      <ngx-charts-line-chart
        [results]="data"
        [xAxis]="true"
        [scheme]="colorScheme"
        [showGridLines]="false"
      >
      </ngx-charts-line-chart>
    </div>
    <div *ngIf="!error && selected >= 4">
      <ngx-charts-bar-horizontal
        [view]="view"
        [scheme]="colorScheme"
        [results]="data"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="'Amount'"
        [yAxisLabel]="seedType"
      >
      </ngx-charts-bar-horizontal>
    </div>
  </div>
  <div
    mat-dialog-actions
    style="position: static; bottom: 0; height: 20%; width: 100%"
  >
    <div class="ms-auto">
      <button
        mat-button
        class="toyota-btn-secondary bwd toyota-btn-sm me-2"
        (click)="refresh()"
      >
        Refresh
      </button>
      <button
        mat-button
        class="toyota-btn-secondary bwd toyota-btn-sm me-2"
        (click)="close()"
      >
        Close
      </button>
    </div>
  </div>
</div>
