import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { Subject, take } from "rxjs";
import { ROUTES_TOYOTA } from "src/app/constants";
import { TOYOTA_BASE_URL } from "src/app/constants/imagebase.constants";
import { FindMyCarService } from "src/app/service/find-my-car.service";
import { getToyotaDisplayHeader } from "src/app/utils";
import { UiStateService } from "../../../service/ui-state.service";

@Component({
  selector: "app-interior-color",
  templateUrl: "./interior-color.component.html",
  styleUrls: ["./interior-color.component.css"],
})
export class InteriorColorComponent implements OnInit {
  interiorColor: any = [];
  displayText: any;
  unsubscribe$ = new Subject();
  interiorCarImages:any={};
  selectedInteriorColor:any;
  selectedInteriorImg:any;
  constructor(
    private appState: UiStateService,
    private findCarService: FindMyCarService,
    private router: Router
    ) {
      
  }

  ngOnInit(): void {
    if(history.state?.selected_exterior !== undefined){
      this.appState?.setState({
        exterior_color: history.state?.selected_exterior,
      });
    }
    this.checkStateValidityAndInit();
  }
  
  checkStateValidityAndInit(){
    try {
      /* Extract interior color information based on previously
       stored series data */
      this.appState
        .getState()
        .pipe(take(1))
        .subscribe((state) => {
          console.log('InState', state)
          if(!state.hasOwnProperty('modelNo') || state?.modelNo==null){
            this.router?.navigate(ROUTES_TOYOTA.slice(0, 3)); 
          }
          else if(!state.hasOwnProperty('exterior_color') || state?.exterior_color==null){
            this.router?.navigate(ROUTES_TOYOTA.slice(0, 4)); 
          }
          else{
            this.displayText = getToyotaDisplayHeader(state);
            
            this.getImage(state?.seriescode, state?.year, state.modelNo);
            
            const interior_color = state.toyotaseriesdata?.colors?.interior;
            this.getInteriorColor(state.modelNo, interior_color);
            this.interiorColor = this.interiorColor.filter(
              (x) => x.status == "LIVE" && x.type == "interiorcolor"
            );
            this.selectedInteriorColor = this.interiorColor[0]
            if(state?.interior_color){
              this.selectedInteriorColor = state?.interior_color;
            }
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  /* Extract interior color information */
  getInteriorColor(modelNo, interior_color) {
    for (let i = 0; i < interior_color.length; i++) {
      let applicable = interior_color[i].applicable;
      for (let j = 0; j < applicable.length; j++) {
        if (applicable[j].trim == modelNo) {
          this.interiorColor.push({
            color_code: interior_color[i]?.code,
            color_name: interior_color[i]?.title.split("&")[0],
            image:
              TOYOTA_BASE_URL +
              applicable[j]?.override?.images?.on_swatch_image,
            status: interior_color[i]?.visibility?.status?.status_code,
            type: interior_color[i]?.type,
          });
        }
      }
    }
  }

  getImage(code, year, modelCode) {
  
    this.findCarService
        .getToyotaImage(code, year)
        .pipe()
        .subscribe((data) => {
          let model = _.values(data.DataArea.model).flat();
          let modelColor = data.DataArea.model[modelCode]?.interiorcolor;
          
          for(let element in modelColor){
            this.interiorCarImages[element] = TOYOTA_BASE_URL + modelColor[element]["3dimage"]["680_383_PNG"]["1"][0]
          }
        });
  }

  getLeftCarImage(){
    if(this.interiorCarImages && 
      this.selectedInteriorColor && 
      this.selectedInteriorColor?.color_code &&
      this.interiorCarImages[this.selectedInteriorColor?.color_code]){
      return true
    }
    return false

  }

  selectNewInterior(idata) {
    this.selectedInteriorColor = idata
  }
  formatColorName(colorName) {
    return colorName
      ?.replace('[softex]', "");
  }
}
