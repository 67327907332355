<div class="container pb-5">
  <div class="row text-center text-white mt-4 mb-4">
    <div class="vehicle-container">
      <h1 style="font-weight: 500">
          Your vehicle:
          <span style="font-weight: 400">{{ displayText }}</span>
        </h1>
        <h1 class="display-4">Select a Powertrain</h1>
    </div>
  </div>
  <div class="dsmds-steps-navigations">
    <dsmds-steps-navigations [tabnumber]="2"></dsmds-steps-navigations>
  </div>
  <div class="container-fluid1 mt-3">
    <div
      class="row">
      <div
        class="col-lg-4 col-xl-3 row-cols-xxxl-5 col-md-6 col-12"
        *ngFor="let row of pwrTrainData">
        <dsmds-vehicle-card-powertrain [rowData]="row" [selectedPwrtrain]="selectedPwrtrain" [displayText]="displayText"></dsmds-vehicle-card-powertrain>
      </div>
      
    </div>
  </div>
</div>
