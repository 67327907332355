<div style="background-color: #fff">
  <div class="main-nav-bar container h-100">
    <div class="t-container">
      <div class="row h-100 justify-content-center align-items-center g-0">
        <div class="col-6 col-sm-6 col-md-24-9">
          <div
            (click)="navigateToToyota()"
            style="display: inline-block; cursor: pointer"
          >
            <dsmds-logo-small-icon
              class="d-block d-md-none"
            ></dsmds-logo-small-icon>
            <dsmds-logo-icon class="d-none d-md-block"></dsmds-logo-icon>
          </div>
        </div>
        <div class="col-md-24-6 d-none d-md-block">
          <div class="toyota-logo">
            <div *ngIf="latest_date" class="text-center" style="margin-top: 1%">
              Results Accurate as of<br class="d-lg-none" />
              {{ latest_date }}
            </div>
          </div>
        </div>
        <div class="col-6 col-sm-6 col-md-24-9">
          <div class="SFP-logo">
            <div class="pe-3">
              <dsmds-button
                (onClickfunc)="onClickRedirect()"
                [buttonConfig]="{
                  text: 'New Search',
                  type: 'primary',
                  size: 'sm'
                }"
              ></dsmds-button>
            </div>
            <div>
              <a [href]="feedbackUrl" target="_blank" rel="noopener noreferrer">
                <dsmds-button
                  (onClick)="onClickfunc($event)"
                  [buttonConfig]="{
                    text: 'Feedback',
                    type: 'primary',
                    size: 'sm'
                  }"
                ></dsmds-button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="justify-content-center mt-5" [hidden]="loading">
  <div class="spinner-border" role="status">
    <span class="sr-only" id="loading"></span>
  </div>
</div>
<div class="dsmds-steps-navigations px-4 mt-3">
  <dsmds-steps-navigations [tabnumber]="6"></dsmds-steps-navigations>
</div>
<div class="mt-3 px-4" [ngClass]="{ 'd-flex d-sm': !exact_match }">
  <div
    class="table-responsive-lg"
    [hidden]="!loading"
    [class.w-100]="exact_match"
    style="overflow-x: auto; position: relative"
  >
    <table id="users" aria-describedby="findcar-table">
      <tbody>
        <tr>
          <th scope="row"></th>
          <td>
            <div class="your-choice-header">Your choice</div>
            <div class="img-not-found" *ngIf="!your_choice?.jellyImage">
              Image Coming Soon
            </div>
            <img
              *ngIf="your_choice?.jellyImage"
              alt="Car Image"
              width="250px"
              src="{{ your_choice?.img }}"
            />
          </td>
          <td [hidden]="!exact_match">
            <div *ngIf="exact_match" class="exact-match-header">
              Closest match
            </div>
            <div
              class="img-not-found"
              *ngIf="!exact_match?.exterior_image_path_compare"
            >
              Image Coming Soon
            </div>
            <img
              *ngIf="exact_match && exact_match?.exterior_image_path_compare"
              width="250px"
              alt="Car Image"
              src="{{ getImage(exact_match?.exterior_image_path_compare) }}"
            />
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            <div class="exact-match-header">Recommendations</div>
            <div
              class="img-not-found"
              *ngIf="!data?.exterior_image_path_compare"
            >
              Image Coming Soon
            </div>
            <img
              *ngIf="data?.exterior_image_path_compare"
              alt="Car Image"
              src="{{ getImage(data?.exterior_image_path_compare) }}"
              class="ml-lg-5 order-1 order-lg-2"
              width="250px"
            />
          </td>
        </tr>
        <!-- <tr>
        <th scope="row">
          <div class="left-title">
            <div class="left-title-sticky">
                Earliest available<sup
                style="cursor: pointer"
                (click)="openDialogDisclaimer('eta')"
                >*</sup
              >
            </div>
          </div>
        </th>
        <td style="padding: 30px">
          <mat-select
            (selectionChange)="onSelection($event)"
            [(value)]="modeselect"
          >
            <mat-option *ngFor="let eta of eta_options" [value]="eta.value">
              {{ eta.displayValue }}
            </mat-option>
          </mat-select>
        </td>
        <td [hidden]="!exact_match">
          {{ exact_match?.eta }}
        </td>
        <td [hidden]="!best_match" *ngFor="let data of best_match">
          {{ data?.eta }}
        </td>
      </tr> -->
        <tr>
          <th scope="row">
            <div class="left-title">
              <div class="left-title-sticky">Estimated Dealer Arrival</div>
            </div>
          </th>
          <td style="padding: 30px">
            <mat-select
              (selectionChange)="onSelectionEDA($event)"
              [(value)]="edaselect"
            >
              <mat-option *ngFor="let eta of eta_options" [value]="eta.value">
                {{ eta.displayValue }}
              </mat-option>
            </mat-select>
          </td>
          <td [hidden]="!exact_match">
            {{ exact_match?.edaText }}
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ data?.edaText }}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <div class="left-title">
              <div class="left-title-sticky">
                Similarity
                <sup
                  style="cursor: pointer"
                  (click)="openDialogDisclaimer('similarity')"
                  >*</sup
                >
              </div>
            </div>
          </th>
          <td></td>
          <td [hidden]="!exact_match">
            {{ exact_match?.Similarity_score * 100 | number: "1.0-0" }} %
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ data?.Similarity_score * 100 | number: "1.0-0" }} %
          </td>
        </tr>
        <tr>
          <th scope="row">
            <div class="left-title">
              <div class="left-title-sticky">Model Year</div>
            </div>
          </th>
          <td style="padding: 30px">
            <mat-select
              (selectionChange)="onSelectionYear($event)"
              [(value)]="modeyear"
            >
              <mat-option
                *ngFor="let year of year_options"
                [value]="year.value"
              >
                {{ year.displayValue }}
              </mat-option>
            </mat-select>
          </td>
          <td [hidden]="!exact_match">
            {{ exact_match?.model_year_compare }}
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ data?.model_year_compare }}
          </td>
        </tr>
        <tr>
          <th scope="row">Model #</th>
          <td>
            {{ your_choice.model_number }}
            <mat-checkbox
              class="example-margin"
              (change)="
                onCheck(
                  $event,
                  'model_number_compare',
                  your_choice.model_number
                )
              "
            ></mat-checkbox>
          </td>
          <td [hidden]="!exact_match">
            {{ exact_match?.model_number_compare }}
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ data?.model_number_compare }}
          </td>
        </tr>
        <tr>
          <th scope="row">Series</th>
          <td>
            {{ getSeriesName(your_choice) }}
            <mat-checkbox
              class="example-margin"
              (change)="
                onCheck(
                  $event,
                  'toms_series_name_compare',
                  getSeriesName(your_choice)
                )
              "
            ></mat-checkbox>
          </td>
          <td [hidden]="!exact_match">
            {{ exact_match?.toms_series_name_compare }}
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ data?.toms_series_name_compare }}
          </td>
        </tr>
        <tr>
          <th scope="row">Grade</th>
          <td>
            {{ your_choice.grade }}
            <mat-checkbox
              class="example-margin"
              (change)="
                onCheck($event, 'grade_spec_name_compare', your_choice.grade)
              "
            ></mat-checkbox>
          </td>
          <td [hidden]="!exact_match">
            {{ exact_match?.grade_spec_name_compare }}
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ data?.grade_spec_name_compare }}
          </td>
        </tr>
        <tr>
          <th scope="row">Drive</th>
          <td>
            {{ your_choice.drive }}
            <mat-checkbox
              class="example-margin"
              (change)="
                onCheck($event, 'drivetrain_name_compare', your_choice.drive)
              "
            ></mat-checkbox>
          </td>
          <td [hidden]="!exact_match">
            {{ exact_match?.drivetrain_name_compare }}
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ data?.drivetrain_name_compare }}
          </td>
        </tr>
        <tr>
          <th scope="row">Interior</th>
          <td>
            {{ your_choice.interior_color }}
            <mat-checkbox
              class="example-margin"
              (change)="
                onCheck(
                  $event,
                  'interior_color_name_compare',
                  your_choice.interior_color
                )
              "
            ></mat-checkbox>
          </td>
          <td [hidden]="!exact_match">
            {{ exact_match?.interior_color_name_compare }}
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ data.interior_color_name_compare }}
          </td>
        </tr>
        <tr>
          <th scope="row">Exterior</th>
          <td>
            {{ formatExtColor(your_choice?.exterior_color) }}
            <mat-checkbox
              class="example-margin"
              (change)="
                onCheck(
                  $event,
                  'exterior_color_name_compare',
                  your_choice.exterior_color
                )
              "
            ></mat-checkbox>
          </td>
          <td [hidden]="!exact_match">
            {{ formatExtColor(exact_match?.exterior_color_name_compare) }}
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ formatExtColor(data?.exterior_color_name_compare) }}
          </td>
        </tr>
        <tr>
          <th scope="row">Packages</th>
          <td
            matTooltip="{{ your_choice?.option_package_details?.join('\r\n') }}"
            matTooltipClass="tooltip-package-details"
            [matTooltipDisabled]="!your_choice?.option_packages"
            matTooltipPosition="below"
          >
            {{ your_choice?.option_packages }}
            <button
              (click)="openOptionDialog(your_choice.option_packages)"
              class="link-btn"
              style="float: right"
            >
              +
            </button>
          </td>
          <td
            [hidden]="!exact_match"
            matTooltip="{{ exact_match?.option_package_details?.join('\r\n') }}"
            matTooltipClass="tooltip-package-details"
            [matTooltipDisabled]="!exact_match?.fio_list_compare"
            matTooltipPosition="below"
          >
            {{ exact_match?.fio_list_compare.split(",").join(", ") }}
          </td>
          <td
            [hidden]="!exact_match"
            *ngFor="let data of best_match"
            matTooltip="{{ data?.option_package_details?.join('\r\n') }}"
            matTooltipClass="tooltip-package-details"
            [matTooltipDisabled]="!data?.fio_list_compare"
            matTooltipPosition="below"
          >
            {{ data?.fio_list_compare.split(",").join(", ") }}
          </td>
        </tr>
        <tr>
          <th scope="row">
            Factory MSRP
            <sup
              style="cursor: pointer"
              (click)="openDialogDisclaimer('findcar-msrp')"
              >*</sup
            >
          </th>
          <td>{{ formatPrice(your_choice?.msrp) }}</td>
          <td [hidden]="!exact_match">
            {{ formatPrice(exact_match?.msrp_amount_compare) }}
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ formatPrice(data?.msrp_amount_compare) }}
          </td>
        </tr>
        <tr>
          <th scope="row">Dealer #</th>
          <td>{{ your_choice?.dealercode }}</td>
          <td [hidden]="!exact_match">
            {{ exact_match?.dealer_number_compare[0] }}
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ data?.dealer_number_compare[0] }}
          </td>
        </tr>
        <tr>
          <th scope="row">VIN</th>
          <td></td>
          <td [hidden]="!exact_match">
            {{ exact_match?.vin_compare[0] }}
            <button
              (click)="openDialog(exact_match)"
              [hidden]="!exact_match"
              class="link-btn"
            >
              +
            </button>
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ data?.vin_compare[0] }}
            <button
              (click)="openDialog(data)"
              title="{{ best_match?.vin_compare }}"
              mat-button
              [hidden]="!exact_match"
              class="link-btn"
            >
              +
            </button>
          </td>
        </tr>
        <tr>
          <th scope="row">V-Spec</th>
          <td></td>
          <td [hidden]="!exact_match">
            V-Spec
            <button
              (click)="openDialog(exact_match, 'vspec')"
              [hidden]="!exact_match"
              class="link-btn icn"
            >
              <mat-icon class="t-icon">cloud_upload</mat-icon>
            </button>
          </td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            V-Spec
            <button
              (click)="openDialog(data, 'vspec')"
              title="{{ best_match?.vin_compare }}"
              mat-button
              [hidden]="!exact_match"
              class="link-btn icn"
            >
              <mat-icon class="t-icon">cloud_upload</mat-icon>
            </button>
          </td>
        </tr>
        <tr>
          <th scope="row">Number available</th>
          <td></td>
          <td [hidden]="!exact_match">{{ exact_match?.vin_count }}</td>
          <td [hidden]="!exact_match" *ngFor="let data of best_match">
            {{ data?.vin_count }}
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <div style="text-align: center">
      <button
        [hidden]="!this.next"
        class="toyota-btn-primary toyota-btn-sm me-2 toyota-btn-rd"
        (click)="getBestUpsellDataPrev()"
      >
        Previous
      </button>
      <button
        [hidden]="prev >= cardatalength || cardatalength <= 4"
        class="toyota-btn-primary toyota-btn-sm toyota-btn-rd"
        (click)="getBestUpsellData(true)"
      >
        More results
      </button>
    </div>
  </div>
  <div [hidden]="!loading" class="no-result">
    <div [hidden]="exact_match">
      No available vehicles match the search criteria.
    </div>
    <div [hidden]="exact_match" style="padding-top: 10px">
      Please remove filters or try a
      <span class="lnk" (click)="onClickRedirect()">new search</span>.
    </div>
  </div>
</div>
