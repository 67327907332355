import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, take, takeUntil } from "rxjs";
import { FindMyCarService } from "src/app/service/find-my-car.service";
import { UiStateService } from "src/app/service/ui-state.service";
import { DealerSelectionModalComponent } from "../dealer-selection-modal/dealer-selection-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { LocalStorageService } from "src/app/service/storage/local-storage.service";
import { ObservableDataShareService } from "src/app/service/data-share/observable-data-share.service";
import { DEALER_USER, PROVIDE_FEEDBACK_URL } from "src/app/constants";
import { OAuthService } from "angular-oauth2-oidc";
import { errorStatusCode } from "src/app/utils/custom.function";
import { v4 as uuidv4 } from "uuid";
import {
  ANALYTICS_CAPTURE_USER_LOGIN_LOCALLY,
  ANALYTICS_TEMPLATE_USER_LOGIN,
} from "../../../constants/analytics.constants";
import { isLocal } from "../../../utils";
import { AnalyticsService } from "../../../service/analytics/analytics.service";
import { ApiRouteService } from "../../../config/api-routes";
import { isProd } from "../../../utils/env.functions";

@Component({
  selector: "dsmds-navigation-page",
  templateUrl: "./navigation-page.component.html",
  styleUrls: ["./navigation-page.component.css"],
})
export class NavigationPageComponent implements OnInit {
  toyota_dealer = [0, 1, 2, 3, 4, 5];
  unsubscribe$ = new Subject();
  dealer_list = [];
  errMessage: string;
  isLoading: boolean = true;
  sessionId = "";
  captureUserLogin = !isLocal() || ANALYTICS_CAPTURE_USER_LOGIN_LOCALLY;

  constructor(
    private appState: UiStateService,
    private router: Router,
    private findCarService: FindMyCarService,
    public dialog: MatDialog,
    private lStorage: LocalStorageService,
    private obDataShareService: ObservableDataShareService,
    private oauthServiceNav: OAuthService,
    private analyticsService: AnalyticsService,
    private apiRouteService: ApiRouteService
  ) {}
  isLexus: string = "T";
  ngOnInit(): void {
    this.populateDealerInformation();
  }

  populateDealerInformation() {
    this.obDataShareService.ssoInitialization.subscribe((profileData) => {
      if (!profileData) {
        return;
      }
      const hasIdToken = this.oauthServiceNav.hasValidIdToken();
      const hasAccessToken = this.oauthServiceNav.hasValidAccessToken();
      const accessTokenExp = this.oauthServiceNav.getAccessTokenExpiration();

      console.log(` Token Information:
      has Id Token -- ${hasIdToken}
      has Access Token -- ${hasAccessToken}
      accessTokenExp -- ${accessTokenExp}
      `);

      if (!hasIdToken || !hasAccessToken) {
        this.oauthServiceNav.logOut();
      }

      if (this.lStorage.checkItem("current_dealer")) {
        const oldD = this.lStorage.getItem("current_dealer");
        if (oldD?.brand === "LEXUS") {
          this.isLexus = "L";
        }
      }

      this.findCarService.getApiKey().subscribe({
        error: (err) => {
          console.log("Error : ");
          console.log(err);
          this.isLoading = false;
          this.errMessage = errorStatusCode(err);
          // this.oauthServiceNav.logOut();
        },
        next: (data) => {
          this.appState.setState({
            otd_api_key: data.otd_api_key,
            efc_api_key: data.efc_api_key,
            vami_api_key: data.vami_api_key,
            analytics_api_key: data.analytics_api_key,
          });

          // Generate a unique "session" identifier.
          this.sessionId = uuidv4();
          profileData["info"].session_id = this.sessionId;
          this.obDataShareService.updateSessionId(this.sessionId);

          if (this.captureUserLogin) {
            // Returns promise, but don't need to wait.
            this.analyticsService.capture(
              this.apiRouteService.getRoutes().ANALYTICS.USER_LOGIN(),
              ANALYTICS_TEMPLATE_USER_LOGIN,
              profileData["info"]
            );
          }

          this.obDataShareService.updateUserId(
            profileData["info"].UserId ? profileData["info"].UserId : ""
          );

          this.findCarService
            .getDealerList()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              error: (err) => {
                this.isLoading = false;
                this.errMessage = errorStatusCode(err);
              },
              next: (data) => {
                this.dealer_list = data?.dddata?.dealers || [];
                this.appState.setState({
                  dealer_list: this.dealer_list,
                });

                this.appState
                  .getState()
                  .pipe(take(1))
                  .subscribe((state) => {
                    const dealer_number = state.claims?.dealer_number;
                    const user_type = state.claims?.user_type;
                    let current_dealer = state?.current_dealer || null;
                    if (
                      !current_dealer &&
                      this.lStorage.checkItem("current_dealer")
                    ) {
                      current_dealer = this.lStorage.getItem("current_dealer");
                    }

                    if (user_type === DEALER_USER) {
                      const dealerObj = this.dealer_list.find(
                        (d) => d.dealer_number == dealer_number
                      );
                      if (undefined !== dealerObj) {
                        if (isProd()) {
                          window.location.href = PROVIDE_FEEDBACK_URL;
                        }
                        this.appState.setState({
                          current_dealer: dealerObj,
                        });
                        this.lStorage.setItem("current_dealer", dealerObj);
                        this.obDataShareService.updateCurrentDealer(dealerObj);
                        this.redirectToBrand(dealer_number);
                      } else {
                        this.logout();
                      }
                    }
                    // To be removed once we get lexus dealercode
                    else {
                      if (current_dealer) {
                        this.appState.setState({
                          current_dealer: current_dealer,
                        });

                        this.redirectToBrand(current_dealer.dealer_number);
                      } else {
                        this.isLoading = false;
                        this.openDialog();
                      }
                    }
                  });
              },
            });
        },
      });
    });
  }

  redirectToBrand(dealer_number) {
    this.isLoading = false;
    if (dealer_number) {
      if (this.toyota_dealer.includes(parseInt(dealer_number[0]))) {
        this.router.navigateByUrl("toyota");
      } else if (parseInt(dealer_number[0]) == 6) {
        this.router.navigateByUrl("lexus");
      }
    }
  }
  logout() {
    this.lStorage.removeItem("current_dealer");
    this.oauthServiceNav.revokeTokenAndLogout();
    this.oauthServiceNav.logOut();
  }
  openDialog() {
    const dialogRef = this.dialog.open(DealerSelectionModalComponent, {
      width: "768px",
      height: "70vh",
    });

    dialogRef.beforeClosed().subscribe((dealerObj) => {
      if (dealerObj && dealerObj?.data) {
        this.lStorage.setItem("current_dealer", dealerObj.data);
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {
      if (resp && resp?.data) {
        this.redirectToBrand(resp?.data?.dealer_number);
      }
    });
  }
}
