<div class="steps-navs-lexus">
    <mat-tab-group class="steps-navs" mat-stretch-tabs (selectedTabChange)="redirectToSteps($event)"  #tabGroup [(selectedIndex)]="activeLink">
        <mat-tab *ngFor="let link of links; let i = index" 
            class="mat-tab"
            [class.active]="i===activeLink"
        >
            <ng-template mat-tab-label>
             {{link}} 
            </ng-template>
        </mat-tab>
       
        <mat-tab class="mat-tab" [disabled]="!isAllSelected" >
            <ng-template mat-tab-label>
                <div class="find-car-btn" [class.car-btn-disabled]="!isAllSelected" (click)="redirectToFindCar()">FIND CAR</div>
            </ng-template>
        </mat-tab>
        <!-- <mat-tab class="mat-tab lg-hidden">
            <ng-template mat-tab-label>&nbsp;
            </ng-template>
        </mat-tab> -->
    </mat-tab-group>
</div>
 