<a class="general-router" (click)="saveModelAndRedirect()">   
    <div class="mt-3">
        <div class="model-row" id="{{ data?.code }}" [class.model-active]="selectedModel===data?.code">
        
            <div
                class="bg-image hover-overlay ripple shadow-1-strong rounded"
                data-ripple-color="light"
            >
                <img
                    alt="Car Image"
                    src="{{ data?.image }}"
                    class="ml-lg-5 order-1 order-lg-2 img-fluid"
                />
            </div>
            <div class="align-items-center justify-content-between mt-1">
                <div class="card-header">{{ data?.year + " " + data?.name }}</div>
                <hr />
                <div class="my-2">
                    <div class="row">
                        <div class="col-7">
                            <div class="card-price">
                                {{ "$" + data?.price + " " }}
                            </div>
                            <div class="card-subheader">
                                Starting MSRP
                                <sup style="cursor: pointer" (click)="callParentPrice()"
                                >*</sup>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="card-price">
                                {{ data?.city_mpg }}/{{ data?.combined_mpg }}
                            </div>
                            <div class="card-subheader">
                                {{ "Est. MPG" }}
                                <sup style="cursor: pointer" (click)="callParentMpg()">*</sup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</a>    
