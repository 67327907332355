export const environment = {
  production: false,
  apiBase: "https://efc.toyota.com",
  vapimsBase: "https://api.vapims.tms.aws.toyota.com",
  backendModelBaseUrl: "https://api.dsmotduat.toyota.com/otdgm",
  findcarUrl: "https://sfe.dsmotduat.toyota.com",
  backendBaseUrl: "https://api.dsmotduat.toyota.com/otdfc",
  lexusBase: "https://next.ws.tms.aws.toyota.com",
  vSpecUrl: "https://guest.dealer.qa.toyota.com",
  vSpecUrlApiBase: "https://api.rti.qa.toyota.com",
  analyticsBaseUrl: "<TBD>",
  idle: 1000,
  timeOut: 120,
};
