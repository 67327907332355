<div class="container pb-5">
  <div class="row text-center text-white mt-4 mb-4">
    <div class="model-container">
      <h1 style="font-weight: 500">
        Your vehicle:
        <span style="font-weight: 400">{{ displayText }}</span>
      </h1>
      <h1 class="display-4">Select an Interior Color</h1>
    </div>
  </div>
  <div class="dsmds-steps-navigations">
    <dsmds-steps-navigations [tabnumber]="4"></dsmds-steps-navigations>
  </div>
  <div class="container-fluid-o mt-3">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="bg-image hover-overlay ripple shadow-1-strong rounded" data-ripple-color="light">
          <img alt="Car Image" [src]="interiorCarImages[selectedInteriorColor?.color_code]"
                  [class.no-image-op]="!getLeftCarImage()" class="img-fluid"  dsmdsImgFallback="assets/images/toyota-interior-df.png"/>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="row">
          <div class="col-xxl-3 col-md-4 col-sm-6 col-12" *ngFor="let data of interiorColor">
            <div class="general-router" (click)="selectNewInterior(data)">
              <div class="interior-color bg-white" id="{{ data.color_code }}"
                [class.interior-active]="data.color_code===selectedInteriorColor?.color_code">

                <img alt="Car Image" src="{{ data.image }}" class="img-fluid" />
                <label class="labelGrp">
                  <div class=" text-muted mb-0 small">
                    {{ formatColorName(data.color_name) }}
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="moveButtons">
          <a
            [routerLink]="['packages']"
            [state]="{idata:selectedInteriorColor, displayText}"
            >
            <dsmds-button-round [buttonConfig]="{
              'text':'Next Step',
              'type':'primary',
               'size' : 'sm'
            }"></dsmds-button-round>
            </a
          >
        </div>
      </div>
    </div> 
  </div>