import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'dsmds-vehicle-card-grade',
  templateUrl: './vehicle-card-grade.component.html',
  styleUrls: ['./vehicle-card-grade.component.css']
})
export class VehicleCardGradeComponent implements OnInit {
  @Input() data:any = {}
  @Input() selectedGrade:any = null
  @Input() displayText:any;
  @Output() openDialogFromChild = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }
  formatText(text) {
    if (text) {
      return text.replace(/ *\[[^\]]*]/g, "");
    }
  }

  formatPrice(price) {
    return parseInt(price)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  callParentPrice() {
    this.openDialogFromChild.emit('msrp');
  }
  callParentMpg() {
    this.openDialogFromChild.emit('mpg');
  }

}
