<div
  class="main-body-contents"
  [ngClass]="isLexus === 'L' ? 'lexus-body' : 'toyota-body'"
>
  <header class="top-header" [hidden]="!loading">
    <div class="container h-100 justify-content-center align-items-center">
      <div class="t-container">
        <div class="row gx-1">
          <div class="col-8 col-sm-6 col-lg-4 offset-lg-4">
            <div>
              <div class="min-top-bar">Smart Fulfillment Engine Prototype</div>
            </div>
          </div>
          <div
            class="col-4 col-sm-6 col-lg-4"
            style="display: flex; justify-content: flex-end"
          >
            <div class="profile-owner min-top-bar d-none d-sm-flex">
              {{ profileOwner | lowercase | titlecase }}
              <span class="d-none d-md-flex ms-2" style="opacity: 0.8"
                >({{ profileDealer?.name | lowercase | titlecase }}
                {{ profileDealer?.dealerCd }})</span
              >
            </div>
            <div class="actions login__block__actions">
              <div class="dropdown" [ngClass]="{ open: showOption }">
                <div data-toggle="dropdown" (click)="toggleOption()">
                  <img [src]="imgProfile" alt="User" #loginpopup />
                </div>
                <ul class="dropdown-menu pull-right" *ngIf="this.showOption">
                  <li>
                    <div class="h-100 mb-3">
                      <div class="pss-3">
                        {{ profileOwner | lowercase | titlecase }}
                      </div>
                      <div class="pss-3">
                        <small>{{ profileDealer?.dealerCd }} </small>
                      </div>
                      <div class="pss-3">
                        <small
                          >{{ profileDealer?.name | lowercase | titlecase }}
                        </small>
                      </div>
                    </div>
                  </li>
                  <li *ngIf="profileType !== 'Dealer-User'">
                    <div (click)="openDialog()" class="h-100 d-flex">
                      <mat-icon>settings</mat-icon>
                      <span class="ps-2">Change Dealership</span>
                    </div>
                  </li>
                  <li (click)="openAnalytics()" *ngIf="showAnalytics">
                    <div class="h-100 d-flex">
                      <mat-icon>pie_chart</mat-icon>
                      <span class="ps-2">Analytics</span>
                    </div>
                  </li>
                  <li (click)="doLogout()" *ngIf="token">
                    <div class="h-100 d-flex">
                      <mat-icon>exit_to_app</mat-icon>
                      <span class="ps-2">Logout</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div [hidden]="!loading">
    <div style="background-color: #fff" *ngIf="showLogo && 'T' === isLexus">
      <div class="main-nav-bar container h-100">
        <div class="t-container">
          <div
            class="row h-100 justify-content-center align-items-center row gx-1"
          >
            <div class="col-6 col-sm-6 col-md-4">
              <div
                (click)="navigateToHome('toyota')"
                style="display: inline-block; cursor: pointer"
              >
                <dsmds-logo-small-icon
                  class="d-block d-md-none"
                ></dsmds-logo-small-icon>
                <dsmds-logo-icon class="d-none d-md-block"></dsmds-logo-icon>
              </div>
            </div>
            <div class="col-md-4 d-none d-md-block">
              <div class="toyota-logo">
                <h1 class="display-4">Tell us about the car you had in mind</h1>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4">
              <div class="SFP-logo">
                <div class="pe-3" *ngIf="!isLandingPage">
                  <div>
                    <dsmds-button
                      (onClickfunc)="onClickRedirect()"
                      [buttonConfig]="{
                        text: 'New Search',
                        type: 'primary',
                        size: 'sm'
                      }"
                    ></dsmds-button>
                  </div>
                </div>
                <div>
                  <a
                    [href]="feedbackUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <dsmds-button
                      (onClick)="onClickfunc($event)"
                      [buttonConfig]="{
                        text: 'Feedback',
                        type: 'primary',
                        size: 'sm'
                      }"
                    ></dsmds-button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nav-bar-lexus" *ngIf="showLogo && 'L' === isLexus">
      <div class="main-nav-bar-lexus container h-100">
        <div class="t-container h-100">
          <div class="row h-100 justify-content-center align-items-center">
            <div class="col-6 col-sm-5 col-md-4">
              <img
                (click)="navigateToHome('lexus')"
                class="nav-hover"
                style="width: 150px; height: auto"
                alt="logo"
                [src]="lexusLogoUrl"
              />
            </div>
            <div class="col-8 col-sm-6 col-md-4 d-none d-md-block">
              <div class="toyota-logo">
                <h1 style="font-weight: 700; font-size: 180%" class="display-4">
                  TELL US ABOUT THE CAR YOU HAD IN MIND
                </h1>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4">
              <div class="SFP-logo-lexus d-flex">
                <div class="pe-2" *ngIf="!isLandingPage">
                  <a>
                    <dsmds-button-lexus
                      (onClickfunc)="onClickRedirectlexus()"
                      [buttonConfig]="{
                        text: 'New Search',
                        type: 'primary',
                        size: 'sm'
                      }"
                    ></dsmds-button-lexus>
                  </a>
                </div>
                <div>
                  <a
                    [href]="feedbackUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <dsmds-button-lexus
                      (onClick)="onClickfunc($event)"
                      [buttonConfig]="{
                        text: 'feedback',
                        type: 'primary',
                        size: 'sm'
                      }"
                    ></dsmds-button-lexus>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-outlet *ngIf="token"></router-outlet>
  </div>
</div>
