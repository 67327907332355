<div class="container">
  <div class="row text-center text-white mb-5">
    <div class="vehicle-container">
      <span style="margin-top: 5%; margin-left: 20%; text-align: center">
        <h1 class="container-heading">
          YOUR VEHICLE:
          <span>{{
            modelNumber
          }}</span>
        </h1>
      </span>
      <span style="margin-top: 12%; margin-left: 20%">
        <h1 class="display-4">SELECT A GRADE</h1>
      </span>
    </div>
  </div>
  <div class="dsmds-steps-navigations-lexus">
    <dsmds-steps-navigations-lexus [tabnumber]="1" [paramsdata]="paramsdata"></dsmds-steps-navigations-lexus>
  </div>
  <div class="trim3">
    <div
      class="row" style="align-items: stretch;"
    >
    <div class="col-lg-4 col-xl-4 col-xxl-3 col-md-6 col-sm-12 mb-4 border-bottom1" *ngFor="let data of gradeResult"  >
      <a (click)="navigateToExteriorColor($event, data)" class="general-router-lx" >
        <div class="trim-row" #handleDragW [class.active-trim]="lexGradeCodeSelected==data.code" >
          <div class="col-mds" >
              <div
                class="bg-image hover-overlay ripple shadow-1-strong rounded"
                data-ripple-color="light"
              >
                
                  <img
                    alt="Car Image"
                    src="{{ data.image? data.image: 'assets/images/default-car-lexus.png'}}"
                    class="ml-lg-5 order-1 order-lg-2 img-fluid"
                    [class.no-image] = "!data.image"
                  />
              </div>
            </div>
          <div class="d-flexs mt-1">
            <div class="card-car-tittle"
              style="font-weight: 700"
            >
              {{ data.title }}
            </div>
            <div class="display:flex,flex:column">
              <div class="font-weight-bold my-2">
                <div class="card-price-0">
                  <span class="card-subheader">STARTING AT {{ "$" + formatPrice(data.price) }}</span>
                  <span
                    style="cursor: pointer"
                    (click)="openDialog('lexus-msrp')"
                    >*</span
                  >
                </div>
              </div>
              <div style="text-transform: uppercase">{{ data.drive }}</div>
              <br />
              <div [hidden]="data.description === undefined">
                <p class="card-subheader" [innerHtml]="formatText(data.description)"></p>
              </div>
            </div>
          </div>
        </div>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="handling"> <h1 >{{errMessage}}</h1></div>