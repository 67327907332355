import { Component, OnDestroy, OnInit } from "@angular/core";
import { FindMyCarService } from "../../../service/find-my-car.service";
import * as _ from "lodash";
import { DisclaimerPopupComponent } from "../../shared/disclaimer-popup/disclaimer-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { Subject, takeUntil, take } from "rxjs";
import { UiStateService } from "../../../service/ui-state.service";
import { TOYOTA_BASE_URL } from "src/app/constants/imagebase.constants";
import { Router } from "@angular/router";
import { getToyotaDisplayHeader } from "src/app/utils";
import { errorStatusCode } from "src/app/utils/custom.function";

@Component({
  selector: "app-model-trim",
  templateUrl: "./model-trim.component.html",
  styleUrls: ["./model-trim.component.css"],
})
export class ModelTrimComponent implements OnInit, OnDestroy {
  gridClass =
    "col-lg-4 col-xl-3 row-cols-xxxl-5 col-md-6 col-sm-12 mb-4 mb-lg-0";
  displayText: string = "";
  code: any;
  year: any;
  gradeResult: any = [];
  featureData: any = [];
  selectedGrade: any = null;
  unsubscribe$ = new Subject();
  errMessage: string;
  isLoading: boolean = true;
  constructor(
    private findCarService: FindMyCarService,
    public dialog: MatDialog,
    private appstate: UiStateService,
    private router: Router
  ) {
    if (history.state?.code === undefined) {
      try {
        this.appstate
          .getState()
          .pipe(take(1))
          .subscribe((state) => {
            if (state?.seriescode) {
              this.code = state?.seriescode;
              this.year = state?.year;
              this.getTrimData();
              if (state?.gradeData) {
                this.selectedGrade = state?.gradeData[0].code;
              }
            } else {
              this.router?.navigate(["/"]);
            }
          });
      } catch (error) {
        this.router?.navigate(["/"]);
        console.log(error);
      }
    } else {
      this.code = history.state?.code;
      this.year = history.state?.year;

      /* Store Selected State in uistate service */
      this.appstate?.setState({
        seriescode: this.code,
        year: history.state?.year,
        series: history.state?.name,
      });
      this.getTrimData();
    }
  }
  /* Open Disclaimer Popup based on type */
  openDialog(type) {
    this.dialog.open(DisclaimerPopupComponent, {
      data: type,
    });
  }

  /* Get trim information based on series and type */
  ngOnInit(): void {}
  getTrimData() {
    try {
      this.appstate
        .getState()
        .pipe(take(1))
        .subscribe((state) => {
          this.displayText = getToyotaDisplayHeader(state);
        });
    } catch (error) {
      this.router?.navigate(["/"]);
      console.log(error);
    }

    try {
      this.findCarService
        .getSeriesDetials("TOY", this.code, this.year)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          error: (err) => {
            this.isLoading = false;
            this.errMessage = errorStatusCode(err);
          },
          next: (data) => {
            this.appstate.setState({
              toyotaseriesdata: data,
            });
            this.trimInfo(data);
            /* Group model no based on Grade title */
            this.featureData = _.chain(this.gradeResult)
              .groupBy("title")
              .map((value, key) => ({ title: key, grade: value }))
              .value();
          },
        });
    } catch (error) {
      console.log(error);
    }
  }

  /* Get trim information based on series */
  trimInfo(data) {
    const trim = data["trims"];
    for (let i = 0; i < trim.length; i++) {
      this.gradeResult.push({
        year: data?.year,
        code: trim[i]?.code,
        attributes: trim[i]?.attributes,
        title: "",
        description: "",
        city_mpg: trim[i]?.mileage?.city_mpg,
        status: trim[i]?.visibility?.status?.status_code,
        combined_mpg: trim[i]?.mileage?.combined_mpg,
        highway_mpg: trim[i]?.mileage?.highway_mpg,
        price: trim[i]?.price?.msrp
          ? parseInt(trim[i]?.price?.msrp) +
            parseInt(trim[i]?.price?.destination_fee)
          : parseInt(trim[i]?.price?.base_msrp) +
            parseInt(trim[i]?.price?.destination_fee),
        image: TOYOTA_BASE_URL + trim[i]?.images?.grade_car_jelly_image,
      });
    }
    this.gradeResult.forEach((element) => {
      element.attributes.forEach((ele) => {
        if (ele.type == "grade") {
          element.title = ele?.title;
          element.description = ele?.description;
        }
      });
    });
    /* Filter trims that have status live */
    this.gradeResult = this.gradeResult.filter((x) => x.status == "LIVE");
  }

  formatText(text) {
    if (text) {
      return text.replace(/ *\[[^\]]*]/g, "");
    }
  }

  formatPrice(price) {
    return parseInt(price)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  ngOnDestroy() {
    this.unsubscribe$.complete();
  }
}
