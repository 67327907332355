import { Component, OnDestroy, OnInit } from "@angular/core";
import { FindMyCarService } from "../../../service/find-my-car.service";
import * as _ from "lodash";
import { MatDialog } from "@angular/material/dialog";
import { forkJoin, map, Subject, take, takeUntil } from "rxjs";
import { DisclaimerPopupComponent } from "../../shared/disclaimer-popup/disclaimer-popup.component";
import { Router } from "@angular/router";
import { UiStateService } from "src/app/service/ui-state.service";
import { LEXUS_BASE_URL } from "src/app/constants/imagebase.constants";

@Component({
  selector: "dsmds-lexus-model",
  templateUrl: "./lexus-model.component.html",
  styleUrls: ["./lexus-model.component.css"],
})
export class LexusModelComponent implements OnInit, OnDestroy {
  modelData: any = [];
  imageData: any;
  trimResult: any = [];
  finalResult: any = [];
  lexmodelCodeSelected: any = null;
  sedan: any = [];
  suv: any = [];
  coupe: any = [];
  hybrid_electric: any = [];
  performance: any = [];
  category: any = "sedan";
  selectedGroup: any = [];
  unsubscribe$ = new Subject();

  constructor(
    private findCarService: FindMyCarService,
    public dialog: MatDialog,
    public router: Router,
    public appState: UiStateService
  ) {
    this.appState
      ?.getState()
      .pipe(take(1))
      .subscribe((state) => {
        if (!state.hasOwnProperty("otd_api_key") || !state?.otd_api_key) {
          this.router.navigate(["/"]);
        }

        if (state && state.hasOwnProperty("lexModelCode")) {
          this.lexmodelCodeSelected = state.lexModelCode;
        }
      });
  }

  public ngOnInit(): void {
    /* Parse data to get series info and mrsp
       associated with series information */
    forkJoin({
      modelinfo: this.findCarService.getToyotaSeriesApi("LEX"),
      modelcategory: this.findCarService.getToyotaApi("lexus"),
      triminfo: this.findCarService.getTrimInfo("LEX"),
    })
      .pipe(
        takeUntil(this.unsubscribe$),
        map((response) => {
          const modelinfo = <Array<any>>response.modelinfo;
          const triminfo = <Array<any>>response.triminfo;
          const modelCategory = <Array<any>>response.modelcategory;

          this.getModelInfo(modelinfo, modelCategory);
          this.getTrimInfo(triminfo);
          const finalResultMerged = _.merge(
            {},
            _.keyBy(this.modelData, "seriescode"),
            _.keyBy(this.trimResult, "seriescode")
          );
          this.finalResult = _.toArray(finalResultMerged);
          this.getImage(this.finalResult);
          this.finalResult = _.sortBy(this.finalResult, ["title"]);
          console.log("lexusALLModel", this.finalResult);
          return this.finalResult;
        })
      )
      .subscribe((data) => {
        this.getCategoryResult();
      });
  }

  getTrimInfo(triminfo) {
    const series = triminfo["years_list"];
    let trim_final = [];
    const trimResult = [];
    for (let i = 0; i < series.length; i++) {
      const series_info = series[i].series_list;
      for (let j = 0; j < series_info.length; j++) {
        const trim = series_info[j].trims;
        for (let k = 0; k < trim.length; k++) {
          trimResult.push({
            year: parseInt(series[i].year),
            seriescode: series_info[j].series,
            trim_code: trim[k].code,
            title: trim[k].title,
            price: trim[k]?.price?.msrp
              ? parseInt(trim[k]?.price?.msrp) +
                parseInt(trim[k]?.price?.destination_fee)
              : parseInt(trim[k]?.price?.base_msrp) +
                parseInt(trim[k]?.price?.destination_fee),
          });
        }
      }
      const data = _(trimResult)
        .groupBy("seriescode")
        .map((group) => _.minBy(group, "price"))
        .value();

      trim_final.push(...data);
    }

    this.trimResult = _(trim_final)
      .groupBy("seriescode")
      .map((group) => _.maxBy(group, "year"))
      .value();
  }

  openDialog(type) {
    this.dialog.open(DisclaimerPopupComponent, {
      data: type,
    });
  }

  /* Parse data to get model info
    associated with series information */
  getModelInfo(modelinfo, modelCategory) {
    const series = modelinfo["series_data"];
    let model_info = [];
    for (let i = 0; i < series.length; i++) {
      const series_info = series[i].series_list;

      for (let j = 0; j < series_info.length; j++) {
        console.log("Series Code is -- " + series_info[j].code);
        const categoryInfo = modelCategory.seriesList.find(
          (sl) =>
            sl.seriesName.toLowerCase() === series_info[j].title.toLowerCase()
        );
        // const categoryYear = categoryInfo?.years.find(
        //   (y) => y.year == series[i].year
        // );
        const categoryYear = categoryInfo?.years[0];
        console.log(
          "category is -- " + JSON.stringify(categoryYear?.seriesCategory)
        );
        model_info.push({
          code: series_info[j].code,
          seriescode: series_info[j].code.toLowerCase(),
          name: series_info[j].title,
          image: "",
          year: parseInt(series[i].year),
          category: categoryYear?.seriesCategory,
        });
      }
    }
    // model_info = [];
    // const series_list = modelCategory.seriesList;
    // for(let i = 0 ; i < series_list.length; i++){
    //   let years = series_list[i].years;
    //   for(let j = 0 ; j < years.length; j++){
    //     model_info.push({
    //       code: series_list[i].seriesId,
    //       seriescode: series_list[i].seriesId.toLowerCase(),
    //       name: series_list[i].seriesName,
    //       image: "",
    //       year: parseInt(years[j].year),
    //       category: years[j]?.seriesCategory
    //     });
    //   }
    // }
    console.log("model--Info :: " + JSON.stringify(model_info, null, 4));
    // Get Latest year veichle
    this.modelData = _(model_info)
      .groupBy("code")
      .map((group) => _.maxBy(group, "year"))
      .value();
  }

  /* Parse data to get image info
    associated with series information */
  getImage(result) {
    for (let i = 0; i < result.length; i++) {
      if (!result[i].code) {
        console.log(
          "No Series Code found for -- " + JSON.stringify(result, null, 4)
        );
        continue;
      }
      this.findCarService
        .getLexusImage(result[i].code, result[i].year)
        .pipe()
        .subscribe(
          (data) => {
            let model = _.values(data.DataArea.model).flat();
            model.forEach((element) => {
              if (result[i].trim_code == element?.code) {
                result[i].image =
                  LEXUS_BASE_URL +
                  this.getTrimImage(_.values(element?.exteriorcolor).flat());
              }
            });
          },
          (err) => {
            console.log("error getting image for code -- " + result[i].code);
          }
        );
    }
  }

  getTrimImage(exterior) {
    // console.log('mmmmg', exterior)
    let img = _?.values(exterior)?.flat();
    let x = _.values(img[1]).flat();

    if (x.length > 0) {
      const _imgUrl = x[0][1][0];
      if (_imgUrl.includes("png") || _imgUrl.includes("jpg")) {
        return x[0][1][0];
      } else if (x.length > 1) {
        const y = Object.values(x[1]);
        return y[0][1][0];
      }
    }
    return null;
  }

  formatPrice(price) {
    return parseInt(price)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  navigateToGrade(event, data) {
    console.log("modelData", data)
    let category = this.category.toUpperCase();
    if(category == 'HYBRID_ELECTRIC'){
      category = 'HYBRID & ELECTRIC';
    }
    this.appState.setState({
      lexSeriesCode: data.code,
      lexSeriesTitle: data.name,
      lexYear: data.year,
      lexModelCode: data.code,
      lexConflictInfo: null,
      lexDisplayExterior: null,
      lexDisplayText: null,
      lexDriveTitle: null,
      lexExtImageData: null,
      lexGradeCode: null,
      lexGradeTitle: null,
      lexIntImageData: null,
      lexRequiredOptions: null,
      lexRequirementInfo: null,
      lexSelectedExterior: null,
      lexSelectedGradePrice: null,
      lexSelectedInterior: null,
      lexSeriesData: null,
      seriesCategory: category,
      lexextcode: null,
      lexintcode: null,
    });
    event.stopPropagation();
    this.router.navigate(["lexus", "trim", data.code]);
  }

  getCategoryResult() {
    console.log("this.finalResult", this.finalResult);
    for (let i = 0; i < this.finalResult.length; i++) {
      if (!this.finalResult[i]?.category) {
        console.log(
          "Not able to get Category for -- " +
            JSON.stringify(this.finalResult[i], null, 4)
        );
        continue;
      }
      if (this.finalResult[i]?.category.indexOf("SUV") !== -1) {
        this.suv.push(this.finalResult[i]);
      }
      if (this.finalResult[i]?.category.indexOf("SEDAN") !== -1) {
        this.sedan.push(this.finalResult[i]);
      }
      if (this.finalResult[i]?.category.indexOf("COUPE") !== -1) {
        this.coupe.push(this.finalResult[i]);
      }
      if (this.finalResult[i]?.category.indexOf("PERFORMANCE") !== -1) {
        this.performance.push(this.finalResult[i]);
      }
      if (this.finalResult[i]?.category.indexOf("HYBRID & ELECTRIC") !== -1) {
        this.hybrid_electric.push(this.finalResult[i]);
      }
    }
    this.selectedGroup = this.sedan;
  }

  ngOnDestroy() {
    this.unsubscribe$.complete();
  }
}
