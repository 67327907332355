import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { FeatureFlagService } from "../feature-flag/feature-flag.service";
import { FEATURE_FLAG_ANALYTICS } from "../../constants/flags.constants";
import { catchError } from "rxjs/operators";
import { of, take } from 'rxjs';
import { DEBUG } from "../../utils";
import { UiStateService } from '../ui-state.service';

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  apikey: string;
  efc_apikey: string;
  vami_apiKey: string;
  analytics_apiKey: string;
  constructor(
    private featureFlagService: FeatureFlagService,
    private http: HttpClient,
    private appState: UiStateService,
  ) {}

  capture(endpoint: string, template: any, data: any) {
    return new Promise<void>((resolve, reject) => {
      this.getApiStateKey();

      const D = DEBUG("AnalyticsService.capture()");

      if (!this.featureFlagService.isFlag(FEATURE_FLAG_ANALYTICS)) {
        D("feature flag disabled", true);
        resolve();
        return;
      }

      let captured = {};

      D("template", template);
      D("data", data);

      for (const property in template) {
        D("property", property);
        D("data[property]", data[property]);
        D("template[property]", template[property]);

        if (data[property]) {
          captured[template[property] ? template[property] : property] =
            data[property];
        }
      }

      const headers = new HttpHeaders().set(
        "x-api-key",
        this.analytics_apiKey
      );

      this.http
        .post(endpoint, captured, { headers: headers })
        .pipe(
          catchError((error) => {
            if (error) {
              D("capture error", error);
            }
            reject();
            return of(error != null);
          })
        )
        .subscribe((results) => {
          D("results", results);
          D("captured", captured);
          resolve();
        });
    });
  }

  get(
    endpoint: string,
    callback: (d: any) => void,
    errorCallback: (error: HttpErrorResponse) => void
  ) {
    const D = DEBUG("AnalyticsService.get()");
    this.getApiStateKey();

    if (!this.featureFlagService.isFlag(FEATURE_FLAG_ANALYTICS)) {
      D("feature flag disabled", true);
      callback({});
      return;
    }

    D("endpoint", endpoint);

    const headers = new HttpHeaders().set(
      "x-api-key",
      this.analytics_apiKey
    );
    this.http
      .get(endpoint, { headers: headers })
      .pipe(
        catchError((error) => {
          if (error) {
            D("error", error);
            errorCallback(error);
          }
          return of(error != null);
        })
      )
      .subscribe((d) => {
        D("results", d);
        callback(d);
      });
  }

  getApiStateKey() {
    this.appState
      .getState()
      .pipe(take(1))
      .subscribe((state) => {
        if ("otd_api_key" in state) {
          this.apikey = state?.otd_api_key;
          this.efc_apikey = state?.efc_api_key;
          this.vami_apiKey = state?.vami_api_key;
          this.analytics_apiKey = state?.analytics_api_key;
        }
      });
  }
}
