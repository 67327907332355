<div class="container pb-5">
  <div class="row text-center text-white mt-4 mb-4">
    <div class="model-container">
      <h1 style="font-weight: 500">
        Your vehicle:
        <span style="font-weight: 400">{{ displayText }}</span>
      </h1>
      <div >
        <h1 class="display-4">Select an Exterior Color</h1>
      </div>
    </div>
  </div>
  <div class="dsmds-steps-navigations">
    <dsmds-steps-navigations [tabnumber]="3"></dsmds-steps-navigations>
  </div>
  <div class="color-info-n bg-white mt-4" >
    <div class="row" >
      <div class="col-md-6 col-sm-12">
      <div
        class="bg-image hover-overlay ripple shadow-1-strong rounded"
        data-ripple-color="light"
      >
        <div class="image-not-found" *ngIf="!selected_exterior?.jellyImage">
          Image Coming Soon
        </div>
        <img
          *ngIf="selected_exterior?.jellyImage"
          alt="Car Image"
          src="{{ selected_exterior?.image }}"
          class="img-fluid"
        />
      </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="row colors-button-row mt-5">
          <div class="row-cols-xxxl-5 col-sm-3 col-4" *ngFor="let data of exteriorColor">
            <div id="{{ data.color_code }}" class="colors" (click)="getImage(data)">
              <div class="buttonGrp">
                <div class="buttonBrd" [class.exterior-active] = "selected_exterior?.color_code==data.color_code">
                  <button
                    *ngIf="data.hex_value_2 == null"
                    [style.background-color]="data.hex_value_1"
                    type="button"
                    class="btn btn-primary btn-circle btn-xl"
                    
                  ></button>
                  <button
                    *ngIf="data.hex_value_2 != null"
                    [style.background]="
                      'linear-gradient(-50deg,' +
                      data.hex_value_1 +
                      ' 50%,' +
                      data.hex_value_2 +
                      ' 0)'
                    "
                    type="button"
                    class="btn btn-primary btn-circle btn-xl"
                  ></button>
                </div>  
                <label class="labelGrp">
                  <h4 class="font-weight-bold my-2">
                    {{ formatExtColor(data) }}
                    <sup
                      *ngIf="data.color_msrp"
                      style="cursor: pointer"
                      (click)="openDialog('msrp')"
                      >*</sup
                    >
                  </h4>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>   
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <div class="moveButtons">
        <a
          [routerLink]="['interiorColor']"
          [state]="{displayText,selected_exterior}"
          >
          <dsmds-button-round [buttonConfig]="{
            'text':'Next Step',
            'type':'primary',
             'size' : 'sm'
          }"></dsmds-button-round>
          </a
        >
      </div>
    </div>
  </div>  
</div>
<div class="handling"> <h1 >{{errMessage}}</h1></div>
